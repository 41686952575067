import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { handleLogout } from "../utils/authUtils.js";
import { useApiUtils } from "../utils/apiUtils.js";
import { trackOperation } from "../utils/memoryMonitor.js";

const Auth0Auth = () => {
  const { loginWithRedirect, logout } = useAuth0();
  const [errorMessage, setErrorMessage] = useState("");
  const apiUtils = useApiUtils();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("error")) {
      console.log(
        "Auth0 error:",
        params.get("error_description") || "An error occurred."
      );
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    return () => {
      setErrorMessage("");
    };
  }, []);

  const handleAuthLogin = async () => {
    const memoryTracker = trackOperation("auth0-login");
    try {
      console.log("Initiating Auth0 login...");
      await loginWithRedirect({
        appState: { returnTo: window.location.origin },
        scope: "openid profile email",
        prompt: "select_account login",
        connection: undefined,
        max_age: 0,
        ui_locales: "en",
      });
    } catch (error) {
      console.error("Login redirect failed:", error);
      setErrorMessage("Failed to initiate login. Please try again.");
    } finally {
      memoryTracker.end();
    }
  };
  return (
    <div className="login-page">
      <div className="login-container">
        <div className="company-logo" aria-label="Company Logo"></div>
        <h1 className="welcome-message">
          Welcome to the
          <br />
          Supervise Console
        </h1>
        <button onClick={handleAuthLogin} className="login-button">
          Log in
        </button>
      </div>
    </div>
  );
};

export default Auth0Auth;
