import * as echarts from "echarts";
import {
  faInfoCircle,
  faFileDownload,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { toggleOverlay, createMetricNameTooltip } from "../utils/chartUtils.js";
import {
  formatMetricValue,
  capitalizeWords,
  formatIntervalDate,
  formatIntervalTooltip,
} from "../utils/formatUtils.js";

export const renderStackedBarChart = (
  metrics,
  container,
  selectedDateRange,
  renderedCharts,
  downloadData
) => {
  if (!metrics || !container || metrics.length === 0) {
    console.error("Invalid metrics or container.");
    return;
  }

  const key = `${metrics[0].metric_name}-${metrics[0].workflow_name}`;
  if (renderedCharts.has(key)) {
    return;
  }

  renderedCharts.add(key);

  const chartContainer = document.createElement("div");
  const dataPoints = metrics.length;
  const minWidth = 400;
  const widthPerPoint = 20;
  const calculatedWidth = Math.max(minWidth, dataPoints * widthPerPoint);
  const screenWidth = window.innerWidth - 100;
  const finalWidth = Math.min(calculatedWidth, screenWidth, 1200);
  const computedStyles = getComputedStyle(document.documentElement);

  const colors = [
    computedStyles.getPropertyValue("--chart-theme-color-1"),
    computedStyles.getPropertyValue("--chart-theme-color-2"),
    computedStyles.getPropertyValue("--chart-theme-color-3"),
    computedStyles.getPropertyValue("--chart-theme-color-4"),
    computedStyles.getPropertyValue("--chart-theme-color-5"),
    computedStyles.getPropertyValue("--chart-theme-color-6"),
    computedStyles.getPropertyValue("--chart-theme-color-7"),
    computedStyles.getPropertyValue("--chart-theme-color-8"),
    computedStyles.getPropertyValue("--chart-theme-color-9"),
    computedStyles.getPropertyValue("--chart-theme-color-10"),
  ];

  chartContainer.style.width = `${finalWidth}px`;
  chartContainer.style.height = "400px";
  container.appendChild(chartContainer);

  const chartInstance = echarts.init(chartContainer);
  metrics[0].chartInstance = chartInstance;

  // Check for empty interval_data here, after container creation
  if (!metrics[0].interval_data || metrics[0].interval_data.length === 0) {
    console.log("No data collected yet for stacked bar chart");
    container.style.position = "relative";
    toggleOverlay(container, true, "No data collected yet");
  }

  const handleResize = () => {
    const newScreenWidth = window.innerWidth - 100;
    const newWidth = Math.min(calculatedWidth, newScreenWidth, 1200);
    chartContainer.style.width = `${newWidth}px`;
    chartInstance.resize();
  };

  window.addEventListener("resize", handleResize);
  container.chartResizeHandlers = container.chartResizeHandlers || [];
  container.chartResizeHandlers.push(handleResize);

  const [startDate, endDate] = selectedDateRange || [null, null];
  let filteredMetrics = metrics;

  if (startDate && endDate) {
    try {
      filteredMetrics = metrics.map((metric) => ({
        ...metric,
        interval_data: metric.interval_data.filter((item) => {
          const itemDate = new Date(item.interval_start);
          return itemDate >= startDate && itemDate <= endDate;
        }),
      }));

      if (filteredMetrics[0].interval_data.length === 0) {
        toggleOverlay(container, true, "No data within this date range");
      } else {
        toggleOverlay(container, false);
      }
    } catch (error) {
      console.error("Error filtering dates:", error);
    }
  }

  let groupedData = {};
  let rawCategories = [];

  // First, collect all unique dates and dimensions
  const intervalData = filteredMetrics[0].interval_data || [];
  rawCategories = [
    ...new Set(intervalData.map((item) => item.interval_start)),
  ].sort((a, b) => new Date(a) - new Date(b)); // Sort dates in ascending order
  const allDimensions = [
    ...new Set(intervalData.map((item) => item.dimension)),
  ].sort();

  // Initialize groupedData with all dimensions set to 0 for each date
  rawCategories.forEach((date) => {
    groupedData[date] = {};
    allDimensions.forEach((dim) => {
      const dimensionKey = capitalizeWords(dim || "");
      groupedData[date][dimensionKey] = 0;
    });
  });

  // Sum up measures for each date and dimension
  intervalData.forEach((item) => {
    const dimensionKey = capitalizeWords(item.dimension || "");
    groupedData[item.interval_start][dimensionKey] = item.measure;
  });

  const categories = rawCategories;
  const seriesData = {};

  // Create series data ensuring all dimensions are represented for each date
  allDimensions.forEach((dimension) => {
    const formattedDimension = capitalizeWords(dimension || "");
    seriesData[formattedDimension] = categories.map(
      (date) => groupedData[date][formattedDimension] || 0
    );
  });

  const buttonBackground = computedStyles.getPropertyValue("transparent");
  const textColor = computedStyles.getPropertyValue("--text-color");

  const createStyledBase64Icon = (icon) => {
    const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="background-color: ${buttonBackground}; color: ${textColor}; cursor: pointer; border-radius: 2px; width: 50px; height: 50px; font-family: 'Asap', sans-serif; border: none; margin-right: 5px;">
      <g transform="scale(0.8) translate(64, 64)">
        <path fill="currentColor" d="${icon.icon[4]}"></path>
      </g>
    </svg>`;
    return `image://data:image/svg+xml;base64,${btoa(svgString)}`;
  };

  const hasDimensions = Object.keys(seriesData).some(
    (dim) => dim.trim() !== ""
  );

  const option = {
    // animation: false,
    graphic: [
      {
        type: "group",
        top: 5,
        children: [
          {
            type: "text",
            style: {
              text:
                metrics[0].metric_name.charAt(0).toUpperCase() +
                metrics[0].metric_name.slice(1) +
                " (per " +
                metrics[0].time_interval +
                ")",
              font: "bolder 14px Asap",
              fill: computedStyles.getPropertyValue("--text-color"),
              width: 280,
              overflow: "truncate",
            },
            onmouseover: function (params) {
              setTimeout(() => {
                const existingTooltip = document.getElementById(
                  "metric-name-tooltip"
                );
                if (existingTooltip) {
                  existingTooltip.remove();
                }
                document.body.appendChild(
                  createMetricNameTooltip(params, computedStyles, metrics[0])
                );
              }, 50);
            },
            onmouseout: function () {
              setTimeout(() => {
                const tooltipDom = document.getElementById(
                  "metric-name-tooltip"
                );
                if (tooltipDom) {
                  tooltipDom.remove();
                }
              }, 50);
            },
          },
        ],
      },
    ],
    toolbox: {
      show: true,
      showTitle: false,
      feature: {
        myDownload: {
          show: true,
          title: "Download Data",
          icon: createStyledBase64Icon(faFileDownload),
          onclick: () => {
            const options = chartInstance.getOption();
            const dataZoom = options.dataZoom[0];

            const startIndex = Math.floor(
              (dataZoom.start * options.xAxis[0].data.length) / 100
            );
            const endIndex = Math.ceil(
              (dataZoom.end * options.xAxis[0].data.length) / 100
            );

            const visibleDates = rawCategories.slice(startIndex, endIndex);
            const seriesData = options.series;

            const downloadableData = visibleDates.flatMap((date, dateIndex) => {
              return seriesData
                .map((series) => {
                  const value = series.data[startIndex + dateIndex];
                  if (!value || isNaN(value) || value === 0) {
                    return null;
                  }

                  const startDate = new Date(date);
                  const endDate = new Date(startDate);

                  switch (metrics[0].time_interval) {
                    case "week":
                      endDate.setDate(startDate.getDate() + 6);
                      break;
                    case "month":
                      endDate.setMonth(endDate.getMonth() + 1);
                      endDate.setDate(0);
                      break;
                    case "year":
                      endDate.setFullYear(endDate.getFullYear() + 1);
                      endDate.setDate(0);
                      break;
                    case "hour":
                      endDate.setHours(startDate.getHours() + 1);
                      break;
                    case "day":
                    default:
                      endDate.setHours(23, 59, 59, 999);
                      break;
                  }

                  return {
                    "Start Date": startDate.toISOString(),
                    "End Date": endDate.toISOString(),
                    Dimension: series.name,
                    Value: formatMetricValue(value, metrics[0].metric_format),
                  };
                })
                .filter((entry) => entry !== null);
            });

            const formattedFilename = `${metrics[0].workflow_name}_${
              metrics[0].metric_name
            }_${new Date().toISOString().split("T")[0]}`.replace(/ /g, "_");
            downloadData(downloadableData, formattedFilename, metrics[0]);
          },
        },
        myInfo: {
          show: true,
          title: metrics[0].metric_definition,
          icon: createStyledBase64Icon(faInfoCircle),
          onclick: function () {},
        },
      },
      tooltip: {
        show: true,
        formatter: function (param) {
          return "<div>" + param.title + "</div>";
        },
        backgroundColor: computedStyles.getPropertyValue("--background-color"),
        textStyle: {
          fontSize: 12,
          color: computedStyles.getPropertyValue("--text-color"),
          width: 300,
        },
        extraCssText:
          "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); max-width: 300px; overflow: break; white-space: normal;",
        trigger: "axis",
        axisPointer: { type: "shadow" },
        confine: true,
        position: function (point, params, dom, rect, size) {
          return [point[0] + 15, point[1] + 15];
        },
      },
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: computedStyles.getPropertyValue("--background-color"),
      textStyle: {
        fontFamily: "Asap",
        color: computedStyles.getPropertyValue("--text-color"),
      },
      formatter: function (params) {
        let tooltipContent = "";
        if (params.seriesName && params.seriesName.trim() !== "") {
          tooltipContent += `${capitalizeWords(params.seriesName)}<br/>`;
        }
        const originalDateStr = rawCategories[params.dataIndex];
        tooltipContent += `${formatIntervalTooltip(
          originalDateStr,
          metrics[0].time_interval
        )}<br/>`;
        tooltipContent += `Value: <b>${formatMetricValue(
          params.value,
          metrics[0].metric_format
        )}</b>`;
        return tooltipContent;
      },
    },
    legend: {
      show: hasDimensions,
      data: Object.keys(seriesData).map((name) => capitalizeWords(name)),
      right: "right",
      orient: "vertical",
      top: "middle",
      selectedMode: false,
      emphasis: {
        focus: "series",
      },
      type: "scroll",
      height: "80%",
      pageButtonPosition: "end",
      pageButtonItemGap: 5,
      pageButtonGap: 5,
      pageIcons: {
        vertical: [
          createStyledBase64Icon(faChevronUp),
          createStyledBase64Icon(faChevronDown),
        ],
      },
      pageTextStyle: {
        color: computedStyles.getPropertyValue("--text-color"),
      },
      textStyle: {
        fontFamily: "Asap",
        color: computedStyles.getPropertyValue("--text-color"),
        width: 100,
        overflow: "break",
        lineHeight: 15,
      },
    },
    grid: {
      left: "3%",
      // right: hasDimensions ? "35%" : "4%",
      bottom: "15%",
      top: "10%",
      width: hasDimensions ? "60%" : "90%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: categories.map((date) =>
        formatIntervalDate(date, metrics[0].time_interval)
      ),
      axisLine: {
        lineStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--chart-border-color"
          ),
        },
      },
      axisLabel: {
        fontFamily: "Asap",
        rotate: 45,
        interval:
          categories.length <= 10
            ? 0
            : Math.max(
                1,
                Math.floor(categories.length / (window.innerWidth / 100))
              ),
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--chart-border-color"
          ),
        },
      },
      splitLine: {
        lineStyle: {
          opacity: 0.2,
        },
      },
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        xAxisIndex: [0],
        start: 0,
        end: 100,
        height: 20,
        handleSize: "100%",
        textStyle: {
          fontFamily: "Asap",
          color: computedStyles.getPropertyValue("--text-color"),
        },
      },
    ],
    series: [],
  };

  Object.entries(seriesData).forEach(([dimension, data], index) => {
    option.series.push({
      name: dimension,
      type: "bar",
      stack: "total",
      data: data,
      itemStyle: {
        color: colors[index % colors.length],
      },
    });
  });

  // Initialize and render the chart even if there's no data
  chartInstance.setOption(option);
};
