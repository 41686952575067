import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./App.css";
import Header from "./Header.js";
import ReactFlow, { Background, useReactFlow } from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import ActionNodePopup from "./workflow_components/ActionNodePopup.js";
import LLMCallNodePopup from "./workflow_components/LLMCallNodePopup.js";
import ObservableNodePopup from "./workflow_components/ObservableNodePopup.js";
import AgentNodePopup from "./workflow_components/AgentNodePopup.js";
import ErrorPopup from "./workflow_components/ErrorPopup.js";
import ActualErrorPopup from "./workflow_components/ActualErrorPopup.js";
import LeftDrawer from "./workflow_components/LeftDrawer.js";
import WorkflowTable from "./workflow_components/WorkflowTable.js";
import Breadcrumbs from "./workflow_components/Breadcrumbs.js";
import EmptyDrawerMessagePopup from "./workflow_components/EmptyDrawerMessagePopup.js";
import { useObservableView } from "./contexts/ObservableViewContext.js";
import { useUser } from "./contexts/UserContext.js";
import { formatName } from "./utils/formatUtils.js";
import { useWorkflowTooltip } from "./contexts/WorkflowTooltipContext.js";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faPenToSquare,
  faGear,
  faComments,
  faAnglesLeft,
  faAnglesRight,
  faAnglesDown,
} from "@fortawesome/free-solid-svg-icons";
import { useBreadcrumbs } from "./contexts/BreadcrumbsContext.js";
import { getSortIcon } from "./utils/formatUtils.js";
import {
  getLayoutedElements,
  combineRuns,
  toggleNodeVisibility,
} from "./utils/workflowUtils.js";
import { useApiUtils } from "./utils/apiUtils.js";
import TooltipWorkflows from "./TooltipWorkflows.js";
import { trackOperation } from "./utils/memoryMonitor.js";

function WorkflowDetails() {
  const {
    fetchFilteredInstances,
    fetchChildWorkflows,
    fetchWorkflowStructure,
    fetchLatestWorkflowInstance,
    fetchLlmCallSummary,
    fetchObservableSummary,
    fetchWorkflowInstanceInfo,
  } = useApiUtils();

  const { userIsQurrent } = useUser();

  const { setCenter } = useReactFlow();

  const [workflowDescription, setWorkflowDescription] = useState("");
  const [showCustomCalendar, setShowCustomCalendar] = useState(false);
  const [sortColumn, setSortColumn] = useState("start_time");
  const [sortDirection, setSortDirection] = useState("desc");
  const [runs, setRuns] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    status: null,
    run_start: null,
    run_end: null,
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [workflowStructure, setWorkflowStructure] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [actualShowErrorPopup, setActualShowErrorPopup] = useState(false);
  const popupRef = useRef(null);

  const [selectedRunId, setSelectedRunId] = useState(null);
  const [showEmptyDrawerPopup, setShowEmptyDrawerPopup] = useState(false);

  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
  const [openStartDropdown, setOpenStartDropdown] = useState(false);
  const [openEndDropdown, setOpenEndDropdown] = useState(false);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [openVersionDropdown, setOpenVersionDropdown] = useState(false);

  const [runStartFilter, setRunStartFilter] = useState([null, null]);
  const [runEndFilter, setRunEndFilter] = useState([null, null]);
  const [runStartDisplay, setRunStartDisplay] = useState(null);
  const [runEndDisplay, setRunEndDisplay] = useState(null);
  const startDropdownRef = useRef(null);
  const endDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const [customDateType, setCustomDateType] = useState(null);
  const [copied, setCopied] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedNode, setSelectedNode] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const [hiddenNodes, setHiddenNodes] = useState(new Set());
  const [hiddenEdges, setHiddenEdges] = useState(new Set());

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loadingStructure, setLoadingStructure] = useState(false);

  const [expandedRunIds, setExpandedRunIds] = useState(new Set());
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [observableDetails, setObservableDetails] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [highlightedNodeId, setHighlightedNodeId] = useState(null);
  const drawerRef = useRef(null);
  const [hiddenChildrenNodeIds, setHiddenChildrenNodeIds] = useState(new Set());
  const [focusedIndex, setFocusedIndex] = useState(0);
  const observableListRef = useRef([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [childWorkflows, setChildWorkflows] = useState({});
  const [currentWorkflowName, setCurrentWorkflowName] = useState(null);
  const { workflowInstanceId } = useParams();
  useState(workflowInstanceId);
  const nodeWidth = 150;
  const nodeHeight = 50;
  const originalWarn = console.warn;
  const [llmSummary, setLlmSummary] = useState("");
  const [observableSummary, setObservableSummary] = useState("");
  const [visibleNodes, setVisibleNodes] = useState([]);
  const [visibleEdges, setVisibleEdges] = useState([]);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const abortControllerRef = useRef(null);
  const { isObservableViewActive } = useObservableView(); // Use the context
  const [workflowInstanceCreatedAt, setWorkflowInstanceCreatedAt] =
    useState(null);
  const [workflowInstanceEndedAt, setWorkflowInstanceEndedAt] = useState(null);
  const [lastClickedNodeIndex, setLastClickedNodeIndex] = useState(null);
  const combinedRuns = combineRuns(runs);
  const selectedRunData = combinedRuns.find((run) => run.id === selectedRunId);
  const selectedRunOutput = selectedRunData ? selectedRunData.outputs : [];

  const [initialHideDone, setInitialHideDone] = useState(false);
  const [isRunClick, setIsRunClick] = useState(false);

  const [shouldLoadData, setShouldLoadData] = useState(true);
  const [thisWorkflowId, setThisWorkflowId] = useState(null);
  const [showObservables, setShowObservables] = useState(false);
  const [searchByDisplay, setSearchByDisplay] = useState("Search by");
  const searchDropdownRef = useRef(null);
  const [selectedIdentifier, setSelectedIdentifier] = useState(null);
  const { fetchIdentifiers } = useApiUtils();
  const [identifiers, setIdentifiers] = useState([]);
  const [llmImages, setLlmImages] = useState([]);
  const [identifierName, setIdentifierName] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const currentFiltersRef = useRef(activeFilters);
  const [fixedBottomHeight, setFixedBottomHeight] = useState(
    window.innerHeight
  );
  const [allVersions, setAllVersions] = useState([]);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [spinningColumn, setSpinningColumn] = useState(null);
  const [statusSortIndex, setStatusSortIndex] = useState(0);
  const statusOrder = ["created", "completed", "failed", "waiting", "warning"];
  const { shouldShowTooltips, closeWorkflowTooltipForever } =
    useWorkflowTooltip();
  const pageName = "WorkflowDetails";
  const showTooltip = shouldShowTooltips(pageName);
  const { env } = useContext(EnvironmentContext);

  const updateFixedBottomHeight = () => {
    const drawerHeight =
      document.querySelector(".fixed-bottom")?.offsetHeight - 23 ||
      window.innerHeight;
    setFixedBottomHeight(drawerHeight);
  };

  useEffect(() => {
    updateFixedBottomHeight();
    window.addEventListener("resize", updateFixedBottomHeight);

    return () => {
      window.removeEventListener("resize", updateFixedBottomHeight);
    };
  }, []);

  console.warn = (message, ...args) => {
    if (
      typeof message === "string" &&
      message.includes("[React Flow]: Node type")
    ) {
      return;
    }
    originalWarn(message, ...args);
  };

  async function initializeData(thisWorkflowInstanceId, thisWorkflowName) {
    setIsOverlayVisible(true);
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
    setCurrentPage(1);
    setIdentifierName(identifierName);
    setSearchTerm(searchTerm);
    setRuns([]);
    setCurrentWorkflowName(thisWorkflowName);

    try {
      const allData = await fetchFilteredInstances(
        workflowInstanceId ? workflowInstanceId : thisWorkflowInstanceId,
        1,
        activeFilters,
        sortColumn,
        sortDirection,
        identifierName,
        searchTerm,
        selectedVersions
          ? selectedVersions.length > 0
            ? selectedVersions
            : ["All"]
          : ["All"]
      );

      setAllVersions(allData.response.data.all_versions);

      if (allData.response.data.workflow_id) {
        try {
          const identifiers = await fetchIdentifiers(
            allData.response.data.workflow_id,
            workflowInstanceId ? workflowInstanceId : thisWorkflowInstanceId
          );
          setIdentifiers(identifiers);
        } catch (error) {
          console.error("Error loading identifiers:", error);
        }
      }

      setTotal(allData.response.data.total_count || 0);
      setTotalPages(Math.ceil(allData.response.data.total_count / 25));
      setRuns(allData.processedInstances);
      setIsDrawerOpen(true);
      setIsLeftDrawerOpen(false);

      if (allData.processedInstances && allData.processedInstances.length > 0) {
        thisWorkflowName = allData.response.data.workflow_name;
        const description = allData.response.data.workflow_description
          ? allData.response.data.workflow_description
          : "No description available";
        setWorkflowDescription(description);
        setCurrentWorkflowName(thisWorkflowName);
        if (workflowInstanceId && thisWorkflowName) {
          updateBreadcrumbs({
            workflow_id: allData.response.data.workflow_id,
            name: thisWorkflowName,
            id: workflowInstanceId,
          });
          setThisWorkflowId(allData.response.data.workflow_id);
        }
        setWorkflowInstanceCreatedAt(allData.processedInstances[0].start_time);
        setWorkflowInstanceEndedAt(allData.processedInstances[0].end_time);
        const runId = workflowInstanceId || allData.processedInstances[0].id;
        setSelectedRunId(runId);
        await processWorkflowStructure(runId);
      } else {
        console.log("No instances found, skipping child workflows fetch.");
      }
    } catch (error) {
      console.error("Error in initializeData:", error);
    } finally {
      setHasInitialized(true);
      setIsOverlayVisible(false);
    }
  }

  async function processWorkflowStructure(thisWorkflowInstanceId) {
    const memoryTracker = trackOperation("process-workflow-structure");
    try {
      const structureData = await fetchWorkflowStructure(
        thisWorkflowInstanceId
      );
      if (structureData && structureData.length > 0) {
        setWorkflowStructure(structureData);
        const observableDetails = structureData.filter(
          (item) => item.type === "observable"
        );
        setObservableDetails(observableDetails);
        setHighlightedNodeId(structureData[0].id);
      } else {
        setWorkflowStructure(null);
        setObservableDetails([]);
        setHighlightedNodeId(null);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        console.error("Error fetching workflow structure:", error);
      }
      setWorkflowStructure(null);
      setObservableDetails([]);
      setHighlightedNodeId(null);
    } finally {
      memoryTracker.end();
    }
  }

  const handleDropdownClick = (nodeId) => {
    setHiddenChildrenNodeIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(nodeId)) {
        newIds.delete(nodeId);
      } else {
        newIds.add(nodeId);
      }
      return newIds;
    });
  };

  useEffect(() => {
    if (!workflowStructure) {
      console.log("No workflow structure found, EXITING EARLY");
      return;
    }

    const nodes = [];
    var edges = [];
    const positionMap = {};
    // Process workflow structure
    workflowStructure.forEach((item) => {
      const type = item.type || "default";
      let icon;

      // Icon mapping
      switch (type) {
        case "observable":
          icon = <FontAwesomeIcon className="node-icon" icon={faPenToSquare} />;
          break;
        case "llm_call":
          icon = <FontAwesomeIcon className="node-icon" icon={faComments} />;
          break;
        case "action":
          icon = <FontAwesomeIcon className="node-icon" icon={faGear} />;
          break;
        default:
          icon = null;
      }

      if (
        isObservableViewActive &&
        userIsQurrent &&
        (item.type === "action" || item.type === "llm_call") &&
        !item.trigger_action_id &&
        !item.llm_call_id &&
        !item.trigger_observable_id &&
        item.trigger_workflow_instance_id
      ) {
        // Add a placeholder observable node
        const placeholderObservableId = `placeholder-observable-${item.id}`;
        nodes.push({
          id: placeholderObservableId,
          data: {
            label: "Placeholder Task",
          },
          type: "observable",
          position: { x: 250, y: 50 },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Add a placeholder agent node
        const placeholderAgentId = `placeholder-agent-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the placeholder observable
        edges.push({
          id: `e${placeholderAgentId}-${placeholderObservableId}`,
          source: placeholderAgentId,
          target: placeholderObservableId,
        });

        // Connect the placeholder observable to the current item
        edges.push({
          id: `e${placeholderObservableId}-${item.id}`,
          source: placeholderObservableId,
          target: item.id,
        });
      }

      // Placeholder agent node
      if (
        isObservableViewActive &&
        userIsQurrent &&
        item.type === "observable" &&
        !item.trigger_console_agent_id &&
        !item.trigger_observable_id
      ) {
        const placeholderAgentId = `placeholder-observable-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the observable
        edges.push({
          id: `e${placeholderAgentId}-${item.id}`,
          source: placeholderAgentId,
          target: item.id,
        });
      }

      // Placeholder observable node
      if (
        isObservableViewActive &&
        userIsQurrent &&
        item.type === "observable" &&
        !item.trigger_console_agent_id &&
        !item.trigger_observable_id
      ) {
        const placeholderAgentId = `placeholder-agent-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the observable
        edges.push({
          id: `e${placeholderAgentId}-${item.id}`,
          source: placeholderAgentId,
          target: item.id,
        });
      }

      const position = item.position || { x: 250, y: 0 };
      const timestamp = new Date(item.created_at).getTime();
      const baseY = positionMap[timestamp] || 0;

      const hasChildren = workflowStructure.some(
        (nextItem) =>
          nextItem.trigger_action_id === item.id ||
          nextItem.llm_call_id === item.id ||
          nextItem.trigger_observable_id === item.id
      );

      // Node creation
      nodes.push({
        id: item.id,
        data: {
          item,
          label: (
            <div
              id={item.id}
              className="node-label"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  overflow: "hidden",
                }}
              >
                {icon}
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => {
                    setSelectedNode(item);
                  }}
                >
                  {type === "llm_call"
                    ? `LLM Call ...${item.id.slice(-5)}`
                    : type === "observable" || type === "action"
                    ? item.function_name
                    : "Node"}
                </span>
              </div>
              {type === "observable" && hasChildren && (
                <div style={{ marginLeft: "4px" }}>
                  <FontAwesomeIcon
                    icon={faAnglesDown}
                    className={`node-dropdown-icon ${
                      hiddenChildrenNodeIds.has(item.id) ? "rotated" : ""
                    }`}
                    style={{ fontSize: "0.8em" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDropdownClick(item.id);
                      console.log("Hidden Nodes: ", hiddenNodes);
                      toggleNodeVisibility(
                        item.id,
                        edges,
                        nodes,
                        hiddenNodes,
                        setHiddenNodes
                      );
                    }}
                  />
                </div>
              )}
            </div>
          ),
        },
        type: type,
        position: item.position,
        style: {
          backgroundColor: "var(--background-color-node)",
          borderColor: "var(--border-color)",
          borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
          borderStyle: "solid",
          borderRadius: "5px",
        },
      });

      positionMap[timestamp] = baseY + nodeHeight + 10;

      // Agent node
      if (item.trigger_console_agent_id && !item.trigger_observable_id) {
        const uniqueAgentId = `${item.trigger_console_agent_id}-${timestamp}`;
        const agentNode = {
          id: uniqueAgentId,
          type: "agent",
          data: {
            item: {
              ...item,
              console_agent: item.console_agent,
            },
            label: (
              <div
                className="node-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                  }}
                >
                  <FontAwesomeIcon className="node-icon" icon={faRobot} />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      setSelectedNode(item);
                    }}
                  >
                    {formatName(item.console_agent.name)}
                  </span>
                </div>
                <div style={{ marginLeft: "4px" }}></div>
              </div>
            ),
          },
          position: { x: position.x - 100, y: position.y },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        };

        nodes.push(agentNode);

        edges.push({
          id: `e${uniqueAgentId}-${item.id}`,
          source: uniqueAgentId,
          target: item.id,
        });
      }

      // Agent node FROM ANOTHER WORKFLOW
      if (
        item.trigger_console_agent_id &&
        item.trigger_observable_id &&
        item.parent_workflow_instance_id
      ) {
        const uniqueAgentId = `${item.trigger_console_agent_id}-${timestamp}`; // Create a unique id for each agent
        const agentNode = {
          id: uniqueAgentId,
          type: "agent",
          data: {
            item: {
              ...item,
              console_agent: item.console_agent,
            },
            label: (
              <div
                className="node-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                  }}
                >
                  <FontAwesomeIcon className="node-icon" icon={faRobot} />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      setSelectedNode(item);
                    }}
                  >
                    {formatName(item.console_agent.name)}
                  </span>
                </div>
                <div style={{ marginLeft: "4px" }}></div>
              </div>
            ),
          },
          position: { x: 250, y: 50 },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        };

        nodes.push(agentNode);

        edges.push({
          id: `e${uniqueAgentId}-${item.id}`,
          source: uniqueAgentId,
          target: item.id,
        });
      }

      // Action edge
      if (item.trigger_action_id) {
        edges.push({
          id: `e${item.trigger_action_id}-${item.id}`,
          source: item.trigger_action_id,
          target: item.id,
        });
      }

      // LLM call edge
      if (item.llm_call_id) {
        edges.push({
          id: `e${item.llm_call_id}-${item.id}`,
          source: item.llm_call_id,
          target: item.id,
        });
      }

      // Observable edge
      if (item.trigger_observable_id) {
        edges.push({
          id: `e${item.trigger_observable_id}-${item.id}`,
          source: item.trigger_observable_id,
          target: item.id,
        });
      }
    });

    // Initial hiding of child nodes for observables
    if (!initialHideDone) {
      workflowStructure.forEach((item) => {
        if (item.type === "observable") {
          toggleNodeVisibility(
            item.id,
            edges,
            nodes,
            hiddenNodes,
            setHiddenNodes
          );
        }
      });

      setInitialHideDone(true); // Mark initial hiding as done
    }

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges,
      nodeWidth,
      nodeHeight,
      hiddenNodes,
      hiddenEdges
    );

    const updatedVisibleNodes = layoutedNodes.map((node) => ({
      ...node,
      hidden: hiddenNodes.has(node.id),
    }));

    const updatedVisibleEdges = layoutedEdges.map((edge) => ({
      ...edge,
      hidden: hiddenEdges.has(edge.id),
    }));

    setVisibleNodes(updatedVisibleNodes);
    setVisibleEdges(updatedVisibleEdges);

    if (updatedVisibleNodes.length > 0 && !initialHideDone) {
      const firstNode = updatedVisibleNodes[0];
      setTimeout(() => {
        setHighlightedNodeId(firstNode.id);
        setShowEmptyDrawerPopup(true);

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        setCenter(
          firstNode.position.x + viewportWidth / 5,
          firstNode.position.y + viewportHeight / 8,
          {
            zoom: 1.3,
            duration: 800,
          }
        );
        setInitialHideDone(true);
      }, 100);
    }

    const updatedObservableDetails = observableDetails.map((item) => {
      const visibleNode = updatedVisibleNodes.find(
        (node) => node.id === item.id
      );
      if (visibleNode) {
        const { data, ...restVisibleNode } = visibleNode;
        return { ...item, ...restVisibleNode };
      }
      return item;
    });

    setObservableDetails(updatedObservableDetails);

    // Cleanup function (if needed)
    return () => {
      // Perform any necessary cleanup here
    };
  }, [workflowStructure, hiddenNodes, hiddenEdges]);

  const renderWorkflowStructure = () => {
    return (
      <div
        className={`workflow-container ${
          isOverlayVisible ? "loading-overlay" : ""
        }`}
        ref={containerRef}
      >
        <ReactFlow
          nodes={visibleNodes.map((node) => ({
            ...node,
            className: node.id === selectedNode?.id ? "selected-node" : "",
          }))}
          edges={visibleEdges}
          onNodeClick={handleNodeClick}
          onPaneClick={handlePaneClick}
          style={{ width: "100%", height: "100%" }}
          fitView
          className="reactflow-container"
          ref={containerRef}
          nodesDraggable={true}
          nodesConnectable={false}
          snapToGrid={true}
          snapGrid={[15, 15]}
          proOptions={{ hideAttribution: true }}
        >
          {loadingStructure && (
            <div className="loading-spinner-corner-workflow">
              <div className="loading-spinner"></div>
            </div>
          )}
          <Background />
        </ReactFlow>
      </div>
    );
  };

  const handlePaneClick = () => {
    setSelectedNode(null);
    setShowEmptyDrawerPopup(true);
  };

  const handleFilterToggle = async (type, value) => {
    let newFilters = { ...activeFilters };

    if (value === null) {
      // Handle filter removal
      if (type === "run_start") {
        delete newFilters.run_start;
        setRunStartFilter(null);
        setRunStartDisplay("");
      } else if (type === "run_end") {
        delete newFilters.run_end;
        setRunEndFilter(null);
        setRunEndDisplay("");
      } else if (type === "status") {
        delete newFilters.status;
        setOpenStatusDropdown(null);
      }
    } else if (type === "status") {
      newFilters.status = value;
      setOpenStatusDropdown(null);
    } else if (type === "run_start" || type === "run_end") {
      if (Array.isArray(value)) {
        const [start, end] = value;
        if (start && end) {
          if (type === "run_start") {
            setRunStartFilter([start, end]);
            setRunStartDisplay(
              `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
            );
            newFilters.run_start = [start, end];
          } else {
            setRunEndFilter([start, end]);
            setRunEndDisplay(
              `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
            );
            newFilters.run_end = [start, end];
          }
        }
      } else if (value === "Last 7 days" || value === "Last 30 days") {
        const end = new Date();
        const start = new Date();
        start.setDate(start.getDate() - (value === "Last 7 days" ? 7 : 30));

        const formattedDates = [start, end];

        if (type === "run_start") {
          setRunStartFilter(formattedDates);
          setRunStartDisplay(value);
          newFilters.run_start = formattedDates;
        } else {
          setRunEndFilter(formattedDates);
          setRunEndDisplay(value);
          newFilters.run_end = formattedDates;
        }
      }
    }

    setActiveFilters(newFilters);
    currentFiltersRef.current = newFilters;

    setCurrentPage(1);
    await fetchWorkflowData({
      filters: newFilters,
      page: 1,
    });
    setShowCustomCalendar(false);
  };

  const toggleDropdown = (filterType) => {
    if (filterType === "status") {
      setOpenStatusDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStartDropdown(false);
      setOpenSearchDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "run_start") {
      setOpenStartDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStatusDropdown(false);
      setOpenSearchDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "run_end") {
      setOpenEndDropdown((prev) => !prev);
      setOpenStartDropdown(false);
      setOpenStatusDropdown(false);
      setOpenSearchDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "searchBy") {
      setOpenSearchDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStartDropdown(false);
      setOpenStatusDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "version") {
      setOpenVersionDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStartDropdown(false);
      setOpenStatusDropdown(false);
      setOpenSearchDropdown(false);
    }
  };

  const handleIdClick = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const handleClosePopup = () => {
    setSelectedNode(null);
    setIsDrawerOpen(false);
    setShowEmptyDrawerPopup(false);
  };

  const handleSortClick = (column) => {
    console.log("Handle sort click", column);
    console.log("sortDirection", sortDirection);

    var newDirection = sortDirection;

    if (spinningColumn !== column) {
      setSpinningColumn(column);

      if (column === "status") {
        const newIndex = (statusSortIndex + 1) % statusOrder.length;
        setStatusSortIndex(newIndex);
        setSortDirection(statusOrder[newIndex]);
        newDirection = statusOrder[newIndex];
      } else {
        if (sortColumn === column && column !== "status") {
          setSortDirection(sortDirection === "asc" ? "desc" : "asc");
          newDirection = sortDirection === "asc" ? "desc" : "asc";
        } else {
          setSortColumn(column);
          setSortDirection("asc");
          newDirection = "asc";
        }
      }

      setCurrentPage(1);
      fetchWorkflowData({
        newSort: column,
        newDirection: newDirection,
        page: 1,
      });

      setTimeout(() => {
        setSpinningColumn(null);
      }, 200);
    }
  };

  const handlePageChange = async (newPage) => {
    console.log("Handle page change", newPage);
    await fetchWorkflowData({
      page: newPage,
      filters: currentFiltersRef.current,
      appendResults: true,
    });
  };

  const handleVersionSelect = async (versions) => {
    await fetchWorkflowData({
      page: 1,
      newSelectedVersions: versions,
    });
  };

  const handleSearch = async (identifier, searchTerm) => {
    if (!searchTerm) {
      setSelectedIdentifier(identifier);
      setSearchTerm("");
      return;
    }

    console.log("handleSearch", identifier, searchTerm);
    setSelectedIdentifier(identifier);
    await fetchWorkflowData({
      page: 1,
      newIdentifier: identifier.identifier_name,
      newSearchTerm: searchTerm,
    });
  };

  const clearSearch = async () => {
    setSearchTerm("");
    await fetchWorkflowData({
      page: 1,
      newIdentifier: null,
      newSearchTerm: null,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".App-filter-dropdown")) {
        setOpenStatusDropdown(false);
        setOpenStartDropdown(false);
        setOpenEndDropdown(false);
        setOpenSearchDropdown(false);
        setOpenVersionDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleExpand = (runId) => {
    setExpandedRunIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(runId)) {
        newIds.delete(runId);
      } else {
        newIds.add(runId);
      }
      return newIds;
    });
  };

  const updateBreadcrumbs = async (crumb) => {
    // Get existing breadcrumbs from session storage or initialize empty array
    let theseBreadcrumbs = [];
    try {
      const storedBreadcrumbs = sessionStorage.getItem("breadcrumbs");
      theseBreadcrumbs = storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
    } catch (error) {
      console.warn("Error parsing stored breadcrumbs:", error);
      theseBreadcrumbs = [];
    }

    // Check if this workflow already exists in breadcrumbs
    const existingIndex = theseBreadcrumbs.findIndex(
      (c) => c.workflow_id === crumb.workflow_id
    );

    // Update breadcrumbs array
    if (existingIndex >= 0) {
      // Update existing breadcrumb
      theseBreadcrumbs[existingIndex] = crumb;
    } else {
      // Add new breadcrumb
      theseBreadcrumbs.push(crumb);
    }

    // Update session storage and state
    try {
      sessionStorage.setItem("breadcrumbs", JSON.stringify(theseBreadcrumbs));
      setBreadcrumbs(theseBreadcrumbs);
      setCurrentWorkflowName(crumb.name);
    } catch (error) {
      console.error("Error updating breadcrumbs:", error);
    }
  };

  // FOR CLICKING ON CHILD WORKFLOW
  const handleWorkflowClick = async (thisWorkflowInfo) => {
    console.log("handleWorkflowClick", thisWorkflowInfo);

    setIsOverlayVisible(true);
    setShouldLoadData(false);

    try {
      const structureData = await fetchWorkflowStructure(thisWorkflowInfo.id);

      if (structureData) {
        const initialHiddenNodes = initializeHiddenNodes(structureData);

        // Batch update the state to prevent flickering
        setWorkflowStructure(structureData);
        setHiddenNodes(initialHiddenNodes);
        setObservableDetails(
          structureData.filter((item) => item.type === "observable")
        );
        setCurrentWorkflowName(
          thisWorkflowInfo.display_name
            ? thisWorkflowInfo.display_name
            : thisWorkflowInfo.name
        );

        // Pass the entire crumb object
        const crumb = {
          workflow_id: thisWorkflowInfo.workflow_id,
          id: thisWorkflowInfo.id,
          name: thisWorkflowInfo.display_name || thisWorkflowInfo.name,
        };
        updateBreadcrumbs(crumb);

        setThisWorkflowId(thisWorkflowInfo.workflow_id);
        setSelectedNode(null);
        setHighlightedNodeId(structureData[0]?.id || null);
        setSelectedRunId(thisWorkflowInfo.id);
      } else {
        console.warn(
          "Failed to fetch workflow structure for the selected run."
        );
      }
    } catch (error) {
      console.error("Error handling workflow click:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  // FOR CLICKING ON CHILD WORKFLOW LINK OR BREADCRUMB
  const handleWorkflowLinkClick = async (thisWorkflowInfo) => {
    console.log("handleWorkflowLinkClick", thisWorkflowInfo);
    setWorkflowStructure(null);
    setHighlightedNodeId(null);
    setSelectedRunId(thisWorkflowInfo.id);
    setInitialHideDone(false);
    setIsOverlayVisible(true);
    setShouldLoadData(false);

    // Reset breadcrumbs to just this workflow
    const newBreadcrumb = {
      workflow_id: thisWorkflowInfo.workflow_id,
      id: thisWorkflowInfo.id,
      name: thisWorkflowInfo.display_name || thisWorkflowInfo.name,
    };
    setBreadcrumbs([newBreadcrumb]);
    sessionStorage.setItem("breadcrumbs", JSON.stringify([newBreadcrumb]));

    navigate(
      `${
        env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""
      }/workflow/${thisWorkflowInfo.id}`
    );

    try {
      // Fetch workflow structure
      const structureData = await fetchWorkflowStructure(thisWorkflowInfo.id);

      // Fetch new instances
      const allData = await fetchFilteredInstances(
        thisWorkflowInfo.id,
        1,
        activeFilters,
        sortColumn,
        sortDirection,
        identifierName,
        searchTerm,
        selectedVersions?.length > 0 ? selectedVersions : ["All"]
      );

      // Update instances state
      setTotal(allData.response.data.total_count || 0);
      setTotalPages(Math.ceil(allData.response.data.total_count / 25));
      setRuns(allData.processedInstances);

      // Fetch and update child workflows
      if (allData.response.data.customer_id) {
        const idsList = allData.processedInstances.map(
          (instance) => instance.id
        );
        const newChildWorkflows = await fetchChildWorkflows(
          idsList,
          allData.response.data.customer_id,
          thisWorkflowInfo.id
        );
        setChildWorkflows(newChildWorkflows);
      }

      if (structureData) {
        setWorkflowStructure(structureData);
        setHiddenNodes(initializeHiddenNodes(structureData));
        setCurrentWorkflowName(
          thisWorkflowInfo.display_name
            ? thisWorkflowInfo.display_name
            : thisWorkflowInfo.name
        );
        updateBreadcrumbs(
          thisWorkflowInfo.workflow_id,
          thisWorkflowInfo.display_name,
          thisWorkflowInfo.id
        );
        setThisWorkflowId(thisWorkflowInfo.workflow_id);

        setObservableDetails(
          structureData.filter((item) => item.type === "observable")
        );
        setSelectedNode(null);
        setHighlightedNodeId(structureData[0]?.id || null);
        setShouldLoadData(false);
      } else {
        console.warn(
          "Failed to fetch workflow structure for the selected run."
        );
      }
    } catch (error) {
      console.error("Error handling workflow click:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleRunClick = async (event, run) => {
    event.preventDefault();
    setInitialHideDone(false);

    // Clear existing state
    setWorkflowStructure(null);
    setHighlightedNodeId(null);
    setLlmSummary("");
    setObservableSummary("");
    setSelectedRunId(run.id);
    setSelectedNode(null);
    setShowEmptyDrawerPopup(true);
    setIsRunClick(true);

    if (
      event &&
      event.target &&
      event.target.closest(".see-more-button, .dropdown")
    ) {
      return;
    }

    // If this is a parent workflow (exists in first position of breadcrumbs)
    // then reset breadcrumbs to just that workflow
    if (breadcrumbs.length > 0 && breadcrumbs[0].id !== run.id) {
      setBreadcrumbs([breadcrumbs[0]]);
      sessionStorage.setItem("breadcrumbs", JSON.stringify([breadcrumbs[0]]));
    }

    navigate(
      `${
        env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""
      }/workflow/${run.id}`
    );

    try {
      const structureData = await fetchWorkflowStructure(run.id);

      if (structureData) {
        console.log(
          "New workflow structure received, nodes:",
          structureData.length
        );

        const initialHiddenNodes = new Set();
        if (showObservables) {
          console.log(
            "Hiding nodes because showObservables is:",
            showObservables
          );
          structureData.forEach((node) => {
            if (
              node.type === "action" ||
              node.type === "llm_call" ||
              (node.type === "observable" && node.trigger_observable_id)
            ) {
              initialHiddenNodes.add(node.id);
            }
          });
        } else {
          console.log(
            "Showing all nodes because showObservables is:",
            showObservables
          );
        }

        console.log("Number of hidden nodes:", initialHiddenNodes.size);
        setHiddenNodes(initialHiddenNodes);
        setWorkflowStructure(structureData);
        const observableDetails = structureData.filter(
          (item) => item.type === "observable"
        );
        setObservableDetails(observableDetails);
        setHighlightedNodeId(structureData[0]?.id || null);

        const workflowInfo = await fetchWorkflowInstanceInfo(run.id);

        if (workflowInfo) {
          setCurrentWorkflowName(
            workflowInfo.display_name
              ? workflowInfo.display_name
              : workflowInfo.name
          );
          setThisWorkflowId(workflowInfo.workflow_id);
          const createdAt =
            run.start_time ||
            (workflowInfo.created_at
              ? new Date(workflowInfo.created_at)
              : null);
          setWorkflowInstanceCreatedAt(createdAt ? createdAt : "N/A");
          setWorkflowInstanceEndedAt(run.end_time ? run.end_time : "N/A");
        }
      }
    } catch (error) {
      console.error("Error fetching workflow structure:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleNodeClick = async (event, node) => {
    if (!node) {
      console.warn("Node is undefined:", node);
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    if (selectedNode && selectedNode.id === node.id) {
      setSelectedNode(null);
      return;
    }

    setLlmSummary("");
    setLlmImages([]);
    setObservableSummary("");
    setSelectedNode(node);
    setIsDrawerOpen(true);
    centerAndZoomOnNode(node);

    try {
      if (node.type === "llm_call") {
        const controller = new AbortController();
        abortControllerRef.current = controller;

        // Create cleanup function for state updates
        let isSubscribed = true;

        await fetchLlmCallSummary(
          node.id,
          (chunk) => {
            if (!isSubscribed) return; // Don't update state if unmounted
            if (typeof chunk === "object") {
              if (chunk.type === "images") {
                setLlmImages(chunk.data);
              } else if (chunk.type === "text") {
                setLlmSummary((prev) => prev + chunk.data);
              }
            } else {
              setLlmSummary((prev) => prev + chunk);
            }
          },
          controller
        );

        return () => {
          isSubscribed = false;
          controller.abort();
        };
      } else if (node.type === "observable") {
        await fetchObservableSummary(
          node.id,
          (chunk) => {
            setObservableSummary((prev) => prev + chunk);
          },
          abortControllerRef.current
        );
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log(
          "Fetch request was aborted, which is expected when switching nodes quickly."
        );
      } else {
        console.error("Error fetching node summary:", err);
      }
    }
  };

  const centerAndZoomOnNode = (node) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    if (node && node.position) {
      const { x, y } = node.position;
      setCenter(x + viewportWidth / 5, y + viewportHeight / 8, {
        zoom: 1.3,
        duration: 800,
      });
    } else {
      setCenter(viewportWidth / 5, viewportHeight / 8, {
        zoom: 1.3,
        duration: 800,
      });
    }
  };

  useEffect(() => {
    const loadFullWorkflowData = async () => {
      if (!workflowInstanceId || !shouldLoadData) return;

      if (isRunClick) {
        setIsRunClick(false);
        return;
      }

      try {
        setIsOverlayVisible(true);
        setWorkflowStructure(null);
        setHighlightedNodeId(null);
        setSelectedRunId(null);
        setInitialHideDone(false);

        // Fetch workflow instance info first to get workflow name
        const workflowInfo = await fetchWorkflowInstanceInfo(
          workflowInstanceId
        );
        if (workflowInfo) {
          const workflowName = workflowInfo.display_name || workflowInfo.name;
          setCurrentWorkflowName(workflowName);

          // Create new breadcrumb
          const newBreadcrumb = {
            workflow_id: workflowInfo.workflow_id,
            id: workflowInstanceId,
            name: workflowName,
          };

          // Update breadcrumbs
          setBreadcrumbs([newBreadcrumb]);
          sessionStorage.setItem(
            "breadcrumbs",
            JSON.stringify([newBreadcrumb])
          );
        }

        setCurrentWorkflowName(currentWorkflowName);
        setWorkflowInstanceCreatedAt(workflowInstanceCreatedAt);
        setWorkflowInstanceEndedAt(workflowInstanceEndedAt);
        let targetInstanceId = workflowInstanceId;
        if (!targetInstanceId) {
          const latestInstance = await fetchLatestWorkflowInstance(
            workflowInstanceId
          );
          targetInstanceId = latestInstance.id;
        }

        await initializeData(workflowInstanceId, currentWorkflowName);
        setHasInitialized(true);
      } catch (error) {
        console.error("Error loading workflow data:", error);
      } finally {
        setIsOverlayVisible(false);
      }
    };

    loadFullWorkflowData();
  }, [workflowInstanceId]);

  const handleBackButtonClick = () => {
    sessionStorage.removeItem("breadcrumbs");
    setBreadcrumbs([]);
  };

  useEffect(() => {
    return () => {
      handleBackButtonClick();
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleLeftDrawer = () => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  };

  const handleSeeMoreClickError = (errors) => {
    setErrorMessage(errors);
    setShowErrorPopup(true);
    setActualShowErrorPopup(false);
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const initializeHiddenNodes = (workflowStructure) => {
    const newHiddenNodes = new Set();
    const newHiddenChildrenNodeIds = new Set();

    workflowStructure.forEach((node) => {
      if (node.type === "observable") {
        const childEdges = workflowStructure.filter(
          (n) => n.data?.item?.trigger_observable_id === node.id
        );

        childEdges.forEach((child) => {
          newHiddenNodes.add(child.id);
          newHiddenChildrenNodeIds.add(node.id);

          const findDescendants = (nodeId) => {
            workflowStructure.forEach((n) => {
              if (n.data?.item?.trigger_observable_id === nodeId) {
                newHiddenNodes.add(n.id);
                findDescendants(n.id);
              }
            });
          };

          findDescendants(child.id);
        });
      }
    });

    setHiddenChildrenNodeIds(newHiddenChildrenNodeIds);
    return newHiddenNodes;
  };

  const toggleActionAndLlmCalls = () => {
    console.log("Toggle clicked - Current showObservables:", showObservables);
    console.log("Current hidden nodes:", hiddenNodes.size);

    setShowObservables(!showObservables);

    setWorkflowStructure((prevStructure) => {
      const newHiddenNodes = new Set(hiddenNodes);

      prevStructure.forEach((node) => {
        if (node.type === "action" || node.type === "llm_call") {
          if (showObservables) {
            newHiddenNodes.add(node.id);
          } else {
            newHiddenNodes.delete(node.id);
          }
        } else if (node.type === "observable" && node.trigger_observable_id) {
          if (showObservables) {
            newHiddenNodes.add(node.id);
          } else {
            newHiddenNodes.delete(node.id);
          }
        }
      });

      setHiddenNodes(newHiddenNodes);
      return prevStructure;
    });
  };

  useEffect(() => {
    if (workflowStructure) {
      setWorkflowStructure([...workflowStructure]);
    }
  }, [isObservableViewActive]);

  const fetchWorkflowData = async ({
    page = currentPage,
    filters = activeFilters,
    newSort = sortColumn,
    newDirection = sortDirection,
    newIdentifier = identifierName,
    newSearchTerm = searchTerm,
    appendResults = false,
    newSelectedVersions = selectedVersions,
  }) => {
    const memoryTracker = trackOperation("fetch-workflow-details");
    if (isLoading) return;

    setCurrentPage(page);
    setIsLoading(true);

    try {
      const { response, processedInstances } = await fetchFilteredInstances(
        workflowInstanceId,
        page,
        filters,
        newSort,
        newDirection,
        newIdentifier,
        newSearchTerm,
        newSelectedVersions
          ? newSelectedVersions.length > 0
            ? newSelectedVersions
            : ["All"]
          : ["All"]
      );

      if (response.data.workflow_id) {
        try {
          const identifiers = await fetchIdentifiers(
            response.data.workflow_id,
            workflowInstanceId
          );
          setIdentifiers(identifiers);
        } catch (error) {
          console.error("Error loading identifiers:", error);
        }
      }

      setTotal(response.data.total_count || 0);
      setTotalPages(Math.ceil(response.data.total_count / 25));

      // Update runs while preserving existing data if appending
      setRuns((prevRuns) =>
        appendResults
          ? [...prevRuns, ...processedInstances]
          : processedInstances
      );

      // Get IDs from all instances (including existing ones if appending)
      const idsList = appendResults
        ? [...runs, ...processedInstances].map((instance) => instance.id)
        : processedInstances.map((instance) => instance.id);

      if (response.data.customer_id && processedInstances.length > 0) {
        const thisCustomerId = response.data.customer_id;
        try {
          const newChildWorkflows = await fetchChildWorkflows(
            idsList,
            thisCustomerId,
            workflowInstanceId
          );

          // Merge with existing child workflows if appending
          setChildWorkflows((prevChildWorkflows) =>
            appendResults
              ? { ...prevChildWorkflows, ...newChildWorkflows }
              : newChildWorkflows
          );
        } catch (error) {
          console.error("Error fetching child workflows:", error);
        }
      } else {
        console.log("No instances found, skipping child workflows fetch.");
      }

      // Update other state
      setCurrentPage(page);
      setSortColumn(newSort);
      setSortDirection(newDirection);
      setIdentifierName(newIdentifier);
      setSearchTerm(newSearchTerm);
      setSelectedVersions(newSelectedVersions);
      if (filters !== activeFilters) {
        setActiveFilters(filters);
      }

      return { response, processedInstances };
    } catch (error) {
      console.error("Error fetching workflow data:", error);
      if (!appendResults) setRuns([]);
    } finally {
      setIsLoading(false);
      memoryTracker.end();
    }
  };

  useEffect(() => {
    // Open the right drawer when tooltips are shown
    if (showTooltip) {
      setIsDrawerOpen(true);
    }
  }, [showTooltip]);

  const handleCloseTooltip = () => {
    closeWorkflowTooltipForever(pageName);
    setIsDrawerOpen(false); // Close the drawer when tooltips are closed
  };

  return (
    <div className="WorkflowDetails" ref={containerRef}>
      <Header />
      {showTooltip && (
        <TooltipWorkflows handleCloseTooltip={handleCloseTooltip} />
      )}
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        currentWorkflowInstanceId={workflowInstanceId}
        handleBackButtonClick={handleBackButtonClick}
        handleWorkflowClick={handleWorkflowClick}
        thisWorkflowId={thisWorkflowId}
      />
      {workflowStructure &&
        workflowStructure.some(
          (node) => node.type === "action" || node.type === "llm_call"
        ) && (
          <button
            className={`top-right-filter-button ${
              isDrawerOpen ? "drawer-open" : ""
            }`}
            onClick={toggleActionAndLlmCalls}
          >
            {showObservables ? "Collapse" : "Expand"}
          </button>
        )}
      <button className="drawer-toggle-button" onClick={toggleLeftDrawer}>
        <FontAwesomeIcon icon={faAnglesRight} />
      </button>
      <LeftDrawer
        toggleLeftDrawer={toggleLeftDrawer}
        handleNodeClick={handleNodeClick}
        isLeftDrawerOpen={isLeftDrawerOpen}
        workflowDescription={workflowDescription}
        observableDetails={observableDetails}
        selectedNode={selectedNode}
        observableListRef={observableListRef}
        fixedBottomHeight={fixedBottomHeight}
      />
      <div className="popup-drawer" onClick={toggleDrawer}>
        <FontAwesomeIcon icon={faAnglesLeft} className="right-angles" />
      </div>
      <div className={`drawer ${isDrawerOpen ? "open" : ""}`} ref={drawerRef}>
        {selectedNode ? (
          <div>
            {(() => {
              switch (selectedNode.type) {
                case "action":
                  return (
                    <ActionNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
                case "llm_call":
                  return (
                    <LLMCallNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      node={selectedNode}
                      copied={copied}
                      llmSummary={llmSummary}
                      llmImages={llmImages}
                    />
                  );
                case "observable":
                  return (
                    <ObservableNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      node={selectedNode}
                      copied={copied}
                      observableSummary={observableSummary}
                    />
                  );
                case "agent":
                  return (
                    <AgentNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
                default:
                  return (
                    <AgentNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
              }
            })()}
          </div>
        ) : (
          <div>
            {(!selectedNode || showEmptyDrawerPopup) && (
              <EmptyDrawerMessagePopup
                currentWorkflowName={currentWorkflowName}
                workflowDescription={workflowDescription}
                onClose={handleClosePopup}
                outputs={selectedRunOutput}
                workflowInstanceId={workflowInstanceId}
                workflowInstanceCreatedAt={workflowInstanceCreatedAt}
                workflowInstanceEndedAt={workflowInstanceEndedAt}
                copied={copied}
                handleIdClick={handleIdClick}
              />
            )}
          </div>
        )}
      </div>
      {renderWorkflowStructure()}
      <WorkflowTable
        handleSortClick={handleSortClick}
        getSortIcon={getSortIcon}
        handleFilterToggle={handleFilterToggle}
        toggleDropdown={toggleDropdown}
        setRunStartFilter={setRunStartFilter}
        setRunStartDisplay={setRunStartDisplay}
        setRunEndFilter={setRunEndFilter}
        setRunEndDisplay={setRunEndDisplay}
        setActiveFilters={setActiveFilters}
        handleSeeMoreClickError={handleSeeMoreClickError}
        setShowCustomCalendar={setShowCustomCalendar}
        handleWorkflowClick={handleWorkflowClick}
        handleRunClick={handleRunClick}
        handleWorkflowLinkClick={handleWorkflowLinkClick}
        toggleExpand={toggleExpand}
        handlePageChange={handlePageChange}
        setFixedBottomHeight={setFixedBottomHeight}
        setSearchByDisplay={setSearchByDisplay}
        setFocusedIndex={setFocusedIndex}
        setOpenSearchDropdown={setOpenSearchDropdown}
        handleSearch={handleSearch}
        setSelectedIdentifier={setSelectedIdentifier}
        clearSearch={clearSearch}
        setCustomDateType={setCustomDateType}
        handleVersionSelect={handleVersionSelect}
        combinedRuns={combinedRuns}
        runStartDisplay={runStartDisplay}
        runEndDisplay={runEndDisplay}
        openStartDropdown={openStartDropdown}
        openEndDropdown={openEndDropdown}
        openStatusDropdown={openStatusDropdown}
        activeFilters={activeFilters}
        runStartFilter={runStartFilter}
        runEndFilter={runEndFilter}
        total={total}
        isLoading={isLoading}
        isDrawerOpen={isDrawerOpen}
        drawerRef={drawerRef}
        showCustomCalendar={showCustomCalendar}
        dateRange={dateRange}
        statusDropdownRef={statusDropdownRef}
        startDropdownRef={startDropdownRef}
        endDropdownRef={endDropdownRef}
        selectedRunId={selectedRunId}
        childWorkflows={childWorkflows}
        expandedRunIds={expandedRunIds}
        totalPages={totalPages}
        currentPage={currentPage}
        searchByDisplay={searchByDisplay}
        searchDropdownRef={searchDropdownRef}
        focusedIndex={focusedIndex}
        openSearchDropdown={openSearchDropdown}
        openVersionDropdown={openVersionDropdown}
        selectedIdentifier={selectedIdentifier}
        selectedVersions={selectedVersions}
        allVersions={allVersions}
        setSelectedVersions={setSelectedVersions}
        identifiers={identifiers}
        customDateType={customDateType}
        workflowInstanceId={workflowInstanceId}
        setSelectedRunId={setSelectedRunId}
      />
      {showErrorPopup && (
        <ErrorPopup
          errorMessage={errorMessage}
          onClose={() => setShowErrorPopup(false)}
        />
      )}
      {actualShowErrorPopup && (
        <ActualErrorPopup
          errorMessage={errorMessage}
          onClose={() => setActualShowErrorPopup(false)}
        />
      )}
    </div>
  );
}

export default WorkflowDetails;
