import * as echarts from "echarts";
import {
  faInfoCircle,
  faChartColumn,
  faChartLine,
  faTable,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatMetricValue,
  formatIntervalDate,
  formatIntervalTooltip,
} from "../utils/formatUtils.js";
import { toggleOverlay, createMetricNameTooltip } from "../utils/chartUtils.js";
import { renderTable } from "./TableChart.js";

export const renderChart = (
  metric,
  container,
  selectedDateRange,
  prepareDataForDownload,
  downloadData,
  zoomState = null
) => {
  // Check for empty interval_data first, before any DOM manipulation
  if (!metric.interval_data || metric.interval_data.length === 0) {
    console.log("No data collected yet");
    container.style.position = "relative";
    toggleOverlay(container, true, "No data collected yet");
  }

  const chartElement = document.createElement("div");
  container.style.display = "flex";
  container.style.flexDirection = "column";
  container.style.alignItems = "center";
  container.style.borderBottom = "none";

  // Calculate width based on data volume and screen width
  const dataPoints = metric.interval_data?.length || 0;
  const minWidth = 400;
  const widthPerPoint = 20;
  const calculatedWidth = Math.max(minWidth, dataPoints * widthPerPoint);
  const screenWidth = window.innerWidth - 100;
  const finalWidth = Math.min(calculatedWidth, screenWidth, 1200);

  const computedStyles = getComputedStyle(document.documentElement);

  const colors = [
    computedStyles.getPropertyValue("--chart-theme-color-1"),
    computedStyles.getPropertyValue("--chart-theme-color-2"),
    computedStyles.getPropertyValue("--chart-theme-color-3"),
    computedStyles.getPropertyValue("--chart-theme-color-4"),
    computedStyles.getPropertyValue("--chart-theme-color-5"),
    computedStyles.getPropertyValue("--chart-theme-color-6"),
    computedStyles.getPropertyValue("--chart-theme-color-7"),
    computedStyles.getPropertyValue("--chart-theme-color-8"),
    computedStyles.getPropertyValue("--chart-theme-color-9"),
    computedStyles.getPropertyValue("--chart-theme-color-10"),
  ];

  container.style.width = `${finalWidth}px`;
  chartElement.style.width = "100%";
  chartElement.style.height = "400px";
  container.appendChild(chartElement);

  // Add resize handler
  const handleResize = () => {
    // Only resize if chart is fully initialized
    if (container.chartInstances && container.chartInstances.length > 0) {
      const newScreenWidth = window.innerWidth - 100;
      const newWidth = Math.min(calculatedWidth, newScreenWidth, 1200);
      container.style.width = `${newWidth}px`;

      // Use setTimeout to ensure resize happens outside the main process
      setTimeout(() => {
        container.chartInstances.forEach((instance) => {
          if (instance && instance.resize) {
            instance.resize();
          }
        });
      }, 0);
    }
  };

  window.addEventListener("resize", handleResize);
  container.chartResizeHandlers = container.chartResizeHandlers || [];
  container.chartResizeHandlers.push(handleResize);

  // Wait for next frame to ensure DOM dimensions are calculated
  requestAnimationFrame(() => {
    let chartData = metric.interval_data || [];

    // Fill in missing intervals if we have data and it's a time-based metric
    if (chartData.length > 0 && metric.time_interval) {
      const filledData = [];

      for (let i = 0; i < chartData.length - 1; i++) {
        const currentDate = new Date(chartData[i].interval_start);
        const nextDate = new Date(chartData[i + 1].interval_start);
        filledData.push(chartData[i]);

        // Calculate expected next date based on interval
        let expectedNext = new Date(currentDate);
        switch (metric.time_interval) {
          case "week":
            expectedNext.setDate(expectedNext.getDate() + 7);
            break;
          case "day":
            expectedNext.setDate(expectedNext.getDate() + 1);
            break;
          case "hour":
            expectedNext.setHours(expectedNext.getHours() + 1);
            break;
          case "month":
            expectedNext.setMonth(expectedNext.getMonth() + 1);
            break;
          case "year":
            expectedNext.setFullYear(expectedNext.getFullYear() + 1);
            break;
          default:
            console.warn(`Unexpected time interval: ${metric.time_interval}`);
            break;
        }

        // If there's a gap, add zero data points
        while (expectedNext < nextDate) {
          filledData.push({
            interval_start: expectedNext.toISOString(),
            measure: 0, // Always use 0 instead of null
            dimension: null,
          });

          // Move to next expected interval
          switch (metric.time_interval) {
            case "week":
              expectedNext.setDate(expectedNext.getDate() + 7);
              break;
            case "day":
              expectedNext.setDate(expectedNext.getDate() + 1);
              break;
            case "hour":
              expectedNext.setHours(expectedNext.getHours() + 1);
              break;
            case "month":
              expectedNext.setMonth(expectedNext.getMonth() + 1);
              break;
            case "year":
              expectedNext.setFullYear(expectedNext.getFullYear() + 1);
              break;
            default:
              console.warn(`Unexpected time interval: ${metric.time_interval}`);
              break;
          }
        }
      }

      // Don't forget to add the last data point
      filledData.push(chartData[chartData.length - 1]);

      chartData = filledData;
    }

    const [startDate, endDate] = selectedDateRange || [null, null];
    if (startDate && endDate) {
      chartData = chartData.filter((item) => {
        const itemDate = new Date(item.interval_start);
        return itemDate >= startDate && itemDate <= endDate;
      });

      // Check if all values are zero
      const allValuesZero = chartData.every((item) => item.measure === 0);

      if (chartData.length === 0 || allValuesZero) {
        container.style.position = "relative";
        toggleOverlay(container, true, "No data within this date range");
      }
    }

    const buttonBackground = computedStyles.getPropertyValue("transparent");
    const textColor = computedStyles.getPropertyValue("--text-color");

    const createStyledBase64Icon = (icon) => {
      const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="background-color: ${buttonBackground}; color: ${textColor}; cursor: pointer; border-radius: 2px; width: 50px; height: 50px; font-family: 'Asap', sans-serif; border: none; margin-right: 5px;">
        <g transform="scale(0.8) translate(64, 64)">
          <path fill="currentColor" d="${icon.icon[4]}"></path>
        </g>
      </svg>`;
      return `image://data:image/svg+xml;base64,${btoa(svgString)}`;
    };

    const chartInstance = echarts.init(chartElement, null);
    if (!container.chartInstances) {
      container.chartInstances = [];
    }
    container.chartInstances.push(chartInstance);
    metric.chartInstance = chartInstance;

    const option = {
      // animation: false,
      backgroundColor: "transparent",
      graphic: [
        {
          type: "group",
          top: 5,
          children: [
            {
              type: "text",
              style: {
                text:
                  metric.metric_name.charAt(0).toUpperCase() +
                  metric.metric_name.slice(1) +
                  " (per " +
                  metric.time_interval +
                  ")",
                font: "bolder 14px Asap",
                fill: computedStyles.getPropertyValue("--text-color"),
                width: 280,
                overflow: "truncate",
              },
              onmouseover: function (params) {
                setTimeout(() => {
                  const existingTooltip = document.getElementById(
                    "metric-name-tooltip"
                  );
                  if (existingTooltip) {
                    existingTooltip.remove();
                  }
                  document.body.appendChild(
                    createMetricNameTooltip(params, computedStyles, metric)
                  );
                }, 50);
              },
              onmouseout: function () {
                setTimeout(() => {
                  const tooltipDom = document.getElementById(
                    "metric-name-tooltip"
                  );
                  if (tooltipDom) {
                    tooltipDom.remove();
                  }
                }, 50);
              },
            },
          ],
        },
      ],
      tooltip: {
        backgroundColor: computedStyles.getPropertyValue("--background-color"),
        textStyle: {
          fontFamily: "Asap",
          color: computedStyles.getPropertyValue("--text-color"),
        },
        trigger: "axis",
        formatter: function (params) {
          const date = new Date(chartData[params[0].dataIndex].interval_start);
          return `${formatIntervalTooltip(
            date.toISOString(),
            metric.time_interval
          )}<br/>
                  Value: <b>${formatMetricValue(
                    params[0].value,
                    metric.metric_format
                  )}</b>`;
        },
      },
      toolbox: {
        show: true,
        showTitle: false,
        feature: {
          myDownload: {
            show: true,
            title: "Download Data",
            icon: createStyledBase64Icon(faFileDownload),
            onclick: () => {
              const options = chartInstance.getOption();
              const dataZoom = options.dataZoom[0];

              // Use interval_data for both OS and non-OS metrics
              const data = metric.interval_data || [];

              // Apply selected date range filter if it exists
              const [startDate, endDate] = selectedDateRange || [null, null];
              let dateFilteredData = [...data];
              if (startDate && endDate) {
                dateFilteredData = data.filter((item) => {
                  const itemDate = new Date(item.interval_start);
                  return itemDate >= startDate && itemDate <= endDate;
                });
              }

              // Fill in missing intervals if necessary
              const filledData = [];
              for (let i = 0; i < dateFilteredData.length - 1; i++) {
                const currentDate = new Date(
                  dateFilteredData[i].interval_start
                );
                const nextDate = new Date(
                  dateFilteredData[i + 1].interval_start
                );
                filledData.push(dateFilteredData[i]);

                // Calculate expected next date based on interval
                let expectedNext = new Date(currentDate);
                switch (metric.time_interval) {
                  case "week":
                    expectedNext.setDate(expectedNext.getDate() + 7);
                    break;
                  case "day":
                    expectedNext.setDate(expectedNext.getDate() + 1);
                    break;
                  case "hour":
                    expectedNext.setHours(expectedNext.getHours() + 1);
                    break;
                  case "month":
                    expectedNext.setMonth(expectedNext.getMonth() + 1);
                    break;
                  case "year":
                    expectedNext.setFullYear(expectedNext.getFullYear() + 1);
                    break;
                  default:
                    console.warn(
                      `Unexpected time interval: ${metric.time_interval}`
                    );
                    break;
                }

                // If there's a gap, add zero data points
                while (expectedNext < nextDate) {
                  filledData.push({
                    interval_start: expectedNext.toISOString(),
                    measure: 0, // Always use 0 instead of null
                    dimension: null,
                  });

                  // Move to next expected interval
                  switch (metric.time_interval) {
                    case "week":
                      expectedNext.setDate(expectedNext.getDate() + 7);
                      break;
                    case "day":
                      expectedNext.setDate(expectedNext.getDate() + 1);
                      break;
                    case "hour":
                      expectedNext.setHours(expectedNext.getHours() + 1);
                      break;
                    case "month":
                      expectedNext.setMonth(expectedNext.getMonth() + 1);
                      break;
                    case "year":
                      expectedNext.setFullYear(expectedNext.getFullYear() + 1);
                      break;
                    default:
                      console.warn(
                        `Unexpected time interval: ${metric.time_interval}`
                      );
                      break;
                  }
                }
              }

              // Don't forget to add the last data point
              filledData.push(dateFilteredData[dateFilteredData.length - 1]);

              // Apply zoom filter to the filled data
              const startIndex = Math.floor(
                (dataZoom.start * filledData.length) / 100
              );
              const endIndex = Math.ceil(
                (dataZoom.end * filledData.length) / 100
              );

              const zoomFilteredData = filledData.slice(startIndex, endIndex);

              // Filter out entries with a measure of 0
              const nonZeroData = zoomFilteredData.filter(
                (item) => item.measure !== 0
              );

              // Create filtered data based on slider range
              const downloadableData = prepareDataForDownload(
                nonZeroData,
                metric
              );

              const formattedFilename = `${metric.workflow_name}_${
                metric.metric_name
              }_${new Date().toISOString().split("T")[0]}`.replace(/ /g, "_");

              downloadData(downloadableData, formattedFilename, metric);
            },
          },
          magicType: {
            type: ["bar", "line"],
            title: {
              line: "Switch to Line Chart",
              bar: "Switch to Bar Chart",
            },
            icon: {
              line: createStyledBase64Icon(faChartLine),
              bar: createStyledBase64Icon(faChartColumn),
            },
            option: {
              line: {
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: colors[0] + "CC" },
                    { offset: 1, color: colors[0] + "00" },
                  ]),
                },
              },
            },
          },
          dataView: {
            readOnly: true,
            optionToContent: function (opt) {
              const currentOption = metric.chartInstance.getOption();
              const zoomState = currentOption.dataZoom[0];

              console.log(
                "Converting to table - Current zoom state:",
                zoomState
              );

              // Store the complete chart state
              metric.chartState = {
                type: metric.metric_type,
                originalOption: JSON.parse(JSON.stringify(currentOption)), // Deep copy of chart options
                fullData: [...(metric.interval_data || [])],
                zoomConfig: zoomState,
              };

              metric.original_metric_type = metric.metric_type;
              metric.metric_type = "table";

              container.innerHTML = "";
              renderTable(
                metric,
                container,
                selectedDateRange,
                prepareDataForDownload,
                downloadData,
                zoomState
              );

              return "";
            },
            title: "Switch to Table",
            icon: createStyledBase64Icon(faTable),
            onclick: function () {
              if (metric.metric_type !== "table") {
                const currentOption = metric.chartInstance.getOption();
                const zoomState = currentOption.dataZoom[0];

                metric.chartState = {
                  type: metric.metric_type,
                  originalOption: JSON.parse(JSON.stringify(currentOption)),
                  fullData: [...(metric.interval_data || [])],
                  zoomConfig: zoomState,
                };

                metric.original_metric_type = metric.metric_type;
                metric.metric_type = "table";

                container.innerHTML = "";
                renderTable(
                  metric,
                  container,
                  selectedDateRange,
                  prepareDataForDownload,
                  downloadData,
                  zoomState
                );
              }
            },
          },
          myInfo: {
            show: true,
            title: metric.metric_definition,
            icon: createStyledBase64Icon(faInfoCircle),
            onclick: function () {},
          },
        },
        tooltip: {
          show: true,
          formatter: function (param) {
            return "<div>" + param.title + "</div>";
          },
          backgroundColor:
            computedStyles.getPropertyValue("--background-color"),
          textStyle: {
            fontSize: 12,
            color: computedStyles.getPropertyValue("--text-color"),
          },
          extraCssText:
            "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); max-width: 300px; overflow: break; white-space: normal;",
          trigger: "axis",
          axisPointer: { type: "shadow" },
          confine: true,
          position: function (point, params, dom, rect, size) {
            return [point[0] + 15, point[1] + 15];
          },
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "15%",
        top: "10%",
        containLabel: true,
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: zoomState ? zoomState.start : 0,
          end: zoomState ? zoomState.end : 100,
          height: 20,
          handleSize: "100%",
          textStyle: {
            fontFamily: "Asap",
            color: computedStyles.getPropertyValue("--text-color"),
          },
        },
      ],
      xAxis: {
        type: "category",
        data: chartData.map((item) =>
          formatIntervalDate(item.interval_start, metric.time_interval)
        ),
        axisLine: {
          lineStyle: {
            color: computedStyles.getPropertyValue("--chart-border-color"),
          },
        },
        axisLabel: {
          fontFamily: "Asap",
          rotate: 45,
          interval:
            chartData.length <= 10
              ? 0
              : Math.max(
                  1,
                  Math.floor(chartData.length / (window.innerWidth / 100))
                ),
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: computedStyles.getPropertyValue("--chart-border-color"),
          },
        },
        splitLine: {
          lineStyle: {
            opacity: 0.2,
          },
        },
        min: 0, // Ensure y-axis starts at 0
      },
      series: [
        {
          type: metric.metric_type === "line_chart" ? "line" : "bar",
          data: chartData.map((interval) => interval.measure),
          stack: metric.metric_type === "stacked_bar_chart" ? "total" : null,
          connectNulls: false,
          showSymbol: true,
          symbolSize: 8,
          symbol: "circle",
          itemStyle: {
            color:
              metric.metric_type === "line_chart"
                ? new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: (colors[0] || "#5470c6") + "FF" },
                    { offset: 1, color: (colors[0] || "#5470c6") + "66" },
                  ])
                : colors[0], // Solid color for bar charts
          },
          lineStyle: {
            width: 2,
            shadowColor: "rgba(0,0,0,0.3)",
            shadowBlur: 10,
            shadowOffsetY: 5,
          },
          areaStyle:
            metric.metric_type === "line_chart"
              ? {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: (colors[0] || "#5470c6") + "FF" },
                    { offset: 1, color: (colors[0] || "#5470c6") + "66" },
                  ]),
                }
              : undefined,
          smooth: false,
        },
      ],
    };

    chartInstance.setOption(option);
  });
};
