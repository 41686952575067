import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { EnvironmentContext } from "../contexts/EnvironmentContext.js";
import { useBreadcrumbs } from "../contexts/BreadcrumbsContext.js";

function Breadcrumbs({
  handleBackButtonClick,
  handleWorkflowClick,
  thisWorkflowId,
}) {
  const { env } = useContext(EnvironmentContext);
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();

  return (
    <div className="fixed-top">
      <Link
        to={`${env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""}/`}
        className="back-button"
        onClick={handleBackButtonClick}
      >
        Home
      </Link>
      <div className="breadcrumbs">
        {(breadcrumbs || []).map((crumb) => {
          return (
            <React.Fragment key={crumb.workflow_id}>
              {crumb.name && " / "}
              {crumb.workflow_id === thisWorkflowId ? (
                <span className="current-breadcrumb">{crumb.name}</span>
              ) : (
                <span
                  onClick={() => {
                    handleWorkflowClick(crumb);
                    updateBreadcrumbs(crumb);
                  }}
                  className="not-current-breadcrumb"
                >
                  {crumb.name}
                </span>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Breadcrumbs;
