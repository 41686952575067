export function getConfig() {
  const config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    apiBaseUrl: process.env.REACT_APP_API_ORIGIN,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    version: "2025.04.001",
    env: "prod",
  };

  return config;
}

export default getConfig;
