import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config.js";
import history from "./utils/history.js";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { BreadcrumbsProvider } from "./contexts/BreadcrumbsContext.js";
import { EnvironmentProvider } from "./contexts/EnvironmentContext.js";
import { FilterProvider } from "./contexts/FilterContext.js";
import { ObservableViewProvider } from "./contexts/ObservableViewContext.js";
import { UserProvider } from "./contexts/UserContext.js";
import { TooltipProvider } from "./contexts/TooltipContext.js";
import { WorkflowTooltipProvider } from "./contexts/WorkflowTooltipContext.js";
import { ThemeProvider } from "./contexts/ThemeContext.js";
import { monitorMemoryUsage } from "./utils/memoryMonitor.js";
import { FeedbackProvider } from "./contexts/FeedbackContext.js";
import { FeedbackFilterProvider } from "./contexts/FeedbackFilterContext.js";

// Start memory monitoring in development
monitorMemoryUsage();

const onRedirectCallback = (appState) => {
  console.log("Auth0 redirect callback - appState:", appState);
  const targetUrl = appState?.returnTo || window.location.origin;
  console.log("Redirecting to:", targetUrl);
  history.push(targetUrl);
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  useFormData: true,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
    scope: "openid profile email",
  },
  cacheLocation: "localstorage",
};

const forceHttps = () => {
  if (
    window.location.protocol === "http:" &&
    !window.location.hostname.includes("localhost")
  ) {
    window.location.href = window.location.href.replace("http:", "https:");
    return true;
  }
  return false;
};

const AppWithProviders = () => {
  if (forceHttps()) {
    return null;
  }

  return (
    <Auth0Provider {...providerConfig}>
      <Router>
        <React.StrictMode>
          <ThemeProvider>
            <TooltipProvider>
              <WorkflowTooltipProvider>
                <FilterProvider>
                  <FeedbackFilterProvider>
                    <UserProvider>
                      <EnvironmentProvider>
                        <ObservableViewProvider>
                          <BreadcrumbsProvider>
                            <ReactFlowProvider>
                              <FeedbackProvider>
                                <App />
                              </FeedbackProvider>
                            </ReactFlowProvider>
                          </BreadcrumbsProvider>
                        </ObservableViewProvider>
                      </EnvironmentProvider>
                    </UserProvider>
                  </FeedbackFilterProvider>
                </FilterProvider>
              </WorkflowTooltipProvider>
            </TooltipProvider>
          </ThemeProvider>
        </React.StrictMode>
      </Router>
    </Auth0Provider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<AppWithProviders />);
