// LeftDrawer.js
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import qurrentLogo from "../assets/qurrent_logo.png";
import { formatName } from "../utils/formatUtils.js";

function LeftDrawer({
  isLeftDrawerOpen,
  toggleLeftDrawer,
  observableDetails,
  selectedNode,
  handleNodeClick,
  observableListRef,
  fixedBottomHeight,
}) {
  const [drawerHeight, setDrawerHeight] = useState(fixedBottomHeight);

  useEffect(() => {
    setDrawerHeight(window.innerHeight - fixedBottomHeight - 115);
  }, [fixedBottomHeight]);

  let lastNodeName = null;

  return (
    <div
      className={`left-drawer ${isLeftDrawerOpen ? "open" : ""}`}
      style={{ height: `${drawerHeight}px` }}
    >
      <div className="left-drawer-header">
        <button onClick={toggleLeftDrawer} className="close-button-left-drawer">
          &times;
        </button>
      </div>
      <ul className="observable-list">
        <li className="start-message-item">
          <img
            src={qurrentLogo}
            className="left-drawer-node-icon"
            alt="Qurrent logo"
          />
        </li>
        <li className="observable-item start-message">
          <div className="start-bubble">
            Starting...
            <div className="start-tail"></div>
          </div>
        </li>
        {observableDetails.map((node, index) => {
          const nodeName = node.console_agent
            ? formatName(node.console_agent.name)
            : "Unknown Agent";
          const nodeDescription =
            node.description || "No description available";

          const showNodeTitle = nodeName !== lastNodeName;
          lastNodeName = nodeName;

          return (
            <li
              key={index}
              className={`observable-item ${
                selectedNode?.id === node.id ? "selected" : ""
              }`}
              onClick={() => handleNodeClick(null, node)}
              tabIndex={0}
              ref={(el) => (observableListRef.current[index] = el)}
            >
              {showNodeTitle && (
                <div className="node-title">
                  <FontAwesomeIcon
                    icon={faRobot}
                    className="left-drawer-node-icon-agent"
                  />
                  {nodeName}
                </div>
              )}
              <div className="speech-bubble">{nodeDescription}</div>
            </li>
          );
        })}
        <li className="start-message-item">
          <img
            src={qurrentLogo}
            className="left-drawer-node-icon"
            alt="Qurrent logo"
          />
        </li>
        <li className="observable-item start-message">
          <div className="start-bubble">
            End.
            <div className="start-tail"></div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default LeftDrawer;
