import Header from "./Header.js";
import "react-calendar/dist/Calendar.css";
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MainTable from "./main_components/MainTable.js";
import Pagination from "./main_components/Pagination.js";
import ErrorNotification from "./main_components/ErrorNotification.js";
import Cards from "./main_components/Cards.js";
import FilterRow from "./main_components/FilterRow.js";
import { useFilter } from "./contexts/FilterContext.js";
import { useUser } from "./contexts/UserContext.js";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";
import { useApiUtils } from "./utils/apiUtils.js";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { trackOperation } from "./utils/memoryMonitor.js";
import { useTooltip } from "./contexts/TooltipContext.js";

function MainContent() {
  const { fetchWorkflowInstances, fetchTotalRuns, fetchCustomers } =
    useApiUtils();

  const navigate = useNavigate();
  const { user } = useAuth0();
  const { filters, setFilters } = useFilter();
  const {
    userIsQurrent,
    customerViewActive,
    isImpersonating,
    setCustomers,
    selectedCustomerForImpersonation,
    impersonatedCustomer,
  } = useUser();

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  const submitButtonRef = useRef(null);
  const calendarRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);

  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [warningCount, setWarningCount] = useState(0);
  const [totalWorkflows, setTotalWorkflows] = useState(0);
  const [totalRuns, setTotalRuns] = useState(0);

  const [sortColumn, setSortColumn] = useState("last_run");
  const [sortDirection, setSortDirection] = useState("desc");
  const [statusSortIndex, setStatusSortIndex] = useState(0);
  const statusOrder = ["created", "completed", "failed", "warning", "waiting"];

  const [openDropdown, setOpenDropdown] = useState(null);

  const [stateIsLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [workflows, setWorkflows] = useState([]);

  const [spinningColumn, setSpinningColumn] = useState(null);

  const [pageInput, setPageInput] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);

  const { switchEnvironment } = useContext(EnvironmentContext);

  const { shouldShowTooltips, closeTooltipForever } = useTooltip();
  const pageName = "MainContent";
  const showTooltip = shouldShowTooltips(pageName);

  const searchTimeoutRef = useRef(null);
  const { env } = useContext(EnvironmentContext);

  const resetToFirstPage = () => {
    setCurrentPage(1);
  };

  const toggleFilterOptions = (filterType) => {
    setOpenDropdown((prev) => (prev === filterType ? null : filterType));
  };

  const handleSortClick = (column) => {
    console.log("Handle sort click", column);
    console.log("statusSortIndex", statusSortIndex);

    if (spinningColumn !== column) {
      setSpinningColumn(column);

      if (column === "latest_status") {
        setStatusSortIndex((prevIndex) => (prevIndex + 1) % statusOrder.length);
        setSortColumn(column);
        setSortDirection(statusOrder[statusSortIndex]);
      } else {
        if (sortColumn === column) {
          setSortDirection((prevDirection) =>
            prevDirection === "asc" ? "desc" : "asc"
          );
        } else {
          setSortColumn(column);
          setSortDirection("asc");
        }
      }
      setCurrentPage(1);

      setTimeout(() => {
        setSpinningColumn(null);
      }, 200);
    }
  };

  const abortControllerRef = useRef(new AbortController());

  const fetchWorkflowInstancesCallback = useCallback(
    async (
      column = sortColumn,
      direction = sortDirection,
      page = currentPage
    ) => {
      if (!user?.email) return;

      if (abortControllerRef.current) {
        console.log("Aborting previous request");
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      setIsLoading(true);
      setError(null);

      const memoryTracker = trackOperation("fetch-main-content");

      try {
        const data = await fetchWorkflowInstances({
          column: column,
          direction: direction,
          filters: filters,
          showPreviousVersions: filters.showPreviousVersions,
          selectedCustomer: selectedCustomerForImpersonation?.name,
          page: page,
          abortController: abortControllerRef.current,
          impersonated_customer_id: impersonatedCustomer?.id,
        });

        if (data) {
          const newData = data.data || [];
          setWorkflows(newData);
          setTotalWorkflows(data.total || 0);
          if (typeof data.total_runs === "number") {
            setTotalRuns(data.total_runs);
          }
          setErrorCount(data.latest_failed_count || 0);
          setSuccessCount(data.latest_success_count || 0);
          setWarningCount(data.latest_warning_count || 0);

          const totalActiveWorkflows = data.total || 1;
          const itemsPerPage = 20;
          const calculatedTotalPages = Math.ceil(
            totalActiveWorkflows / itemsPerPage
          );
          setTotalPages(calculatedTotalPages);
          setCustomers(data.customers || []);
          setIsLoading(false);
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error("Error fetching workflow instances:", err);
          setError("Failed to load workflow data. Please try again later.");
          setIsLoading(false);
        }
      } finally {
        memoryTracker.end();
      }
    },
    [
      sortColumn,
      sortDirection,
      filters,
      currentPage,
      isImpersonating,
      switchEnvironment,
    ]
  );

  useEffect(() => {
    let isSubscribed = true;

    const intervalId = setInterval(async () => {
      if (isSubscribed) {
        await fetchWorkflowInstancesCallback(
          sortColumn,
          sortDirection,
          currentPage
        );
      }
    }, 30000);

    if (isSubscribed) {
      fetchWorkflowInstancesCallback(sortColumn, sortDirection, currentPage);
    }

    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
      abortControllerRef.current.abort();
    };
  }, [fetchWorkflowInstancesCallback]);

  const handleChange = (value) => {
    setSelectedDateRange(value);
  };

  const handleDateFilter = (option) => {
    if (option === "Custom") {
      setSelectedDateRange(selectedDateRange);
      setShowCalendar(true);
      setOpenDropdown(null);
    } else {
      const now = new Date();
      let start = new Date();

      if (option === "Last 7 Days") {
        start.setDate(now.getDate() - 7);
      } else if (option === "Last 30 Days") {
        start.setDate(now.getDate() - 30);
      }

      // Set consistent time parts in local timezone
      start.setHours(0, 0, 0, 0);
      now.setHours(23, 59, 59, 999);

      setFilters((prev) => ({
        ...prev,
        lastRun: {
          start: start,
          end: now,
          display: option, // Keep the display text
        },
      }));
      setOpenDropdown(null);
      resetToFirstPage();
    }
  };

  const removeFilter = (index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  const handleSearch = (searchTerm) => {
    console.log("Search initiated with term:", searchTerm);

    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // Set loading state immediately for visual feedback
    setIsLoading(true);

    // Debounce the actual search
    searchTimeoutRef.current = setTimeout(() => {
      if (searchTerm || searchTerm === "") {
        setFilters((prev) => ({ ...prev, name: searchTerm || null }));
        setOpenDropdown(null);
        resetToFirstPage();

        fetchWorkflowInstancesCallback(
          sortColumn,
          sortDirection,
          1,
          abortControllerRef.current
        );
      }
    }, 500); // Wait 500ms after last keystroke before searching
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  const handleNameKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const searchTerm = e.target.value.trim();
      handleSearch(searchTerm);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && submitButtonRef.current) {
        submitButtonRef.current.click();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  const goToPage = (page) => {
    setCurrentPage((prevPage) => {
      if (page === "next") return prevPage + 1;
      if (page === "previous") return Math.max(prevPage - 1, 1);
      if (page === "first") return 1;
      if (page === "last") return totalPages;
      return prevPage;
    });
  };

  useEffect(() => {
    setPageInput(currentPage);
  }, [currentPage]);

  const handleRowClick = (item) => {
    navigate(
      `${
        env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""
      }/workflow/${item.first_workflow_instance_id}`
    );
  };

  const toggleStatusFilter = (status) => {
    setFilters((prev) => ({
      ...prev,
      status: prev.status === status ? null : status,
      customer: prev.customer,
    }));
    setOpenDropdown(null);
    resetToFirstPage();
  };

  const handleStatusFilter = (status) => {
    setFilters((prev) => ({ ...prev, status }));
    setOpenDropdown(null);
    resetToFirstPage();
  };

  const handleCheckboxChange = () => {
    setFilters((prev) => ({
      ...prev,
      showPreviousVersions: !prev.showPreviousVersions,
    }));
    setCurrentPage(1);
  };

  const handlePageInputChange = (e) => {
    setPageInput(e.target.value);
  };

  const handlePageInputSubmit = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    } else {
      setPageInput(currentPage);
    }
  };

  useEffect(() => {
    const fetchTotalRunsData = async () => {
      try {
        setTotalRuns(undefined); // Set to undefined before fetching to show loading state
        const fetchedTotalRuns = await fetchTotalRuns();
        setTotalRuns(fetchedTotalRuns);
      } catch (error) {
        console.error("Error fetching total runs:", error);
      }
    };

    fetchTotalRunsData();
  }, [userIsQurrent, isImpersonating, switchEnvironment]);

  useEffect(() => {
    const getCustomers = async () => {
      if (user && userIsQurrent) {
        try {
          const response = await fetchCustomers();
          if (response?.data?.customers) {
            setCustomers(response.data.customers);
          }
        } catch (error) {
          console.error("Error fetching customers:", error);
        }
      }
    };
    getCustomers();
  }, [workflows]);

  return (
    <div className="App-main">
      <Header />
      {showTooltip && (
        <div className="tooltip-container">
          <button
            className="close-tooltip-button-main"
            onClick={() => closeTooltipForever(pageName)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
      <Cards
        totalWorkflows={totalWorkflows}
        totalRuns={totalRuns}
        errorCount={errorCount}
        warningCount={warningCount}
        successCount={successCount}
        toggleStatusFilter={(status) => {
          toggleStatusFilter(status);
          resetToFirstPage();
        }}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        currentDate={new Date().toLocaleDateString()}
        error={error}
        fetchWorkflowInstances={fetchWorkflowInstancesCallback}
        showTooltip={showTooltip}
      />
      <ErrorNotification
        error={error}
        fetchWorkflowInstances={fetchWorkflowInstancesCallback}
      />
      <FilterRow
        filters={filters}
        toggleFilterOptions={toggleFilterOptions}
        handleStatusFilter={handleStatusFilter}
        setFilters={setFilters}
        openDropdown={openDropdown}
        handleNameKeyPress={handleNameKeyPress}
        handleSearch={handleSearch}
        handleDateFilter={handleDateFilter}
        customers={filters.customers}
        selectedCustomer={filters.customer}
        setSelectedCustomer={(customer) =>
          setFilters((prev) => ({ ...prev, customer }))
        }
        removeFilter={removeFilter}
        showCalendar={showCalendar}
        calendarRef={calendarRef}
        handleChange={handleChange}
        selectedDateRange={selectedDateRange}
        submitButtonRef={submitButtonRef}
        customerViewActive={customerViewActive}
        setShowCalendar={setShowCalendar}
      />
      <MainTable
        workflows={workflows}
        handleSortClick={handleSortClick}
        handleRowClick={handleRowClick}
        showPreviousVersions={filters.showPreviousVersions}
        handleCheckboxChange={handleCheckboxChange}
        showTooltip={showTooltip}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageInput={pageInput}
        handlePageInputChange={handlePageInputChange}
        handlePageInputSubmit={handlePageInputSubmit}
        goToPage={goToPage}
        disableNext={currentPage === totalPages}
      />
      {stateIsLoading && (
        <div className="loading-spinner-corner">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
}

export default MainContent;
