// BreadcrumbsContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const BreadcrumbsContext = createContext();

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(() => {
    const storedBreadcrumbs = sessionStorage.getItem("breadcrumbs");
    return storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
  });

  useEffect(() => {
    sessionStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
  }, [breadcrumbs]);

  const updateBreadcrumbs = (clickedCrumb) => {
    // Find the index of the clicked breadcrumb
    const index = breadcrumbs.findIndex(
      (crumb) => crumb.workflow_id === clickedCrumb.workflow_id
    );

    // Ensure the index is valid
    if (index !== -1) {
      // Keep breadcrumbs up to and including the clicked one,
      // and ensure we keep all properties of the original breadcrumbs
      const newBreadcrumbs = breadcrumbs.slice(0, index + 1).map((crumb) => ({
        ...crumb,
        name: crumb.name || clickedCrumb.name, // Preserve the name
      }));
      setBreadcrumbs(newBreadcrumbs);
    } else {
      console.warn("Clicked breadcrumb not found in the current list.");
    }
  };

  return (
    <BreadcrumbsContext.Provider
      value={{ breadcrumbs, setBreadcrumbs, updateBreadcrumbs }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};
