import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faPlus,
  faThumbsUp,
  faThumbsDown,
  faTrash,
  faPencil,
  faChevronDown,
  faCommentSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatId,
  formatDateTime,
  formatOutput,
} from "../utils/formatUtils.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useFeedback } from "../contexts/FeedbackContext.js";
import { useApiUtils } from "../utils/apiUtils.js";
import { useUser } from "../contexts/UserContext.js";
import { useFeedbackFilter } from "../contexts/FeedbackFilterContext.js";
import { createPortal } from "react-dom";
import { useObservableView } from "../contexts/ObservableViewContext.js";

const EmptyDrawerMessagePopup = ({
  currentWorkflowName,
  workflowDescription,
  onClose,
  outputs,
  workflowInstanceId,
  workflowInstanceCreatedAt,
  workflowInstanceEndedAt,
  copied,
  handleIdClick,
  isFromFeedbackPage = false,
  showNoFeedbackMessage = true,
  onStatusRemoved,
  showArchived = false,
  onFeedbackUpdate,
}) => {
  const { user } = useAuth0();
  const apiUtils = useApiUtils();
  const { fetchFeedbackForWorkflow, fetchAllFeedback, instanceFeedback } =
    useFeedback();
  const { filters } = useFeedbackFilter();
  const { userIsQurrent } = useUser();
  const [activeTab, setActiveTab] = useState(
    isFromFeedbackPage ? "feedback" : "details"
  );
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [selectedThumb, setSelectedThumb] = useState(null);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);
  const [editingFeedbackId, setEditingFeedbackId] = useState(null);
  const [deletingFeedbackId, setDeletingFeedbackId] = useState(null);
  const [status, setStatus] = useState(undefined);
  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { selectedCustomerForImpersonation, impersonatedCustomer } = useUser();
  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { isObservableViewActive } = useObservableView();

  const shouldShowNoFeedback =
    isFromFeedbackPage &&
    (showNoFeedbackMessage ||
      (!status && feedbackList.length === 0) ||
      (status === "Archived" && !showArchived));

  useEffect(() => {
    // Update status and flag when feedback changes
    if (instanceFeedback) {
      setStatus(instanceFeedback[0]?.review_status);
    }
  }, [instanceFeedback]);

  // Update the useEffect that handles workflowInstanceId changes
  useEffect(() => {
    const storedFeedback = JSON.parse(
      localStorage.getItem("workflowFeedback") || "[]"
    );
    const flaggedItem = storedFeedback.find(
      (f) => f.workflowInstanceId === workflowInstanceId
    );

    // Reset states for new instance
    setStatus(undefined);
    setShowFeedbackForm(false);
    setEditingFeedbackId(null);
    setFeedbackText("");
    setSelectedThumb(null);

    // Set correct state based on status
    if (flaggedItem?.status === "Needs Review") {
      setStatus("Needs Review");
    } else if (flaggedItem?.status) {
      setStatus(flaggedItem.status);
    }

    // Set feedback list
    const instanceFeedback = storedFeedback.filter(
      (f) => f.workflowInstanceId === workflowInstanceId && f.text
    );
    setFeedbackList(instanceFeedback);
  }, [workflowInstanceId]);

  useEffect(() => {
    const storedFeedback = JSON.parse(
      localStorage.getItem("workflowFeedback") || "[]"
    );
    const existingIndex = storedFeedback.findIndex(
      (f) => f.workflowInstanceId === workflowInstanceId
    );

    if (existingIndex !== -1) {
      if (status === undefined) {
        storedFeedback.splice(existingIndex, 1);
      } else {
        storedFeedback[existingIndex].status = status;
      }
    } else if (status) {
      storedFeedback.unshift({
        workflowInstanceId,
        workflowName: currentWorkflowName,
        workflowDescription,
        startTime: workflowInstanceCreatedAt,
        endTime: workflowInstanceEndedAt,
        status: status,
      });
    }

    localStorage.setItem("workflowFeedback", JSON.stringify(storedFeedback));
  }, [status]);

  useEffect(() => {
    if (workflowInstanceId) {
      const customerId =
        selectedCustomerForImpersonation?.id || impersonatedCustomer?.id;
      fetchFeedbackForWorkflow(workflowInstanceId, customerId);
      setDeletingFeedbackId(null);
    }
  }, [
    workflowInstanceId,
    selectedCustomerForImpersonation,
    impersonatedCustomer,
  ]);

  useEffect(() => {
    if (instanceFeedback) {
      // Only include feedback items that have either a note or evaluation
      const formattedFeedback = instanceFeedback
        .filter(
          (feedback) => feedback.note !== null || feedback.evaluation !== null
        )
        .map((feedback) => ({
          id: feedback.id,
          text: feedback.note,
          type: feedback.evaluation === 0 ? "up" : "down",
          timestamp: formatDateTime(feedback.created_at),
          userName: feedback.user_email,
          workflowInstanceId: feedback.workflow_instance_id,
          status: feedback.review_status,
          isEdited: feedback.created_at !== feedback.last_modified_at,
          customerId: feedback.customer_id,
        }))
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      setFeedbackList(formattedFeedback);

      // Set the status based on any feedback item's review_status, even if it has no note/evaluation
      if (instanceFeedback.length > 0) {
        setStatus(instanceFeedback[0].review_status);
      }
    }
  }, [instanceFeedback]);

  // Add useEffect to close dropdown when workflowInstanceId changes
  useEffect(() => {
    setOpenStatusDropdown(false);
  }, [workflowInstanceId]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".status-dropdown-container")) {
        setOpenStatusDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add useEffect for escape key handling
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" && selectedImage) {
        setSelectedImage(null);
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [selectedImage]); // Only re-run if selectedImage changes

  const handleEdit = (index) => {
    const feedbackToEdit = feedbackList[index];
    // Only allow editing if the feedback belongs to the current user
    if (feedbackToEdit.userName !== user.email) {
      return;
    }
    setFeedbackText(feedbackToEdit.text);
    setSelectedThumb(feedbackToEdit.type);
    setEditingFeedbackId(index);
    setShowFeedbackForm(true);
  };

  const handleDelete = (index) => {
    const feedbackToDelete = feedbackList[index];
    // Only allow deletion if the feedback belongs to the current user
    if (feedbackToDelete.userName !== user.email) {
      return;
    }
    setDeletingFeedbackId(index);
  };

  const confirmDelete = async () => {
    const feedbackToDelete = feedbackList[deletingFeedbackId];

    // Optimistically remove the feedback from the list
    setFeedbackList((prevList) =>
      prevList.filter((_, index) => index !== deletingFeedbackId)
    );

    // If this is the last feedback being deleted, set status to "Needs Review"
    if (feedbackList.length === 1) {
      setStatus("Needs Review");

      try {
        await apiUtils.updateWorkflowFeedback(feedbackToDelete.id, {
          email: user.email,
          review_status: "Needs Review",
        });
      } catch (error) {
        console.error("Error updating status:", error);
        // Revert optimistic update on error
        onFeedbackUpdate({
          workflowInstanceId: workflowInstanceId,
          revert: true,
        });
      }
    }

    setDeletingFeedbackId(null);

    try {
      const deleteType =
        feedbackToDelete.text || feedbackToDelete.type ? "feedback" : null;
      if (deleteType) {
        await apiUtils.deleteWorkflowFeedback(
          feedbackToDelete.id,
          deleteType,
          user.email
        );
      }

      // Refresh both instance feedback and all feedback
      await fetchFeedbackForWorkflow(workflowInstanceId);
      await fetchAllFeedback(filters);
    } catch (error) {
      // Revert optimistic update on error
      setFeedbackList((prevList) => {
        const newList = [...prevList];
        newList.splice(deletingFeedbackId, 0, feedbackToDelete);
        return newList;
      });
      console.error("Error deleting feedback:", error);
      setSubmitError("Failed to delete feedback. Please try again later.");
    }
  };

  const handleSubmit = async () => {
    if (!selectedThumb || !feedbackText.trim() || feedbackText.length > 2000)
      return;

    setIsSubmitting(true);
    setSubmitError(null);

    const newStatus =
      status === undefined && editingFeedbackId === null ? "Reviewed" : status;

    // Create optimistic feedback entry with a temporary ID
    const optimisticFeedback = {
      id: `temp-${Date.now()}`,
      text: feedbackText,
      type: selectedThumb,
      timestamp: formatDateTime(new Date()),
      userName: user.email,
      workflowInstanceId,
      status: newStatus,
      isEdited: false,
      customerId:
        selectedCustomerForImpersonation?.id || impersonatedCustomer?.id,
    };

    // Immediately update UI with new feedback
    setFeedbackList((prevList) => [optimisticFeedback, ...prevList]);
    setShowFeedbackForm(false);
    setSelectedThumb(null);
    setFeedbackText("");
    setEditingFeedbackId(null);

    try {
      if (editingFeedbackId !== null) {
        const feedbackToEdit = feedbackList[editingFeedbackId];
        if (!feedbackToEdit.id) {
          throw new Error("No feedback ID found for editing");
        }

        // Only include fields that have changed
        const feedbackData = {};

        if (feedbackText !== feedbackToEdit.text) {
          feedbackData.text = feedbackText;
        }
        if (selectedThumb !== feedbackToEdit.type) {
          feedbackData.evaluation = selectedThumb === "up" ? 0 : 1;
        }
        if (newStatus) {
          feedbackData.status = newStatus;
        }
        if (user.email) {
          feedbackData.email = user.email;
        }

        // Only make API call if there are changes
        if (Object.keys(feedbackData).length > 0) {
          await apiUtils.updateWorkflowFeedback(
            feedbackToEdit.id,
            feedbackData
          );
        }
      } else {
        const customerId =
          selectedCustomerForImpersonation?.id || impersonatedCustomer?.id;

        // Only include fields that have values
        const feedbackData = {};

        if (feedbackText.trim()) {
          feedbackData.text = feedbackText;
        }
        if (selectedThumb) {
          feedbackData.evaluation = selectedThumb === "up" ? 0 : 1;
        }
        if (newStatus) {
          feedbackData.status = newStatus;
        }
        if (workflowInstanceId) {
          feedbackData.workflowInstanceId = workflowInstanceId;
        }
        if (user.email) {
          feedbackData.email = user.email;
        }
        if (customerId) {
          feedbackData.customerId = customerId;
        }
        if (user.name || user.email) {
          feedbackData.userName = user.name || user.email;
        }

        await apiUtils.createWorkflowFeedback(workflowInstanceId, feedbackData);
      }

      // Refresh feedback lists after successful API calls
      await fetchFeedbackForWorkflow(workflowInstanceId);
      await fetchAllFeedback(filters);

      // After successful API call, update parent with confirmed changes
      if (onFeedbackUpdate) {
        onFeedbackUpdate({
          workflowInstanceId,
          thumbsUpCount: selectedThumb === "up" ? 1 : 0,
          thumbsDownCount: selectedThumb === "down" ? 1 : 0,
          status: newStatus,
          isOptimistic: false,
        });
      }
    } catch (error) {
      // On error, remove the optimistic feedback
      setFeedbackList((prevList) =>
        prevList.filter((f) => f.id !== optimisticFeedback.id)
      );
      console.error("Error submitting feedback:", error);
      setSubmitError("Failed to submit feedback. Please try again later.");
      setShowFeedbackForm(true);
      setSelectedThumb(selectedThumb);
      setFeedbackText(feedbackText);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusChange = async (newStatus) => {
    setOpenStatusDropdown(false);

    if (newStatus === "Needs Review" && feedbackList.length === 0) {
      setStatus(undefined);

      try {
        const response = await apiUtils.fetchWorkflowFeedback(
          workflowInstanceId
        );
        const feedbackId = response.feedback?.[0]?.id;

        if (feedbackId) {
          await apiUtils.deleteWorkflowFeedback(
            feedbackId,
            "status",
            user.email
          );
          if (onStatusRemoved) {
            onStatusRemoved(workflowInstanceId);
          }

          if (onFeedbackUpdate) {
            onFeedbackUpdate({
              workflowInstanceId,
              status: undefined,
              isOptimistic: false,
            });
          }
        }
      } catch (error) {
        // Revert optimistic update on error
        console.error("Error in status removal:", error);
        setStatus("Needs Review");
        if (onFeedbackUpdate) {
          onFeedbackUpdate({
            workflowInstanceId,
            status: "Needs Review",
            isOptimistic: false,
          });
        }
      }
      return;
    }

    const previousStatus = status;
    setStatus(newStatus);

    try {
      const response = await apiUtils.fetchWorkflowFeedback(workflowInstanceId);

      const feedbackId = response.feedback?.[0]?.id;

      if (feedbackId) {
        await apiUtils.updateWorkflowFeedback(feedbackId, {
          email: user.email,
          review_status: newStatus,
        });
      } else {
        await apiUtils.createWorkflowFeedback(workflowInstanceId, {
          email: user.email,
          status: newStatus,
        });
      }

      if (onFeedbackUpdate) {
        onFeedbackUpdate({
          workflowInstanceId,
          status: newStatus,
          isOptimistic: false,
        });
      }
    } catch (error) {
      console.error("Error in status update:", error);
      setStatus(previousStatus);
      if (onFeedbackUpdate) {
        onFeedbackUpdate({
          workflowInstanceId,
          revert: true,
        });
      }
    }
  };

  const handleStatusClick = () => {
    // When there's no status, create one with "Needs Review"
    if (!status) {
      // Update UI state immediately
      setStatus("Needs Review");

      apiUtils
        .createWorkflowFeedback(workflowInstanceId, {
          email: user.email,
          status: "Needs Review",
        })
        .then(() => {
          return fetchFeedbackForWorkflow(workflowInstanceId);
        })
        .then(() => {
          return fetchAllFeedback(filters);
        })
        .catch((error) => {
          // Revert UI state on error
          setStatus(undefined);
          console.error("Error setting initial status:", error);
        });
    } else if (feedbackList.length > 0) {
      setOpenStatusDropdown(!openStatusDropdown);
    } else {
      handleStatusChange("Needs Review");
    }
  };

  const renderOutput = (outputObj, index, totalOutputs) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const renderFormattedOutput = (text) => {
      const formatted = formatOutput(text);
      return (
        <>
          <span style={{ whiteSpace: "pre-wrap" }}>
            {formatted.beforeText.split(urlRegex).map((part, i) => {
              if (part.match(urlRegex)) {
                return (
                  <a
                    key={i}
                    href={part}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {part}
                  </a>
                );
              }
              return part;
            })}
          </span>
          {formatted.image && (
            <>
              <br />
              <img
                src={formatted.image}
                alt="Output"
                style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }}
                onClick={() => setSelectedImage(formatted.image)}
                className="summary-thumbnail"
              />
            </>
          )}
          {formatted.afterText && renderFormattedOutput(formatted.afterText)}
        </>
      );
    };

    return (
      <React.Fragment key={index}>
        <div className="output-row">
          {outputObj.key !== "N/A" && outputObj.key && (
            <p className="key-formatted" style={{ textAlign: "left" }}>
              {outputObj.key}
            </p>
          )}
          <p>
            {outputObj.output
              ? renderFormattedOutput(outputObj.output)
              : "No output data"}
          </p>
        </div>
        {index < totalOutputs - 1 && <hr className="divider-popup-output" />}
      </React.Fragment>
    );
  };

  const renderOutputs = (outputs) => {
    if (Array.isArray(outputs) && outputs.length > 0) {
      const regularOutputs = outputs.filter((output) => !output.isIdentifier);
      const searchableOutputs = outputs.filter((output) => output.isIdentifier);

      return (
        <>
          {searchableOutputs.length > 0 && (
            <>
              <strong className="description-content-regular">
                SEARCHABLE IDs
              </strong>
              <div style={{ marginBottom: "8px" }}></div>
              {searchableOutputs.map((outputObj, index) =>
                renderOutput(outputObj, index, searchableOutputs.length)
              )}
              {regularOutputs.length > 0 && <hr className="divider-popup" />}
            </>
          )}

          {regularOutputs.length > 0 && (
            <>
              <strong className="description-content-regular">
                {regularOutputs.length === 1 ? "OUTPUT" : "OUTPUTS"}
              </strong>
              <div style={{ marginBottom: "8px" }}></div>
              {regularOutputs.map((outputObj, index) =>
                renderOutput(outputObj, index, regularOutputs.length)
              )}
            </>
          )}
        </>
      );
    }
    return <p className="no-output-available">No output available</p>;
  };

  const renderDetailsContent = () => <div>{renderOutputs(outputs)}</div>;

  const renderFeedbackContent = () => (
    <div className="feedback-content">
      <div className="feedback-header">
        <button
          className="add-feedback-button"
          onClick={() => {
            setShowFeedbackForm(!showFeedbackForm);
            setEditingFeedbackId(null);
            setFeedbackText("");
            setSelectedThumb(null);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span>Add new</span>
        </button>
      </div>

      {showFeedbackForm && editingFeedbackId === null && (
        <div className="feedback-form">
          <textarea
            className="feedback-textarea"
            placeholder="Enter your feedback here..."
            rows="4"
            value={feedbackText}
            onChange={(e) => {
              if (e.target.value.length <= 2000) {
                setFeedbackText(e.target.value);
              }
            }}
            maxLength={2000}
          />
          <div className="character-count">
            {feedbackText.length}/2000 characters
          </div>
          {submitError && (
            <div className="error-message-feedback">{submitError}</div>
          )}
          <div className="feedback-actions">
            <div className="feedback-thumbs">
              <FontAwesomeIcon
                icon={faThumbsDown}
                className={`thumb-icon ${
                  selectedThumb === "down" ? "selected-down" : ""
                }`}
                onClick={() => setSelectedThumb("down")}
              />
              <FontAwesomeIcon
                icon={faThumbsUp}
                className={`thumb-icon ${
                  selectedThumb === "up" ? "selected-up" : ""
                }`}
                onClick={() => setSelectedThumb("up")}
              />
            </div>

            <div>
              <button
                className="feedback-submit"
                disabled={
                  !selectedThumb || !feedbackText.trim() || isSubmitting
                }
                onClick={handleSubmit}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
              <button
                className="feedback-cancel"
                onClick={() => {
                  setShowFeedbackForm(false);
                  setSelectedThumb(null);
                  setFeedbackText("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="feedback-list">
        {feedbackList.map((feedback, index) => (
          <div key={index}>
            {editingFeedbackId === index ? (
              <div className="feedback-form">
                <textarea
                  className="feedback-textarea"
                  placeholder="Enter your feedback here..."
                  rows="4"
                  value={feedbackText}
                  onChange={(e) => {
                    if (e.target.value.length <= 2000) {
                      setFeedbackText(e.target.value);
                    }
                  }}
                  maxLength={2000}
                />
                <div className="character-count">
                  {feedbackText.length}/2000 characters
                </div>
                <div className="feedback-actions">
                  <div className="feedback-thumbs">
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      className={`thumb-icon ${
                        selectedThumb === "down" ? "selected-down" : ""
                      }`}
                      onClick={() => setSelectedThumb("down")}
                    />
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className={`thumb-icon ${
                        selectedThumb === "up" ? "selected-up" : ""
                      }`}
                      onClick={() => setSelectedThumb("up")}
                    />
                  </div>
                  <div>
                    <button
                      className="feedback-submit"
                      disabled={!selectedThumb || !feedbackText.trim()}
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                    <button
                      className="feedback-cancel"
                      onClick={() => {
                        setShowFeedbackForm(false);
                        setSelectedThumb(null);
                        setFeedbackText("");
                        setEditingFeedbackId(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="feedback-item">
                <div className="feedback-item-header">
                  <div className="feedback-header-left">
                    <FontAwesomeIcon
                      icon={feedback.type === "up" ? faThumbsUp : faThumbsDown}
                      className={`thumb-icon-inner ${
                        feedback.type === "up" ? "selected-up" : "selected-down"
                      }`}
                    />
                  </div>
                  <div className="feedback-header-right">
                    <span className="feedback-timestamp">
                      {feedback.userName} at {feedback.timestamp}
                    </span>
                  </div>
                </div>
                <div className="feedback-text-container">
                  <p className="feedback-text">{feedback.text}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {feedback.isEdited && (
                    <span className="edited-text">edited</span>
                  )}
                  <div className="feedback-actions-bottom">
                    {feedback.userName === user.email && (
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="feedback-action-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(index);
                        }}
                      />
                    )}
                    {feedback.userName === user.email && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="feedback-action-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(index);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {deletingFeedbackId === index && (
              <div
                className="delete-confirmation-popup"
                style={{ margin: "8px 0" }}
              >
                <p>Are you sure you want to delete this feedback?</p>
                <div className="delete-confirmation-buttons">
                  <button onClick={confirmDelete}>Yes, delete</button>
                  <button onClick={() => setDeletingFeedbackId(null)}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  // Regular popup content
  if (shouldShowNoFeedback) {
    return (
      <div className="popup-content">
        <div className="popup-header-section">
          <div className="main-row">
            <div className="title-row">
              <div className="title-header">
                <FontAwesomeIcon
                  icon={faCommentSlash}
                  className="no-feedback-icon"
                />
                No Feedback Selected
              </div>
              <button className="close-button-right-drawer" onClick={onClose}>
                &times;
              </button>
            </div>
          </div>
          <div className="description-text-content">
            Add feedback by selecting a workflow instance from the table.
          </div>
          <div
            className="description-text-content"
            style={{ fontSize: "0.8em", opacity: 0.7 }}
          >
            <em>Note: Archived feedback is not visible by default.</em>
          </div>
          <hr className="divider-popup" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="popup-content">
        <div className="popup-header-section">
          <div className="main-row">
            <div className="title-row">
              <div className="title-header">
                {currentWorkflowName || "Workflow"}
              </div>
              <button className="close-button-right-drawer" onClick={onClose}>
                &times;
              </button>
            </div>
          </div>
          <div className="description-text-content">
            <div>{workflowDescription || "No description available"}</div>
          </div>
          <hr className="divider-popup" />
        </div>

        <div className="drawer-tabs">
          <button
            className={`drawer-tab ${activeTab === "details" ? "active" : ""}`}
            onClick={() => setActiveTab("details")}
          >
            Details
          </button>
          <button
            className={`drawer-tab ${activeTab === "feedback" ? "active" : ""}`}
            onClick={() => setActiveTab("feedback")}
          >
            Feedback
          </button>
          <div className="status-dropdown-container">
            <button
              className={`status-dropdown-button ${
                !status ? "no-feedback" : ""
              }`}
              data-status={status || "Needs Review"}
              onClick={handleStatusClick}
            >
              {status || "Needs Review"}
              {feedbackList.length > 0 && (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="small-chevron"
                />
              )}
            </button>
            {openStatusDropdown && feedbackList.length > 0 && (
              <div className="status-dropdown-options">
                {[
                  "Needs Review",
                  "Reviewed",
                  ...(userIsQurrent ? ["Addressed"] : []),
                  "Archived",
                ].map((option) => (
                  <div
                    key={option}
                    className="dropdown-option-feedback"
                    onClick={() => handleStatusChange(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="popup-scrollable-content">
          {activeTab === "details" ? (
            <>
              <div>
                {isObservableViewActive && (
                  <p className="key-value-pair">
                    <strong>ID:</strong>
                    <span
                      className="id-tooltip"
                      title={workflowInstanceId}
                      onClick={() => handleIdClick(workflowInstanceId)}
                    >
                      <FontAwesomeIcon className="copy-icon" icon={faClone} />
                      {formatId(workflowInstanceId)}
                      <span
                        onClick={() => handleIdClick(workflowInstanceId)}
                        className="handle-id-click"
                      >
                        {copied && (
                          <span className="copied-style">Copied!</span>
                        )}
                      </span>
                    </span>
                  </p>
                )}
                <p className="key-value-pair">
                  <strong>START:</strong>
                  <span>{workflowInstanceCreatedAt}</span>
                </p>
                <hr className="divider-popup" />
              </div>
              {renderDetailsContent()}
            </>
          ) : (
            renderFeedbackContent()
          )}
        </div>
      </div>
      {selectedImage &&
        createPortal(
          <div
            className="image-modal-overlay"
            onClick={() => setSelectedImage(null)}
          >
            <img
              src={selectedImage}
              alt="Full size"
              onClick={(e) => e.stopPropagation()}
              className="image-modal"
            />
          </div>,
          document.body
        )}
    </>
  );
};
export default EmptyDrawerMessagePopup;
