import React, { useCallback, useRef, useState, useEffect } from "react";
import WorkflowRow from "./WorkflowRow.js";
import WorkflowFilterRow from "./WorkflowFilterRow.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { getSortIcon } from "../utils/formatUtils.js";

function WorkflowTable({
  combinedRuns,
  handleSortClick,
  runStartDisplay,
  runEndDisplay,
  toggleDropdown,
  openStartDropdown,
  openEndDropdown,
  openStatusDropdown,
  handleFilterToggle,
  activeFilters,
  setRunStartFilter,
  setRunStartDisplay,
  setRunEndFilter,
  setRunEndDisplay,
  total,
  isLoading,
  isDrawerOpen,
  drawerRef,
  showCustomCalendar,
  dateRange,
  setShowCustomCalendar,
  setCustomDateType,
  statusDropdownRef,
  startDropdownRef,
  endDropdownRef,
  selectedRunId,
  setSelectedRunId,
  childWorkflows,
  handleWorkflowLinkClick,
  handleWorkflowClick,
  handleRunClick,
  toggleExpand,
  expandedRunIds,
  handlePageChange,
  currentPage,
  totalPages,
  loading,
  handleSeeMoreClickError,
  setFixedBottomHeight,
  searchDropdownRef,
  openSearchDropdown,
  handleSearch,
  selectedIdentifier,
  selectedIdentifierValue,
  identifiers,
  clearSearch,
  customDateType,
  handleVersionSelect,
  openVersionDropdown,
  selectedVersions,
  setSelectedVersions,
  allVersions,
}) {
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const observer = useRef();
  const tableRef = useRef();
  const [spinningColumn, setSpinningColumn] = useState(null);
  const [tempSelectedVersions, setTempSelectedVersions] = useState([]);
  const [focusedRowIndex, setFocusedRowIndex] = useState(-1);

  useEffect(() => {
    if (selectedVersions.length === 0) {
      setSelectedVersions(allVersions || ["None"]);
    }
  }, [selectedVersions, setSelectedVersions, allVersions]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();

        setFocusedRowIndex((prevIndex) => {
          let newIndex;
          if (e.key === "ArrowUp") {
            newIndex = prevIndex <= 0 ? combinedRuns.length - 1 : prevIndex - 1;
          } else {
            newIndex = prevIndex >= combinedRuns.length - 1 ? 0 : prevIndex + 1;
          }

          const workflow = combinedRuns[newIndex];
          if (workflow) {
            if (workflow.parent_workflow_instance_id) {
              handleWorkflowClick(workflow);
            } else {
              handleRunClick(new Event("click"), workflow);
            }
          }

          return newIndex;
        });
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [combinedRuns, handleWorkflowClick, handleRunClick]);

  const handleMouseDown = (e) => {
    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }
    e.preventDefault();
    const startY = e.clientY;
    const startHeight = tableRef.current.offsetHeight;
    const minHeight = window.innerHeight * 0.15;
    const maxHeight = window.innerHeight * 0.5;

    const onMouseMove = (e) => {
      if (tableRef.current) {
        const newHeight = Math.min(
          Math.max(startHeight - (e.clientY - startY), minHeight),
          maxHeight
        );
        window.requestAnimationFrame(() => {
          if (tableRef.current) {
            tableRef.current.style.height = `${newHeight}px`;
            setFixedBottomHeight(newHeight);
          }
        });
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove, { passive: true });
    document.addEventListener("mouseup", onMouseUp);
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading || currentPage >= totalPages) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && currentPage < totalPages) {
            observer.current.disconnect();
            handlePageChange(currentPage + 1);
          }
        },
        {
          root: null,
          rootMargin: "100px",
          threshold: 0.1,
        }
      );

      if (node) observer.current.observe(node);
    },
    [currentPage, handlePageChange]
  );

  // Wrap the click handlers to reset focused row index
  const handleRowClick = (event, workflow, index) => {
    setFocusedRowIndex(index);
    setSelectedRunId(workflow.id);
    if (workflow.parent_workflow_instance_id) {
      handleWorkflowClick(workflow);
    } else {
      handleRunClick(event, workflow);
    }
  };

  return (
    <div
      className={`fixed-bottom ${isDrawerOpen ? "open" : ""}`}
      ref={drawerRef}
    >
      <WorkflowFilterRow
        activeFilters={activeFilters}
        handleFilterToggle={handleFilterToggle}
        toggleDropdown={toggleDropdown}
        openStatusDropdown={openStatusDropdown}
        openStartDropdown={openStartDropdown}
        openEndDropdown={openEndDropdown}
        runStartDisplay={runStartDisplay}
        runEndDisplay={runEndDisplay}
        setRunStartFilter={setRunStartFilter}
        setRunStartDisplay={setRunStartDisplay}
        setRunEndFilter={setRunEndFilter}
        setRunEndDisplay={setRunEndDisplay}
        showCustomCalendar={showCustomCalendar}
        dateRange={dateRange}
        setShowCustomCalendar={setShowCustomCalendar}
        setCustomDateType={setCustomDateType}
        statusDropdownRef={statusDropdownRef}
        startDropdownRef={startDropdownRef}
        endDropdownRef={endDropdownRef}
        searchDropdownRef={searchDropdownRef}
        openSearchDropdown={openSearchDropdown}
        handleSearch={handleSearch}
        selectedIdentifier={selectedIdentifier}
        selectedIdentifierValue={selectedIdentifierValue}
        identifiers={identifiers}
        clearSearch={clearSearch}
        customDateType={customDateType}
        total={total}
        isLoading={isLoading}
        handleMouseDown={handleMouseDown}
        versions={allVersions}
      />
      <div className="table-container" ref={tableRef}>
        <div className="table-body-container">
          <table className="workflow-table">
            <thead>
              <tr>
                <th></th>
                <th
                  className="sortable"
                  onClick={() => {
                    const column = "start_time";
                    setSpinningColumn(column);
                    handleSortClick("start_time", "start_time");
                    setTimeout(() => setSpinningColumn(null), 200);
                  }}
                >
                  Run Start {getSortIcon("start_time", spinningColumn)}
                </th>
                <th
                  className="sortable"
                  onClick={() => {
                    const column = "end_time";
                    setSpinningColumn(column);
                    handleSortClick("end_time", "end_time");
                    setTimeout(() => setSpinningColumn(null), 200);
                  }}
                >
                  Run End {getSortIcon("end_time", spinningColumn)}
                </th>
                <th
                  className="sortable"
                  onClick={() => {
                    const column = "status";
                    setSpinningColumn(column);
                    handleSortClick("status", "status");
                    setTimeout(() => setSpinningColumn(null), 200);
                  }}
                >
                  Status {getSortIcon("status", spinningColumn)}
                </th>
                <th>Errors</th>
                <th className="table-header-cell">
                  <div className="App-filter-dropdown">
                    <div className="header-content">
                      <span>Version</span>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="small-chevron-versions"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown("version");
                          setTempSelectedVersions(selectedVersions);
                        }}
                      />
                      {openVersionDropdown && (
                        <div className="App-filter-options-workflows-versions">
                          <div className="dropdown-option-versions">
                            <input
                              type="checkbox"
                              checked={
                                tempSelectedVersions.length ===
                                allVersions.length
                              }
                              onChange={() => {
                                const newVersions =
                                  tempSelectedVersions.length ===
                                  allVersions.length
                                    ? []
                                    : [...allVersions];
                                setTempSelectedVersions(newVersions);
                              }}
                            />
                            <span>All</span>
                          </div>
                          {(allVersions || []).map((version, index) => (
                            <div
                              key={`version-${version}-${index}`}
                              className="dropdown-option-versions"
                              onClick={() => {
                                const newVersions = (
                                  tempSelectedVersions || []
                                ).includes(version)
                                  ? (tempSelectedVersions || []).filter(
                                      (v) => v !== version
                                    )
                                  : [...(tempSelectedVersions || []), version];
                                setTempSelectedVersions(newVersions);
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={(tempSelectedVersions || []).includes(
                                  version
                                )}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const newVersions = (
                                    tempSelectedVersions || []
                                  ).includes(version)
                                    ? (tempSelectedVersions || []).filter(
                                        (v) => v !== version
                                      )
                                    : [
                                        ...(tempSelectedVersions || []),
                                        version,
                                      ];
                                  setTempSelectedVersions(newVersions);
                                }}
                              />
                              <span>{version}</span>
                            </div>
                          ))}
                          <button
                            className="App-submit-versions"
                            onClick={() => {
                              setSelectedVersions(tempSelectedVersions);
                              handleVersionSelect(tempSelectedVersions);
                              toggleDropdown("version");
                            }}
                          >
                            Done
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </th>
                <th style={{ borderRadius: "0px 4px 0px 0px" }}></th>
              </tr>
            </thead>
            <tbody>
              {combinedRuns.map((workflow, index) => (
                <WorkflowRow
                  key={`${workflow.id}-${index}`}
                  workflow={workflow}
                  index={index}
                  isLast={index === combinedRuns.length - 5}
                  selectedRunId={selectedRunId}
                  childWorkflows={childWorkflows}
                  handleWorkflowClick={handleWorkflowClick}
                  handleRunClick={handleRowClick}
                  toggleExpand={toggleExpand}
                  expandedRunIds={expandedRunIds}
                  lastElementRef={
                    index === combinedRuns.length - 5 &&
                    currentPage < totalPages
                      ? lastElementRef
                      : null
                  }
                  handleSeeMoreClickError={handleSeeMoreClickError}
                  handleWorkflowLinkClick={handleWorkflowLinkClick}
                  isFocused={index === focusedRowIndex}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WorkflowTable;
