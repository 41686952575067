import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiUtils } from "./utils/apiUtils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";

export const ContactPopup = ({ isOpen, onClose }) => {
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "",
  });
  const fileInputRef = useRef(null);
  const { user } = useAuth0();
  const apiUtils = useApiUtils();
  const MAX_MESSAGE_LENGTH = 5000;
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const ALLOWED_TYPES = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
  ];

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    if (newMessage.length <= MAX_MESSAGE_LENGTH) {
      setMessage(newMessage);
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files || []);
    const validFiles = files.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        setNotification({
          show: true,
          message: `File ${file.name} is too large. Maximum size is 5MB.`,
          type: "error",
        });
        return false;
      }
      if (!ALLOWED_TYPES.includes(file.type)) {
        setNotification({
          show: true,
          message: `File ${file.name} is not supported. Please use JPG, PNG, GIF, or PDF.`,
          type: "error",
        });
        return false;
      }
      return true;
    });

    setAttachments((prev) => [...prev, ...validFiles]);
    e.target.value = ""; // Reset input
  };

  const removeAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedMessage = message.trim();
    if (!trimmedMessage) return;

    setIsSending(true);
    try {
      await apiUtils.sendContactEmail(
        trimmedMessage,
        user.email,
        user.name,
        attachments
      );
      setNotification({
        show: true,
        message: "Message sent successfully! We'll get back to you soon.",
        type: "success",
      });
      setMessage("");
      setAttachments([]);
      setTimeout(() => {
        onClose();
        setNotification({ show: false, message: "", type: "" });
      }, 2000);
    } catch (error) {
      setNotification({
        show: true,
        message: "Unable to send message. Please try again later.",
        type: "error",
      });
      console.error("Error sending message:", error);
    } finally {
      setIsSending(false);
    }
  };

  const handleClose = () => {
    setMessage("");
    setAttachments([]);
    onClose();
  };

  useEffect(() => {
    const existingHandler = window.onbeforeunload;
    window.onbeforeunload = null;

    return () => {
      window.onbeforeunload = existingHandler;
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div className="contact-popup-overlay" onClick={handleClose}>
      <div className="contact-popup" onClick={(e) => e.stopPropagation()}>
        {notification.show && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        <h3>Message Qurrent Support</h3>
        <div className="contact-info">
          <span>
            From: {user.name} ({user.email})
          </span>
        </div>
        <div className="contact-info">
          <span>
            To: <a href="mailto:support@qurrent.ai">support@qurrent.ai</a>
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <textarea
            className="contact-textarea"
            value={message}
            onChange={handleMessageChange}
            placeholder="Type your message here..."
            maxLength={MAX_MESSAGE_LENGTH}
            required
          />
          <div className="contact-attachment-container">
            <span className="character-count">
              {message.length}/{MAX_MESSAGE_LENGTH}
            </span>
            <FontAwesomeIcon
              icon={faPaperclip}
              className="attachment-icon"
              onClick={handleAttachmentClick}
            />
            <input
              type="file"
              ref={fileInputRef}
              className="attachment-input"
              onChange={handleFileChange}
              multiple
              accept=".jpg,.jpeg,.png,.gif,.pdf"
            />
          </div>
          {attachments.length > 0 && (
            <div className="attachment-list">
              {attachments.map((file, index) => (
                <div key={index} className="attachment-item">
                  <span>{file.name}</span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="remove-attachment"
                    onClick={() => removeAttachment(index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="contact-button-container">
            <button
              type="button"
              className="contact-button close"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="contact-button"
              disabled={isSending || !message.trim()}
            >
              {isSending ? "Sending..." : "Send Message"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
