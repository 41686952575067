import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faGripLines } from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";
import NodeKey from "./NodeKey.js";
import {
  formatName,
  formatDateRange,
  setDateTimes,
} from "../utils/formatUtils.js";

const statusDisplayMap = {
  failed: "Failed",
  completed: "Success",
  created: "No Status",
  warning: "Warning",
  waiting: "Waiting",
};

function WorkflowFilterRow({
  activeFilters,
  handleFilterToggle,
  toggleDropdown,
  openStatusDropdown,
  openStartDropdown,
  openEndDropdown,
  runStartDisplay,
  runEndDisplay,
  setRunStartFilter,
  setRunStartDisplay,
  setRunEndFilter,
  setRunEndDisplay,
  showCustomCalendar,
  dateRange,
  setShowCustomCalendar,
  setCustomDateType,
  statusDropdownRef,
  startDropdownRef,
  endDropdownRef,
  searchDropdownRef,
  openSearchDropdown,
  handleSearch,
  selectedIdentifier,
  selectedIdentifierValue,
  identifiers,
  clearSearch,
  customDateType,
  total,
  isLoading,
  handleMouseDown,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearchWrapper = (identifier, term) => {
    handleSearch(identifier, term);
  };

  return (
    <>
      {showCustomCalendar && (
        <div className="App-date-range-picker-workflows">
          <Calendar
            selectRange={true}
            onChange={(range) => {
              if (range && range.length === 2) {
                const [startDate, endDate] = setDateTimes(...range);
                const displayText = formatDateRange(startDate, endDate);

                if (customDateType === "run_start") {
                  setRunStartFilter([startDate, endDate]);
                  setRunStartDisplay(displayText);
                } else {
                  setRunEndFilter([startDate, endDate]);
                  setRunEndDisplay(displayText);
                }
                handleFilterToggle(customDateType, [startDate, endDate]);
                setShowCustomCalendar(false);
              }
            }}
            value={dateRange}
            className="custom-calendar"
          />
          <button
            className="App-submit-date-range-workflows"
            onClick={() => {
              handleFilterToggle(customDateType, dateRange);
              setShowCustomCalendar(false);
            }}
          >
            Close
          </button>
        </div>
      )}
      <div className="App-filter-row-workflows">
        <div className="App-filter-dropdown" ref={statusDropdownRef}>
          <button
            className={`App-filter-button ${
              activeFilters.status ? "active" : ""
            }`}
            onClick={() => toggleDropdown("status")}
          >
            {activeFilters.status
              ? statusDisplayMap[activeFilters.status]
              : "Status"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {activeFilters.status && (
            <button
              className="App-filter-clear"
              onClick={() => {
                handleFilterToggle("status", null);
              }}
            >
              ×
            </button>
          )}
          {openStatusDropdown && (
            <div className="App-filter-options-workflows-search">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "failed")}
              >
                Failed
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "warning")}
              >
                Warning
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "completed")}
              >
                Success
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "created")}
              >
                No Status
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "waiting")}
              >
                Waiting
              </div>
            </div>
          )}
        </div>
        <div
          className="App-filter-dropdown"
          ref={startDropdownRef}
          onClick={() => toggleDropdown("run_start")}
        >
          <button
            className={`App-filter-button ${runStartDisplay ? "active" : ""}`}
          >
            {runStartDisplay || "Run Start"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {runStartDisplay && (
            <button
              className="App-filter-clear"
              onClick={(e) => {
                e.stopPropagation();
                setRunStartFilter([null, null]);
                setRunStartDisplay(null);
                handleFilterToggle("run_start", null);
              }}
            >
              ×
            </button>
          )}
          {openStartDropdown && (
            <div className="App-filter-options-workflows-search">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("run_start", "Last 7 days")}
              >
                Last 7 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("run_start", "Last 30 days")}
              >
                Last 30 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => {
                  toggleDropdown(null);
                  setCustomDateType("run_start");
                  setShowCustomCalendar(true);
                }}
              >
                Custom date range
              </div>
            </div>
          )}
        </div>
        <div
          className="App-filter-dropdown"
          ref={endDropdownRef}
          onClick={() => toggleDropdown("run_end")}
        >
          <button
            className={`App-filter-button ${runEndDisplay ? "active" : ""}`}
          >
            {runEndDisplay || "Run End"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {runEndDisplay && (
            <button
              className="App-filter-clear"
              onClick={(e) => {
                e.stopPropagation();
                setRunEndFilter([null, null]);
                setRunEndDisplay(null);
                handleFilterToggle("run_end", null);
              }}
            >
              ×
            </button>
          )}
          {openEndDropdown && (
            <div className="App-filter-options-workflows-search">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("run_end", "Last 7 days")}
              >
                Last 7 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("run_end", "Last 30 days")}
              >
                Last 30 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => {
                  toggleDropdown(null);
                  setCustomDateType("run_end");
                  setShowCustomCalendar(true);
                }}
              >
                Custom date range
              </div>
            </div>
          )}
        </div>
        {identifiers.length > 0 && (
          <>
            <div
              className="App-filter-dropdown"
              ref={searchDropdownRef}
              onClick={() => {
                toggleDropdown("searchBy");
              }}
            >
              <button
                className={`App-filter-button-search ${
                  selectedIdentifier ? "active" : ""
                }`}
              >
                {selectedIdentifier
                  ? formatName(selectedIdentifier.identifier_name)
                  : "Search by"}{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="small-chevron"
                />
              </button>
              {openSearchDropdown && (
                <div className="App-filter-options-workflows-search">
                  {identifiers.map((identifier) => {
                    return (
                      <div
                        key={identifier.id}
                        className="dropdown-option"
                        onClick={() => handleSearchWrapper(identifier, null)}
                      >
                        {formatName(identifier.identifier_name)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {selectedIdentifier && (
              <div className="App-search-input-container">
                <input
                  type="text"
                  placeholder={
                    selectedIdentifierValue
                      ? formatName(selectedIdentifierValue.identifier_name)
                      : ""
                  }
                  value={searchTerm}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setSearchTerm(newValue);
                    if (selectedIdentifier) {
                      if (newValue === "") {
                        clearSearch();
                      } else {
                        handleSearchWrapper(selectedIdentifier, newValue);
                      }
                    }
                  }}
                  className="styled-search-input-workflow"
                />
                {searchTerm && (
                  <button
                    className="App-filter-clear-search"
                    onClick={() => {
                      setSearchTerm("");
                      clearSearch();
                    }}
                  >
                    ×
                  </button>
                )}
              </div>
            )}
          </>
        )}
        <div className="number-of-runs">
          <strong>{total}</strong> {total === 1 ? "run" : "runs"}
        </div>{" "}
        {isLoading && <div className="loading-spinner-workflow"></div>}
        <FontAwesomeIcon
          icon={faGripLines}
          className="resizer-icon"
          onMouseDown={handleMouseDown}
        />
        <div className="node-key-container">
          <NodeKey />
        </div>
      </div>
    </>
  );
}

export default WorkflowFilterRow;
