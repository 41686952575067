// src/components/Pagination.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faChevronLeft,
  faChevronRight,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

const Pagination = ({
  currentPage,
  totalPages,
  pageInput,
  handlePageInputChange,
  handlePageInputSubmit,
  goToPage,
  disableNext,
  isDrawerOpen,
}) => {
  return (
    <div className={`App-pagination ${isDrawerOpen ? "drawer-open" : ""}`}>
      <button
        onClick={() => goToPage("first")}
        disabled={currentPage === 1}
        className="pagination-arrow"
        id="first-page"
        name="first-page"
        aria-label="Go to first page"
      >
        <FontAwesomeIcon icon={faAnglesLeft} />
      </button>
      <button
        onClick={() => goToPage("previous")}
        disabled={currentPage === 1}
        className="pagination-arrow"
        id="previous-page"
        name="previous-page"
        aria-label="Go to previous page"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span>
        Page{" "}
        <input
          type="text"
          value={pageInput}
          onChange={handlePageInputChange}
          onKeyDown={(e) => e.key === "Enter" && handlePageInputSubmit()}
          className="page-input"
          onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
          onBlur={(e) => {
            handlePageInputSubmit();
            e.target.style.borderColor = "#ccc";
          }}
          id="page-number-input"
          name="page-number"
          aria-label="Page number input"
        />{" "}
        of {totalPages}
      </span>
      <button
        onClick={() => goToPage("next")}
        disabled={disableNext}
        className="pagination-arrow"
        id="next-page"
        name="next-page"
        aria-label="Go to next page"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <button
        onClick={() => goToPage("last")}
        disabled={currentPage === totalPages}
        className="pagination-arrow"
        id="last-page"
        name="last-page"
        aria-label="Go to last page"
      >
        <FontAwesomeIcon icon={faAnglesRight} />
      </button>
    </div>
  );
};

export default Pagination;
