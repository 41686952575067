import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faChartColumn,
  faChartLine,
  faTable,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { createRoot } from "react-dom/client";
import {
  formatMetricValue,
  capitalizeWords,
  getSortIcon,
  formatIntervalTooltip,
} from "../utils/formatUtils.js";
import { renderChart } from "./LineBarChart.js";
import {
  toggleOverlay,
  handleTooltipMouseEvents,
  createTooltipContainer,
} from "../utils/chartUtils.js";

export const renderTable = (
  metric,
  container,
  selectedDateRange,
  prepareDataForDownload,
  downloadData,
  zoomState = null
) => {
  if (!metric || !container) {
    console.error("Invalid metric or container.");
    return;
  }

  const [startDate, endDate] = selectedDateRange || [null, null];
  let showOverlay = false;

  // Use chartState.fullData if available (when converting from chart), otherwise use interval_data
  const mutableIntervalData = [
    ...(metric.chartState?.fullData || metric.interval_data || []),
  ];

  console.log("Initial data:", {
    mutableIntervalData: mutableIntervalData.length,
    startDate,
    endDate,
    zoomState,
  });

  let filteredData = mutableIntervalData;

  if (startDate && endDate) {
    filteredData = filteredData.filter((data) => {
      const intervalDate = new Date(data.interval_start);
      return intervalDate >= startDate && intervalDate <= endDate;
    });
  }

  if (
    zoomState &&
    zoomState.start !== undefined &&
    zoomState.end !== undefined
  ) {
    const startIndex = Math.floor(
      (zoomState.start * filteredData.length) / 100
    );
    const endIndex = Math.ceil((zoomState.end * filteredData.length) / 100);
    filteredData = filteredData.slice(startIndex, endIndex);
  }

  if (filteredData.length === 0) {
    console.log("No data in table");
    toggleOverlay(container, true, "No data within this date range");
    return;
  } else {
    toggleOverlay(container, false);
  }

  // Match table width to chart width
  const dataPoints = metric.is_os_metric
    ? metric.interval_data?.length || 0
    : metric.data?.length || 0;
  const minWidth = 400;
  const widthPerPoint = 20;
  const calculatedWidth = Math.max(minWidth, dataPoints * widthPerPoint);
  const finalWidth = Math.min(calculatedWidth, 1200);

  container.style.width = `${finalWidth}px`;

  const computedStyles = getComputedStyle(document.documentElement);

  let currentSortField = null;
  let currentSortDirection = "asc";

  container.innerHTML = "";

  // Update the container styling
  container.className = "table-container-metrics";
  container.style.height = "auto";
  container.style.maxHeight = "400px";
  container.style.overflowY = "auto";

  const titleElement = document.createElement("div");
  titleElement.className = "title-element";
  titleElement.style.display = "flex";
  titleElement.style.justifyContent = "space-between";
  titleElement.style.width = "100%";
  titleElement.style.alignItems = "center";

  const metricName = document.createElement("span");
  metricName.innerHTML = `<b>${
    capitalizeWords(metric.metric_name.charAt(0).toUpperCase()) +
    metric.metric_name.slice(1) +
    " (per " +
    metric.time_interval +
    ")"
  }</b>`;
  metricName.style.cssText = `
    color: var(--text-color);
    max-width: 280px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  `;

  metricName.onmouseover = function (e) {
    const tooltipDom = createTooltipContainer(computedStyles);
    tooltipDom.style.left = `${e.pageX + 15}px`;
    tooltipDom.style.top = `${e.pageY + 15}px`;
    tooltipDom.innerHTML = metricName.textContent;
    tooltipDom.id = "metric-name-tooltip";
    document.body.appendChild(tooltipDom);
  };

  metricName.onmouseout = function () {
    const tooltipDom = document.getElementById("metric-name-tooltip");
    if (tooltipDom) {
      tooltipDom.remove();
    }
  };

  titleElement.appendChild(metricName);

  const buttonContainer = document.createElement("div");
  buttonContainer.className = "button-container";

  // Add download button
  const downloadButton = createIconButton(
    faFileDownload,
    "Download Data",
    () => {
      const downloadableData = prepareDataForDownload(filteredData, metric);
      const formattedFilename = `${metric.workflow_name}_${
        metric.metric_name
      }_${new Date().toISOString().split("T")[0]}`.replace(/ /g, "_");
      downloadData(downloadableData, formattedFilename, metric);
    }
  );

  buttonContainer.appendChild(downloadButton);

  // Existing buttons (Download, Convert, Line Chart, Table, Info)
  const convertButton = document.createElement("button");
  convertButton.className = "echarts-toolbox-icon tooltip-trigger";
  convertButton.innerHTML = ""; // Clear innerHTML
  const chartIcon = <FontAwesomeIcon icon={faChartColumn} />;
  const chartRoot = createRoot(convertButton);
  chartRoot.render(chartIcon);
  convertButton.onclick = () => {
    // Store the zoom state
    const zoomState = metric.chartState?.zoomConfig;

    // Restore original chart type
    metric.metric_type = metric.original_metric_type || "bar";

    container.innerHTML = "";

    // Pass the stored zoom state to renderChart
    renderChart(
      metric,
      container,
      selectedDateRange,
      prepareDataForDownload,
      downloadData,
      zoomState
    );
  };
  convertButton.title = ""; // Remove default tooltip
  convertButton.setAttribute("data-tooltip", "Convert to Bar Chart"); // Custom tooltip text
  buttonContainer.appendChild(convertButton);

  // Create a line chart icon button
  const lineChartButton = document.createElement("button");
  lineChartButton.className = "echarts-toolbox-icon tooltip-trigger";
  lineChartButton.innerHTML = ""; // Clear innerHTML
  const lineChartIcon = <FontAwesomeIcon icon={faChartLine} />;
  const lineChartRoot = createRoot(lineChartButton);
  lineChartRoot.render(lineChartIcon);
  lineChartButton.onclick = () => {
    const zoomState = metric.chartState?.zoomConfig;

    metric.metric_type = "line_chart";
    metric.original_metric_type = "line_chart";

    container.innerHTML = "";

    renderChart(
      metric,
      container,
      selectedDateRange,
      prepareDataForDownload,
      downloadData,
      zoomState
    );
  };
  lineChartButton.title = ""; // Remove default tooltip
  lineChartButton.setAttribute("data-tooltip", "Convert to Line Chart");
  buttonContainer.appendChild(lineChartButton);

  // Create a table icon button
  const tableButton = document.createElement("button");
  tableButton.className = "echarts-toolbox-icon tooltip-trigger";
  tableButton.innerHTML = ""; // Clear innerHTML
  const tableIcon = <FontAwesomeIcon icon={faTable} />;
  const tableRoot = createRoot(tableButton);
  tableRoot.render(tableIcon);
  tableButton.onclick = () => {
    if (metric.metric_type !== "table") {
      const currentOption = metric.chartInstance?.getOption();
      const zoomState = currentOption?.dataZoom?.[0];

      metric.chartState = {
        type: metric.metric_type,
        originalOption: JSON.parse(JSON.stringify(currentOption)),
        fullData: metric.is_os_metric
          ? [...metric.interval_data]
          : [...metric.data],
        zoomConfig: zoomState,
      };

      metric.original_metric_type = metric.metric_type;
      metric.metric_type = "table";

      container.innerHTML = "";
      renderTable(
        metric,
        container,
        selectedDateRange,
        prepareDataForDownload,
        downloadData,
        zoomState
      );
    }
  };
  tableButton.title = ""; // Remove default tooltip
  tableButton.setAttribute("data-tooltip", "Switch to Table");
  buttonContainer.appendChild(tableButton);

  const infoButton = createIconButton(
    faInfoCircle,
    metric.metric_definition,
    () => {}
  );

  buttonContainer.appendChild(infoButton);
  titleElement.appendChild(buttonContainer);
  container.appendChild(titleElement);

  const tableElement = document.createElement("table");
  tableElement.style.border = "1px solid #ccc";
  tableElement.style.borderCollapse = "collapse";
  tableElement.style.width = "100%";
  tableElement.style.color = computedStyles.getPropertyValue("--text-color");

  const createHeaderWithSort = (header, th) => {
    th.style.padding = "8px";
    th.style.border = "1px solid #ccc";
    th.style.color = computedStyles.getPropertyValue("--text-color");
    th.style.cursor = "pointer";
    th.style.textAlign = "left"; // Align header text to the left

    const headerContainer = document.createElement("div");
    headerContainer.style.display = "flex";
    headerContainer.style.alignItems = "center";
    headerContainer.style.gap = "5px"; // Add small gap between text and icon

    const headerText = document.createElement("span");
    headerText.textContent =
      header === "interval_start" ? "Date Time" : capitalizeWords(header);
    headerContainer.appendChild(headerText);

    if (
      header === "measure" ||
      header === "dimension" ||
      header === "interval_start"
    ) {
      const sortIconContainer = document.createElement("span");
      const sortIconRoot = createRoot(sortIconContainer);
      sortIconRoot.render(getSortIcon(header, currentSortField));
      headerContainer.appendChild(sortIconContainer);

      th.onclick = () => {
        if (currentSortField === header) {
          currentSortDirection =
            currentSortDirection === "asc" ? "desc" : "asc";
        } else {
          currentSortField = header;
          currentSortDirection = "asc";
        }

        sortIconRoot.render(getSortIcon(header, currentSortField));

        const sortedData = [...filteredData].sort((a, b) => {
          const aValue =
            header === "measure"
              ? Number(a.measure)
              : new Date(a.interval_start).getTime();
          const bValue =
            header === "measure"
              ? Number(b.measure)
              : new Date(b.interval_start).getTime();

          const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
          return currentSortDirection === "asc" ? comparison : -comparison;
        });

        // Clear the table except for the header row
        while (tableElement.rows.length > 1) {
          tableElement.deleteRow(1);
        }

        // Rebuild the table with sorted data
        sortedData.forEach((data) => {
          const row = document.createElement("tr");
          const tdDimension = document.createElement("td");

          // Fix dimension display
          if (data.dimension) {
            tdDimension.textContent = capitalizeWords(data.dimension);
          } else {
            tdDimension.textContent = "N/A"; // Use N/A for null dimensions
          }

          // Add date as a separate line if available
          if (data.interval_start) {
            const tooltipDate = formatIntervalTooltip(
              data.interval_start,
              metric.time_interval
            );

            if (data.dimension) {
              tdDimension.innerHTML = `${capitalizeWords(
                data.dimension
              )}<br/>${tooltipDate}`;
              tdDimension.setAttribute("title", tooltipDate); // Add tooltip
            } else {
              tdDimension.textContent = tooltipDate;
              tdDimension.setAttribute("title", tooltipDate); // Add tooltip
            }
          }

          row.appendChild(tdDimension);

          const tdMeasure = document.createElement("td");
          tdMeasure.textContent = formatTableValue(
            data.measure,
            metric.metric_format
          );
          row.appendChild(tdMeasure);

          tableElement.appendChild(row);
        });
      };
    }

    th.appendChild(headerContainer);
  };

  if (metric.is_os_metric) {
    const headers = Object.keys(filteredData[0] || {});
    const headerRow = document.createElement("tr");
    headers.forEach((header) => {
      const th = document.createElement("th");
      createHeaderWithSort(header, th);
      headerRow.appendChild(th);
    });
    tableElement.appendChild(headerRow);

    console.log("filteredData", filteredData);

    if (filteredData.length === 0) {
      console.log("No data in table");
      toggleOverlay(container, true, "No data within this date range");
      return;
    } else {
      toggleOverlay(container, false);
    }

    filteredData.forEach((rowData) => {
      const row = document.createElement("tr");

      headers.forEach((header) => {
        const td = document.createElement("td");

        if (header === "measure") {
          td.textContent = formatTableValue(
            rowData[header],
            metric.metric_format
          );
        } else if (header === "interval_start") {
          td.textContent = formatIntervalTooltip(
            rowData.interval_start,
            metric.time_interval
          );
        } else {
          td.textContent = rowData[header];
        }

        row.appendChild(td);
      });

      tableElement.appendChild(row);
    });
  } else {
    const headerRow = document.createElement("tr");
    ["dimension", "measure"].forEach((header) => {
      const th = document.createElement("th");
      createHeaderWithSort(header, th);
      headerRow.appendChild(th);
    });
    tableElement.appendChild(headerRow);

    filteredData.forEach((data) => {
      const row = document.createElement("tr");
      const tdDimension = document.createElement("td");

      if (data.dimension) {
        tdDimension.textContent = capitalizeWords(data.dimension);
      } else {
        tdDimension.textContent = "N/A";
      }

      if (data.interval_start) {
        const formattedDate = formatIntervalTooltip(
          data.interval_start,
          metric.time_interval
        );
        const tooltipDate = formatIntervalTooltip(
          data.interval_start,
          metric.time_interval
        );

        if (data.dimension) {
          tdDimension.innerHTML = `${capitalizeWords(
            data.dimension
          )}<br/>${formattedDate}`;
          tdDimension.setAttribute("title", tooltipDate); // Add tooltip
        } else {
          tdDimension.textContent = formattedDate;
          tdDimension.setAttribute("title", tooltipDate); // Add tooltip
        }
      }

      row.appendChild(tdDimension);

      const tdMeasure = document.createElement("td");
      tdMeasure.textContent = formatTableValue(
        data.measure,
        metric.metric_format
      );
      row.appendChild(tdMeasure);

      tableElement.appendChild(row);
    });
  }

  container.appendChild(tableElement);

  const tooltipContainer = createTooltipContainer(computedStyles);
  container.appendChild(tooltipContainer);

  const buttons = [
    downloadButton,
    convertButton,
    lineChartButton,
    tableButton,
    infoButton,
  ];

  buttons.forEach((button) => {
    handleTooltipMouseEvents(button, tooltipContainer);
  });

  // Add this after the table is fully rendered
  if (showOverlay) {
    toggleOverlay(container, true);
  } else {
    toggleOverlay(container, false);
  }
};

const createIconButton = (icon, tooltip, onClick) => {
  const button = document.createElement("button");
  button.className = "echarts-toolbox-icon tooltip-trigger";
  button.innerHTML = "";
  const iconElement = <FontAwesomeIcon icon={icon} />;
  const iconRoot = createRoot(button);
  iconRoot.render(iconElement);
  button.setAttribute("data-tooltip", tooltip);
  if (onClick) {
    button.onclick = onClick;
  }
  return button;
};

// Create a custom formatter function that handles the '0.0' format
const formatTableValue = (value, format) => {
  if (value === null || value === undefined) return "N/A";

  // Handle specific formats
  if (format === "0.0") {
    return Number(value).toFixed(1);
  } else if (format === "0.00") {
    return Number(value).toFixed(2);
  } else if (format === "0") {
    return Math.round(value).toString();
  } else if (format === "percentage") {
    return `${(value * 100).toFixed(1)}%`;
  } else if (format === "currency") {
    return `$${Number(value).toFixed(2)}`;
  }

  // Use the existing formatMetricValue as fallback
  return formatMetricValue(value, format);
};
