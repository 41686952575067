import { useContext } from "react";
import { EnvironmentContext } from "../contexts/EnvironmentContext.js";
import { formatName, formatDateTime, mapStatus } from "./formatUtils.js";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext.js";
import { getDomainFromEmail } from "../utils/formatUtils.js";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
const getHeaders = async (token, domain) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Domain": domain,
});

const handleApiError = (error, navigate) => {
  if (axios.isCancel(error)) {
    console.log("Request canceled");
    return null;
  }

  if (
    error.message.includes("Network Error") ||
    error.message.includes("CORS")
  ) {
    navigate("/error", {
      state: {
        title: "Connection Error",
        message:
          "Unable to connect to the server. This might be due to a CORS policy restriction.",
        contactInfo:
          "Please ensure you're connecting to the correct server. If the problem persists, contact team@qurrent.ai",
      },
    });
    return null;
  }

  if (error.response?.status === 403) {
    navigate("/error", {
      state: {
        title: "Access Denied",
        message:
          error.response.data.detail || "Access to this route is restricted.",
        contactInfo:
          "If you believe this is an error, please contact team@qurrent.ai",
      },
    });
    return null;
  }

  throw error;
};

export const useApiUtils = () => {
  const { config, apiBaseUrl } = useContext(EnvironmentContext);
  const navigate = useNavigate();
  const {
    userIsQurrent,
    selectedCustomerForImpersonation,
    impersonatedCustomer,
  } = useUser();
  const { user, getAccessTokenSilently } = useAuth0();
  const domain = user ? getDomainFromEmail(user.email) : null;

  const fetchWorkflowInstances = async ({
    column,
    direction,
    filters,
    showPreviousVersions,
    page,
    abortController,
  }) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        domain: domain,
        sort_by: column,
        sort_order: direction,
        status: filters.status ? filters.status.toLowerCase() : undefined,
        search: filters.name || undefined,
        start_date: filters.lastRun ? filters.lastRun.start : undefined,
        end_date: filters.lastRun ? filters.lastRun.end : undefined,
        latest_versions_only: !showPreviousVersions,
        page: page,
        limit: 20,
        user_is_qurrent: userIsQurrent,
        ...(userIsQurrent && {
          customer: selectedCustomerForImpersonation?.name || undefined,
          impersonated_customer_id: impersonatedCustomer?.id,
        }),
      };

      console.log("params", params);

      const response = await axios.get(
        `${apiBaseUrl}/filter/workflows/grouped`,
        {
          headers,
          params,
          signal: abortController.signal,
        }
      );

      console.log("Response:", response.data);

      return response.data;
    } catch (error) {
      return handleApiError(error, navigate);
    }
  };

  const fetchFilteredInstances = async (
    instanceId,
    theseNewPage,
    theseNewFilters,
    theseNewSortColumn,
    theseNewSortDirection,
    identifierName,
    searchTerm,
    versions
  ) => {
    const controller = new AbortController();
    try {
      if (impersonatedCustomer && userIsQurrent) {
        const workflowInstanceInfo = await fetchWorkflowInstanceInfo(
          instanceId,
          domain
        );
        console.log("workflowInstanceInfo", workflowInstanceInfo);
        console.log("impersonatedCustomer", impersonatedCustomer);
        if (workflowInstanceInfo.customer_id !== impersonatedCustomer.id) {
          navigate("/not-authorized");
        }
      }

      const params = {
        workflow_instance_id: instanceId,
        limit: 25,
        status: theseNewFilters.status,
        sort_by: theseNewSortColumn,
        sort_order: theseNewSortDirection,
        run_start: theseNewFilters.run_start,
        run_end: theseNewFilters.run_end,
        domain: domain,
        page: theseNewPage,
        identifier_name: identifierName,
        search_term: searchTerm,
        versions: versions,
        user_is_qurrent: userIsQurrent,
        ...(userIsQurrent && {
          impersonated_customer_id: impersonatedCustomer
            ? impersonatedCustomer.id
            : undefined,
        }),
      };

      console.log("params", params);

      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const response = await axios.get(
        `${apiBaseUrl}/workflows/instances/filter`,
        {
          headers,
          params: params,
          signal: controller.signal,
        }
      );

      console.log("RAW data", response.data);

      if (response.status !== 200) {
        throw new Error("Failed to fetch filtered instances");
      }

      const data = response.data.filtered_instances || [];

      const processedInstances = data.map((instance) => {
        return {
          id: instance.id,
          start_time: formatDateTime(instance.start_time),
          end_time: formatDateTime(instance.end_time),
          status: mapStatus(instance.status),
          outputDetails: `Actions: ${instance.action_count}, LLM Calls: ${instance.llm_call_count}`,
          outputs: [
            ...(instance.identifiers
              ?.filter((id) => id.value !== null && id.name !== "")
              ?.map((id) => ({
                key: formatName(id.name),
                output: id.value,
                isIdentifier: true,
              })) || []),
            ...(instance.outputs || []).map((output) => ({
              ...output,
              key: formatName(output.key),
              isIdentifier: false,
            })),
          ],
          errors: instance.errors || [],
          identifierId: instance.identifier_id || "N/A",
          identifierName: instance.identifier_name || "N/A",
          identifierValue: instance.identifier_value || "N/A",
          workflowVersion: instance.workflow_version || "N/A",
        };
      });

      return { response, processedInstances };
    } catch (error) {
      if (axios.isCancel(error)) return null;
      console.error("Error fetching filtered instances:", error);
      if (error.response && error.response.status === 403) {
        navigate("/not-authorized");
      }
      if (error.response && error.response.status === 404) {
        navigate("/not-authorized", {
          state: {
            message: "The workflow you are looking for does not exist.",
          },
        });
      }
      return [];
    }
  };

  const fetchWorkflowStructure = async (workflow_instance_id) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        workflow_instance_id: workflow_instance_id,
        domain: domain,
      };

      const response = await axios.get(
        `${apiBaseUrl}/workflows/${workflow_instance_id}/structure`,
        {
          headers,
          params: params,
        }
      );

      console.log("Response for workflow structure:", response.data);

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      return response.data.map((node) => ({
        ...node,
        function_name: formatName(node.function_name),
      }));
    } catch (error) {
      console.warn("Error fetching workflow structure:", error);
      return null;
    }
  };

  const fetchChildWorkflows = async (
    workflowInstanceIdList,
    currentCustomerId,
    thisWorkflowInstanceId
  ) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        workflow_instance_id_list: workflowInstanceIdList,
        customer_id: currentCustomerId,
        domain: domain,
        this_workflow_instance_id: thisWorkflowInstanceId,
      };

      const response = await axios.get(`${apiBaseUrl}/workflows/children`, {
        headers,
        params: params,
      });

      const processedChildWorkflows = {};
      Object.entries(response.data).forEach(([parentId, children]) => {
        processedChildWorkflows[parentId] = children.map((child) => {
          const start_time = child.created_at
            ? new Date(child.created_at)
            : null;
          const end_time = child.end_time ? new Date(child.end_time) : null;

          return {
            id: child.id,
            name: child.name,
            display_name: child.display_name,
            workflow_id: child.workflow_id,
            start_time: start_time ? formatDateTime(start_time) : "N/A",
            end_time: end_time ? formatDateTime(end_time) : "N/A",
            status: mapStatus(child.status),
            outputDetails: `Actions: ${child.action_count}, LLM Calls: ${child.llm_call_count}`,
            error: child.error || "N/A",
            parent_workflow_instance_id: child.parent_workflow_instance_id,
            workflowVersion: child.workflow_version,
          };
        });
      });

      return processedChildWorkflows;
    } catch (error) {
      console.error("Error fetching child workflows:", error);
      return {};
    }
  };

  const fetchObservableSummary = async (
    observableId,
    onChunkReceived,
    abortController
  ) => {
    const token = await getAccessTokenSilently();
    const headers = await getHeaders(token, domain);

    const response = await fetch(
      `${apiBaseUrl}/observable_summary/${observableId}?domain=${domain}`,
      {
        headers,
        signal: abortController.signal,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunk = decoder.decode(value, { stream: true });
      onChunkReceived(chunk);
    }
  };

  const fetchLlmCallSummary = async (
    nodeId,
    onChunkReceived,
    abortController
  ) => {
    const token = await getAccessTokenSilently();
    const headers = await getHeaders(token, domain);

    const response = await fetch(
      `${apiBaseUrl}/llm_call_summary/${nodeId}?domain=${domain}`,
      {
        headers,
        signal: abortController.signal,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let buffer = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;

      if (value) {
        buffer += decoder.decode(value, { stream: true });

        const startIndex = buffer.indexOf("__IMAGE_DATA_START__");
        const endIndex = buffer.indexOf("__IMAGE_DATA_END__");

        if (startIndex !== -1 && endIndex !== -1) {
          const imageDataStr = buffer.substring(
            startIndex + "__IMAGE_DATA_START__".length,
            endIndex
          );

          try {
            const images = JSON.parse(imageDataStr);
            onChunkReceived({ type: "images", data: images });

            const beforeImages = buffer.substring(0, startIndex);
            const afterImages = buffer.substring(
              endIndex + "__IMAGE_DATA_END__".length
            );

            if (beforeImages) {
              onChunkReceived(beforeImages);
            }

            buffer = afterImages;
          } catch (error) {
            console.error("Error parsing image data:", error);
          }
        } else if (!buffer.includes("__IMAGE_DATA_START__")) {
          onChunkReceived(buffer);
          buffer = "";
        }
      }
    }

    if (buffer) {
      onChunkReceived(buffer);
    }
  };

  const fetchLatestWorkflowInstance = async (workflowInstanceId) => {
    const params = {
      workflow_instance_id: workflowInstanceId,
      domain: domain,
    };
    const token = await getAccessTokenSilently();
    const headers = await getHeaders(token, domain);

    console.log("Fetching latest workflow instance with params:", params);

    const response = await axios.get(`${apiBaseUrl}/workflow/latest_instance`, {
      headers,
      params: params,
    });

    if (!response.data) {
      throw new Error("No workflow instance found for the given workflow ID");
    }

    return response.data;
  };

  const fetchTotalRuns = async () => {
    try {
      const params = {
        domain: domain,
        user_is_qurrent: userIsQurrent,
        ...(userIsQurrent && {
          impersonated_customer_id: impersonatedCustomer
            ? impersonatedCustomer.id
            : undefined,
        }),
      };
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const response = await axios.get(`${apiBaseUrl}/total_runs`, {
        headers,
        params: params,
      });

      return response.data?.total_runs ?? 0;
    } catch (error) {
      console.error("Error fetching total runs:", error);
      return 0;
    }
  };

  const endSession = async (sessionId) => {
    console.log("sessionId HERE", sessionId);
    const token = await getAccessTokenSilently({
      audience: config.audience,
      prompt: "select_account login",
    });
    const headers = await getHeaders(token, domain);
    if (sessionId) {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/sessions/${sessionId}/end?domain=${domain}`,
          {},
          {
            headers,
          }
        );
        console.log("Session ended:", response.data);
        sessionStorage.removeItem("session_id");
        sessionStorage.clear();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.warn("Session not found:", sessionId);
        } else {
          console.error("Error ending session:", error);
        }
      }
    }
  };

  const fetchWorkflowInstanceInfo = async (workflowInstanceId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        workflow_instance_id: workflowInstanceId,
        domain: domain,
      };

      const response = await axios.get(`${apiBaseUrl}/workflow_instance_info`, {
        headers,
        params,
      });

      if (!response.data) {
        throw new Error("Workflow instance not found");
      }

      const workflowInfo = {
        workflow_id: response.data.workflow_id,
        display_name: response.data.display_name,
        description: response.data.description,
        status: response.data.status,
        created_at: response.data.created_at
          ? formatDateTime(response.data.created_at)
          : "N/A",
        end_time: response.data.end_time
          ? formatDateTime(response.data.end_time)
          : "N/A",
        customer_id: response.data.customer_id,
      };

      return workflowInfo;
    } catch (error) {
      return handleApiError(error, navigate);
    }
  };

  const fetchWorkflowsForLast7Days = async (
    onChunkReceived,
    abortController
  ) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = new URLSearchParams({
        domain: domain,
        user_is_qurrent: userIsQurrent,
        ...(userIsQurrent && {
          impersonated_customer_id: impersonatedCustomer
            ? impersonatedCustomer.id
            : "",
        }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      const response = await fetch(
        `${apiBaseUrl}/last_report?${params.toString()}`,
        {
          headers,
          signal: abortController.signal,
        }
      );

      console.log("Response for workflows for the last 7 days:", response.body);

      if (!response.ok) {
        throw new Error("Failed to fetch workflows for the last 7 days");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunk = decoder.decode(value, { stream: true });
        onChunkReceived(chunk);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("ABORT ERROR: ", error);
        console.log("Fetch request was aborted.");
      } else {
        console.error("Error fetching workflows for the last 7 days:", error);
        throw error;
      }
    }
  };

  const fetchIdentifiers = async (workflowId, workflowInstanceId) => {
    try {
      const params = {
        domain: domain,
        workflow_id: workflowId,
        workflow_instance_id: workflowInstanceId,
      };
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const response = await axios.get(`${apiBaseUrl}/get_identifiers`, {
        headers,
        params: params,
      });

      console.log("Response for identifiers:", response.data);

      const identifiers = response.data.identifiers;
      const formattedIdentifiers = identifiers.map((identifier) => {
        const id = identifier.id || null;
        const identifier_name = identifier.identifier_name || null;
        const workflowId = identifier.workflow_id || null;

        if (!id && !identifier_name && !workflowId) {
          console.warn("Identifier with missing fields:", identifier);
        }

        return { id, identifier_name, workflowId };
      });
      return formattedIdentifiers;
    } catch (error) {
      console.error("Error fetching identifiers:", error);
      throw error;
    }
  };

  const fetchMetricsData = async (
    workflowName,
    customerName,
    onMetricsUpdate = () => {}
  ) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        user_is_qurrent: userIsQurrent,
        domain: domain,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (workflowName) params.workflow_name = workflowName;
      if (customerName) params.customer_name = customerName;

      console.log("Fetching metrics with params:", params);

      const queryString = new URLSearchParams(params).toString();
      const response = await fetch(`${apiBaseUrl}/metrics?${queryString}`, {
        headers,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        throw new Error("Failed to fetch metrics");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let processedData = [];
      let workflow_names = [];
      let isLastChunk = false;

      const sortMetrics = (metrics) => {
        return [...metrics].sort((a, b) => {
          // Convert null/undefined positions to Infinity
          const posA =
            a.position !== null && a.position !== undefined
              ? a.position
              : Infinity;
          const posB =
            b.position !== null && b.position !== undefined
              ? b.position
              : Infinity;
          return posA - posB;
        });
      };

      let buffer = ""; // Add this buffer to handle partial chunks

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // Process any remaining data in buffer
          if (buffer.trim()) {
            try {
              const data = JSON.parse(buffer);
              processMetricData(
                data,
                processedData,
                workflow_names,
                onMetricsUpdate,
                sortMetrics
              );
            } catch (error) {
              console.warn("Error parsing final buffer:", error);
            }
          }
          // Log the final metrics data after streaming is complete
          console.log("Final metrics data:", {
            metrics_data: sortMetrics(processedData),
            workflow_names,
          });

          break;
        }

        const chunk = decoder.decode(value);
        buffer += chunk;

        // Split on newlines, keeping the last potentially incomplete chunk in the buffer
        const lines = buffer.split("\n");
        buffer = lines.pop() || ""; // Keep the last line in buffer if it's incomplete

        for (const line of lines) {
          if (!line.trim()) continue;

          try {
            const data = JSON.parse(line);
            processMetricData(
              data,
              processedData,
              workflow_names,
              onMetricsUpdate,
              sortMetrics
            );
          } catch (error) {
            console.warn("Error parsing metric line:", error, "\nLine:", line);
            // Accumulate the current line with the next chunk
            buffer = line + buffer;
          }
        }
      }

      return { metrics_data: sortMetrics(processedData), workflow_names };
    } catch (error) {
      console.error("Error in fetchMetricsData:", error);
      return handleApiError(error, navigate);
    }
  };

  // Helper function to process metric data
  const processMetricData = (
    data,
    processedData,
    workflow_names,
    onMetricsUpdate,
    sortMetrics
  ) => {
    switch (data.type) {
      case "workflow_names":
        workflow_names = data.data;
        onMetricsUpdate({
          metrics_data: processedData,
          workflow_names,
        });
        break;

      case "metric":
        const metric = data.data;
        if (metric.interval_data) {
          metric.interval_data = metric.interval_data.map((interval) => ({
            ...interval,
            interval_start: new Date(interval.interval_start).toLocaleString(),
          }));
        }
        processedData.push(metric);
        const sortedData = sortMetrics(processedData);
        onMetricsUpdate({
          metrics_data: sortedData,
          workflow_names,
          isComplete: false,
        });
        break;

      case "error":
        console.warn("Error processing metric:", data.error);
        // Optionally notify the user about the error
        break;

      case "complete":
        onMetricsUpdate({
          metrics_data: sortMetrics(processedData),
          workflow_names,
          isComplete: true,
        });
        break;

      default:
        console.warn("Unknown message type:", data.type);
        break;
    }
  };

  const sendContactEmail = async (
    message,
    userEmail,
    userName,
    attachments
  ) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const processedAttachments = await Promise.all(
        attachments.map(async (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            const cleanup = () => {
              reader.onload = null;
              reader.onerror = null;
            };

            reader.onload = () => {
              cleanup();
              resolve({
                name: file.name,
                type: file.type,
                data: reader.result.split(",")[1],
              });
            };

            reader.onerror = (error) => {
              cleanup();
              reject(error);
            };

            reader.readAsDataURL(file);
          });
        })
      );
      const params = {
        message,
        userEmail,
        userName,
        attachments: processedAttachments,
      };

      const response = await axios.post(`${apiBaseUrl}/contact`, params, {
        headers,
      });

      if (response.status !== 200) {
        const errorMessage = response.data.error || "Failed to send message";
        throw new Error(errorMessage);
      }

      return response.data;
    } catch (error) {
      console.error("Error sending contact email:", error);
      throw error;
    }
  };

  const fetchCustomers = async () => {
    const token = await getAccessTokenSilently();
    const headers = await getHeaders(token, domain);
    return axios.get(`${apiBaseUrl}/customers`, {
      headers,
      params: {
        domain: domain,
        user_is_qurrent: userIsQurrent,
      },
    });
  };

  const fetchWorkflowFeedback = async (workflowInstanceId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        workflow_instance_id: workflowInstanceId,
        domain: domain,
        user_is_qurrent: userIsQurrent,
      };

      const response = await axios.get(`${apiBaseUrl}/workflow/feedback`, {
        headers,
        params: params,
      });

      console.log("Response for workflow feedback:", response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching workflow feedback:", error);
      return [];
    }
  };

  const createWorkflowFeedback = async (workflowInstanceId, feedbackData) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        workflow_instance_id: workflowInstanceId,
        domain: domain,
        user_is_qurrent: userIsQurrent,
      };

      console.log("Params for create:", params, feedbackData);

      const response = await axios.post(
        `${apiBaseUrl}/workflow/feedback`,
        feedbackData,
        {
          headers,
          params: params,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error creating workflow feedback:", error);
      throw error;
    }
  };

  const updateWorkflowFeedback = async (feedbackId, feedbackData) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        domain: domain,
        user_is_qurrent: userIsQurrent,
      };

      console.log("Params for update:", params, feedbackData);

      const response = await axios.put(
        `${apiBaseUrl}/workflow/feedback/${feedbackId}`,
        feedbackData,
        {
          headers,
          params: params,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error updating workflow feedback:", error);
      throw error;
    }
  };

  const deleteWorkflowFeedback = async (feedbackId, deleteType, userEmail) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        domain: domain,
        user_is_qurrent: userIsQurrent,
        delete_type: deleteType,
        user_email: userEmail,
      };

      console.log("Params for delete:", params);

      const response = await axios.delete(
        `${apiBaseUrl}/workflow/feedback/${feedbackId}`,
        {
          headers,
          params,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error deleting workflow feedback:", error);
      throw error;
    }
  };

  const getAllFeedback = async (filters) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const params = {
        domain: domain,
        user_is_qurrent: userIsQurrent,
        search: filters?.name || undefined,
        start_date: filters?.lastRun?.start,
        end_date: filters?.lastRun?.end,
        sort_column: filters?.sortColumn,
        sort_direction: filters?.sortDirection,
        ...(userIsQurrent && {
          impersonated_customer_id: impersonatedCustomer
            ? impersonatedCustomer.id
            : selectedCustomerForImpersonation?.id,
        }),
        archived_included: filters?.archivedIncluded,
      };

      console.log("Params:", params);

      const response = await axios.get(`${apiBaseUrl}/workflow/all-feedback`, {
        headers,
        params: params,
      });

      console.log("Response for all feedback:", response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching all feedback:", error);
      throw error;
    }
  };

  const ensureUserInEnvironment = async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = await getHeaders(token, domain);

      const response = await axios.post(
        `${apiBaseUrl}/users/ensure`,
        {},
        {
          headers,
          params: {
            domain,
            email: user.email,
            name: user.name,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error ensuring user in environment:", error);
      throw error;
    }
  };

  return {
    fetchWorkflowInstances,
    fetchFilteredInstances,
    fetchWorkflowStructure,
    fetchChildWorkflows,
    fetchObservableSummary,
    fetchLlmCallSummary,
    fetchLatestWorkflowInstance,
    fetchTotalRuns,
    endSession,
    fetchWorkflowInstanceInfo,
    fetchWorkflowsForLast7Days,
    fetchIdentifiers,
    fetchMetricsData,
    sendContactEmail,
    fetchCustomers,
    fetchWorkflowFeedback,
    createWorkflowFeedback,
    getAllFeedback,
    updateWorkflowFeedback,
    deleteWorkflowFeedback,
    ensureUserInEnvironment,
  };
};
