import React, { createContext, useContext, useState } from "react";
import { useApiUtils } from "../utils/apiUtils.js";
import { formatName } from "../utils/formatUtils.js";

const FeedbackContext = createContext();

export const FeedbackProvider = ({ children }) => {
  const [workflowFeedback, setWorkflowFeedback] = useState([]);
  const [instanceFeedback, setInstanceFeedback] = useState([]);
  const [instanceOutputs, setInstanceOutputs] = useState([]);
  const apiUtils = useApiUtils();

  const fetchFeedbackForWorkflow = async (workflowInstanceId) => {
    try {
      const response = await apiUtils.fetchWorkflowFeedback(workflowInstanceId);

      if (!response || !Array.isArray(response.feedback)) {
        setInstanceFeedback([]);
        setInstanceOutputs([]);
        return { feedback: [], outputs: [] };
      }

      // The API already filters by workflow_instance_id, so we can use the feedback directly
      const validFeedback = response.feedback;

      const outputs = response.common_data?.outputs || [];
      const identifiers = response.common_data?.identifiers || [];

      const processedOutputs = [
        ...identifiers.map((id) => ({
          key: formatName(id.identifier_key),
          output: id.identifier_value,
          isIdentifier: true,
          created_at: id.created_at,
        })),
        ...outputs.map((output) => ({
          key: formatName(output.key),
          output: output.output,
          created_at: output.created_at,
          isIdentifier: false,
        })),
      ];

      setInstanceFeedback(validFeedback);
      setInstanceOutputs(processedOutputs);

      return { feedback: validFeedback, outputs: processedOutputs };
    } catch (error) {
      console.error("Error fetching feedback:", error);
      setInstanceFeedback([]);
      setInstanceOutputs([]);
      return { feedback: [], outputs: [] };
    }
  };

  const fetchAllFeedback = async (filters) => {
    console.log("Fetching all feedback");
    try {
      const response = await apiUtils.getAllFeedback(filters);
      setWorkflowFeedback(response);
    } catch (error) {
      console.error("Error fetching all feedback:", error);
    }
  };

  const updateFeedback = async (feedbackId, feedbackData) => {
    try {
      const updatedFeedback = await apiUtils.updateWorkflowFeedback(
        feedbackId,
        feedbackData
      );
      setInstanceFeedback((prev) =>
        prev.map((f) => (f.id === feedbackId ? updatedFeedback : f))
      );
      await fetchAllFeedback();
      return updatedFeedback;
    } catch (error) {
      console.error("Error updating feedback:", error);
      throw error;
    }
  };

  const deleteFeedback = async (feedbackId) => {
    try {
      await apiUtils.deleteWorkflowFeedback(feedbackId);
      // Remove from both instance and workflow feedback states
      setInstanceFeedback((prev) => prev.filter((f) => f.id !== feedbackId));
      await fetchAllFeedback(); // Refresh the main feedback list
    } catch (error) {
      console.error("Error deleting feedback:", error);
      throw error;
    }
  };

  const clearFeedback = () => {
    setWorkflowFeedback([]);
  };

  return (
    <FeedbackContext.Provider
      value={{
        workflowFeedback,
        setWorkflowFeedback,
        instanceFeedback,
        instanceOutputs,
        fetchFeedbackForWorkflow,
        fetchAllFeedback,
        updateFeedback,
        deleteFeedback,
        clearFeedback,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => useContext(FeedbackContext);
