export const toggleOverlay = (
  container,
  show,
  message = "No data collected yet"
) => {
  let overlay = container.querySelector(".chart-overlay");

  if (show) {
    if (!overlay) {
      container.style.position = "relative";
      overlay = document.createElement("div");
      overlay.className = "chart-overlay";

      // Create a styled message container
      const messageContainer = document.createElement("div");
      messageContainer.style.cssText = `
        background-color: var(--background-color);
        padding: 10px 20px;
        border-radius: 4px;
        border: 3px solid #ff0000;
        font-size: 14px;
        font-family: Asap, sans-serif;
        color: var(--text-color);
      `;
      messageContainer.innerText = message;

      // Style the overlay container
      overlay.style.cssText = `
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      `;

      overlay.appendChild(messageContainer);
      container.appendChild(overlay);
    } else {
      overlay.style.display = "flex";
      overlay.querySelector("div").innerText = message;
    }
  } else if (overlay) {
    overlay.style.display = "none";
  }
};

export const createTooltipContainer = (computedStyles) => {
  const tooltipContainer = document.createElement("div");
  tooltipContainer.className = "tooltip-container";
  tooltipContainer.style.cssText = `
    display: none;
    position: fixed;
    background: ${computedStyles.getPropertyValue("--background-color")};
    color: ${computedStyles.getPropertyValue("--text-color")};
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 12px;
    z-index: 1000;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    font-family: Asap;
  `;
  return tooltipContainer;
};

export const createMetricNameTooltip = (params, computedStyles, metric) => {
  const tooltipDom = document.createElement("div");
  tooltipDom.style.cssText = `
    position: fixed;
    background: ${computedStyles.getPropertyValue("--background-color")};
    padding: 10px;
    font-size: 12px;
    font-family: Asap;
    color: ${computedStyles.getPropertyValue("--text-color")};
    border: 1px solid ${computedStyles.getPropertyValue("--text-color")};
    border-radius: 5px;
    z-index: 9999;
    left: ${params.event.pageX + 15}px;
    top: ${params.event.pageY + 15}px;
  `;
  tooltipDom.innerHTML = `${
    metric.metric_name.charAt(0).toUpperCase() + metric.metric_name.slice(1)
  } (per ${metric.time_interval})`;
  tooltipDom.id = "metric-name-tooltip";
  return tooltipDom;
};

export const handleTooltipMouseEvents = (button, tooltipContainer) => {
  button.addEventListener("mouseenter", (e) => {
    tooltipContainer.textContent = e.target.getAttribute("data-tooltip");
    tooltipContainer.style.display = "block";
    tooltipContainer.style.width = "auto";
    tooltipContainer.style.maxWidth = "300px";
    tooltipContainer.style.height = "auto";
    tooltipContainer.style.whiteSpace = "nowrap";

    const naturalWidth = tooltipContainer.offsetWidth;

    if (naturalWidth > 300) {
      tooltipContainer.style.whiteSpace = "normal";
      tooltipContainer.style.width = "300px";
    }

    const rect = e.target.getBoundingClientRect();
    const tooltipWidth = tooltipContainer.offsetWidth;
    const windowWidth = window.innerWidth;

    let leftPos = rect.left;
    if (leftPos + tooltipWidth > windowWidth) {
      leftPos = windowWidth - tooltipWidth - 10;
    }

    tooltipContainer.style.left = `${leftPos}px`;
    tooltipContainer.style.top = `${rect.bottom + 5}px`;
  });

  button.addEventListener("mouseleave", () => {
    tooltipContainer.style.display = "none";
    tooltipContainer.style.width = "auto";
    tooltipContainer.style.whiteSpace = "nowrap";
  });
};
