const getMemorySnapshot = () => {
  if (
    typeof window !== "undefined" &&
    window.performance &&
    window.performance.memory
  ) {
    const memory = window.performance.memory;
    return {
      jsHeapSizeLimit: Math.round(memory.jsHeapSizeLimit / 1024 / 1024),
      totalJSHeapSize: Math.round(memory.totalJSHeapSize / 1024 / 1024),
      usedJSHeapSize: Math.round(memory.usedJSHeapSize / 1024 / 1024),
      timestamp: new Date().toISOString(),
    };
  }
  return null;
};

const memoryHistory = [];
const MAX_HISTORY_LENGTH = 50;

export const trackOperation = (operationName) => {
  try {
    const beforeMemory = getMemorySnapshot();

    return {
      end: () => {
        try {
          const afterMemory = getMemorySnapshot();
          if (beforeMemory && afterMemory) {
            const memoryDiff = {
              operation: operationName,
              memoryIncrease:
                afterMemory.usedJSHeapSize - beforeMemory.usedJSHeapSize,
              before: beforeMemory,
              after: afterMemory,
            };

            memoryHistory.push(memoryDiff);
            if (memoryHistory.length > MAX_HISTORY_LENGTH) {
              memoryHistory.shift();
            }

            // Only log significant changes (more than 5MB)
            if (Math.abs(memoryDiff.memoryIncrease) > 5) {
              console.log(`Memory Change [${operationName}]:`, {
                increase: `${memoryDiff.memoryIncrease} MB`,
                before: `${beforeMemory.usedJSHeapSize} MB`,
                after: `${afterMemory.usedJSHeapSize} MB`,
              });
            }
          }
        } catch (e) {
          // Silently fail if monitoring fails
        }
      },
    };
  } catch (e) {
    // Return no-op if tracking fails
    return { end: () => {} };
  }
};

export const getMemoryHistory = () => [...memoryHistory];

export const monitorMemoryUsage = () => {
  try {
    // Only monitor if we have access to the performance API
    if (
      typeof window !== "undefined" &&
      window.performance &&
      window.performance.memory
    ) {
      setInterval(() => {
        try {
          const memory = window.performance.memory;
          // console.log("Memory usage:", {
          //   jsHeapSizeLimit: `${Math.round(
          //     memory.jsHeapSizeLimit / 1024 / 1024
          //   )} MB`,
          //   totalJSHeapSize: `${Math.round(
          //     memory.totalJSHeapSize / 1024 / 1024
          //   )} MB`,
          //   usedJSHeapSize: `${Math.round(
          //     memory.usedJSHeapSize / 1024 / 1024
          //   )} MB`,
          // });
        } catch (e) {
          // Silently fail if monitoring fails
        }
      }, 30000);
    }
  } catch (e) {
    // Silently fail if setup fails
  }
};
