import React from "react";
import "../App.css";

function ActualErrorPopup({ errorMessage, onClose }) {
  if (!errorMessage) return null;

  const errors = Array.isArray(errorMessage) ? errorMessage : [errorMessage];

  const renderError = (error) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return error.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < part.split("\n").length - 1 && <br />}
        </React.Fragment>
      ));
    });
  };

  const handleClose = () => {
    onClose();
    window.location.href = "/";
  };

  return (
    <div className="error-overlay" onClick={handleClose}>
      <div className="error-notification" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <div className="error-content-actual">
          {errors.map((error, index) => (
            <React.Fragment key={index}>
              <div id={`error-${index}`} style={{ marginBottom: "12px" }}>
                {renderError(error)}
              </div>
              {index < errors.length - 1 && <hr className="divider-popup" />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ActualErrorPopup;
