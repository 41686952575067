import React, { createContext, useContext, useState, useEffect } from "react";

const FeedbackFilterContext = createContext();

export const FeedbackFilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => {
    // Try to get saved filters from sessionStorage on initial load
    const savedFilters = sessionStorage.getItem("feedbackFilters");
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
          name: null,
          lastRun: null,
          sortColumn: "created_at",
          sortDirection: "desc",
          archivedIncluded: false,
        };
  });

  // Save filters to sessionStorage whenever they change
  useEffect(() => {
    sessionStorage.setItem("feedbackFilters", JSON.stringify(filters));
  }, [filters]);

  return (
    <FeedbackFilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FeedbackFilterContext.Provider>
  );
};

export const useFeedbackFilter = () => useContext(FeedbackFilterContext);
