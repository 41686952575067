import React, { createContext, useState, useContext, useEffect } from "react";
import { debounce } from "lodash";

const WorkflowTooltipContext = createContext();

export const WorkflowTooltipProvider = ({ children }) => {
  const [tooltips, setTooltips] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const [temporarilyShowAll, setTemporarilyShowAll] = useState(false);

  useEffect(() => {
    const savedTooltips = localStorage.getItem("workflowTooltipPreferences");
    if (savedTooltips) {
      setTooltips(JSON.parse(savedTooltips));
    }
  }, []);

  const saveToLocalStorage = debounce((tooltipData) => {
    localStorage.setItem(
      "workflowTooltipPreferences",
      JSON.stringify(tooltipData)
    );
  }, 1000);

  const initializeTooltipsForNewUser = () => {
    setIsNewUser(true);
    setTooltips({});
    saveToLocalStorage({});
  };

  const shouldShowTooltips = (page) => {
    if (temporarilyShowAll) {
      return true;
    }

    if (isNewUser && tooltips[page] === undefined) {
      return true;
    }
    return tooltips[page] !== false;
  };

  const closeWorkflowTooltipForever = (page) => {
    setTemporarilyShowAll(false);
    const updatedTooltips = {
      ...tooltips,
      [page]: false,
    };
    setTooltips(updatedTooltips);
    saveToLocalStorage(updatedTooltips);
  };

  const resetTooltips = (pageName) => {
    if (pageName.includes("/workflow/")) {
      setTemporarilyShowAll(true);
    }
  };

  return (
    <WorkflowTooltipContext.Provider
      value={{
        tooltips,
        closeWorkflowTooltipForever,
        shouldShowTooltips,
        initializeTooltipsForNewUser,
        resetTooltips,
      }}
    >
      {children}
    </WorkflowTooltipContext.Provider>
  );
};

export const useWorkflowTooltip = () => useContext(WorkflowTooltipContext);
