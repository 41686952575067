import React, { useContext, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import { formatDateRange } from "../utils/formatUtils.js";
import { EnvironmentContext } from "../contexts/EnvironmentContext.js";
import FilterDropdown from "../main_components/FilterDropdown.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";

function FeedbackFilterRow({
  toggleFilterOptions,
  setFilters,
  openDropdown,
  handleSearch,
  handleDateFilter,
  showCalendar,
  calendarRef,
  handleChange,
  selectedDateRange,
  submitDateRange,
  submitButtonRef,
  filters,
  setShowCalendar,
  fetchAllFeedback,
  isLoading = false,
}) {
  const { searchInputRef } = useContext(EnvironmentContext);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (searchInputRef.current && filters.name) {
      searchInputRef.current.value = filters.name;
    }
  }, [filters.name, searchInputRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        openDropdown === "lastRun"
      ) {
        toggleFilterOptions(null);
      }

      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        !event.target.closest(".react-calendar") &&
        showCalendar
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openDropdown, toggleFilterOptions, showCalendar]);

  const renderDateDropdown = () => (
    <div>
      {["Last 7 Days", "Last 30 Days", "Custom date range"].map((option) => (
        <div
          key={option}
          className="dropdown-option"
          onClick={() =>
            handleDateFilter(option === "Custom date range" ? "Custom" : option)
          }
        >
          {option}
        </div>
      ))}
    </div>
  );

  const handleReload = () => {
    fetchAllFeedback(filters);
  };

  return (
    <div className="Feedback-filter-row">
      <div ref={dropdownRef}>
        <FilterDropdown
          type="lastRun"
          displayValue={
            filters.lastRun === null
              ? "Last Run"
              : typeof filters.lastRun === "string"
              ? filters.lastRun
              : filters.lastRun?.display ||
                (filters.lastRun?.start && filters.lastRun?.end
                  ? formatDateRange(
                      new Date(filters.lastRun.start),
                      new Date(filters.lastRun.end)
                    )
                  : "")
          }
          isActive={filters.lastRun}
          onClear={() => setFilters((prev) => ({ ...prev, lastRun: null }))}
          toggleFilterOptions={toggleFilterOptions}
          openDropdown={openDropdown}
        >
          {renderDateDropdown()}
        </FilterDropdown>
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder=" Search..."
          ref={searchInputRef}
          defaultValue={filters.name || ""}
          onChange={(e) => {
            const searchTerm = e.target.value.trim();
            handleSearch(searchTerm);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              const searchTerm = e.target.value.trim();
              handleSearch(searchTerm);
            }
          }}
          className="styled-search-input"
        />
        {searchInputRef.current?.value && (
          <button
            className="App-search-submit"
            onClick={() => {
              if (searchInputRef.current) {
                searchInputRef.current.value = "";
                handleSearch("");
              }
            }}
            id="search-submit"
            name="search-submit"
            aria-label="Clear search"
          >
            ×
          </button>
        )}
      </div>
      <div className="Feedback-filter-reload">
        <button
          className="App-filter-button"
          onClick={handleReload}
          title="Refresh feedback"
        >
          <FontAwesomeIcon icon={faRotate} />
        </button>
      </div>
      {isLoading && (
        <div className="feedback-loading-spinner">
          <div className="spinner"></div>
        </div>
      )}
      {showCalendar && (
        <div ref={calendarRef} className="Feedback-date-range-picker">
          <Calendar
            onChange={(value) => {
              handleChange(value);
              if (Array.isArray(value) && value[0] && value[1]) {
                submitDateRange(value);
                console.log("Selected Date Range:", value);
              }
            }}
            value={selectedDateRange}
            selectRange={true}
            className="custom-calendar"
          />
          <button
            ref={submitButtonRef}
            className="Feedback-submit-date-range"
            onClick={() => setShowCalendar(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default FeedbackFilterRow;
