import React, { useEffect, useState, useContext, useRef } from "react";
import Header from "./Header.js";
import Calendar from "react-calendar";
import { useApiUtils } from "./utils/apiUtils.js";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";
import "./App.css";
import { ThemeContext } from "./contexts/ThemeContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faDownload,
  faChartLine,
  faTable,
  faRotate,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "./contexts/UserContext.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useFilter } from "./contexts/FilterContext.js";
import { useAuth0 } from "@auth0/auth0-react";
import { renderStackedBarChart } from "./metrics_components/StackedBarChart.js";
import { renderChart } from "./metrics_components/LineBarChart.js";
import { renderTable } from "./metrics_components/TableChart.js";

library.add(faDownload, faChartLine, faTable, faFileDownload);

function Metrics() {
  const [metricsData, setMetricsData] = useState([]);
  const [workflowNames, setWorkflowNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const { fetchMetricsData, fetchCustomers } = useApiUtils();

  const { userIsQurrent } = useUser();
  const { env } = useContext(EnvironmentContext);
  const chartRef = useRef(null);
  const { darkMode } = useContext(ThemeContext);
  const [openDropdown, setOpenDropdown] = useState(null);

  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [dateRangeLabel, setDateRangeLabel] = useState("Select Date Range");
  const [showCalendar, setShowCalendar] = useState(false);

  const renderedCharts = new Set();

  const { filters } = useFilter();
  const { impersonatedCustomer, setCustomers } = useUser();
  const { user } = useAuth0();

  const [selectedMetricName, setSelectedMetricName] = useState("");

  const [isStreaming, setIsStreaming] = useState(false);

  // Add a ref to track dark mode changes
  const prevDarkMode = useRef(darkMode);

  // Add new state at the top with other states
  const [filterChanged, setFilterChanged] = useState(false);

  // Helper function to COMPLETELY clear everything
  const clearAllMetricsAndCharts = () => {
    setSelectedMetricName("");
    setMetricsData([]);
    setWorkflowNames([]);
    if (chartRef.current) {
      // Dispose ALL chart instances
      if (chartRef.current.chartInstances) {
        chartRef.current.chartInstances.forEach((instance) => {
          instance.dispose();
        });
        chartRef.current.chartInstances.clear();
      }
      // Remove ALL DOM elements
      while (chartRef.current.firstChild) {
        chartRef.current.removeChild(chartRef.current.firstChild);
      }
      // Reset the chart ref
      chartRef.current.innerHTML = "";
    }
    // Clear any rendered charts tracking
    renderedCharts.clear();
  };

  const fetchThisMetricsData = async (workflowOverride = null) => {
    console.log("Fetching metrics data for environment:", env);
    const customerName = impersonatedCustomer?.name || filters?.customer;
    setIsLoading(true);
    setIsStreaming(true);

    try {
      const workflowName =
        workflowOverride?.type === "click"
          ? selectedWorkflow
          : workflowOverride;

      // Clear everything first
      clearAllMetricsAndCharts();

      const onMetricsUpdate = ({ metrics_data, workflow_names }) => {
        // Replace metrics, don't append
        setMetricsData(metrics_data);
        if (workflow_names.length > 0) {
          setWorkflowNames(workflow_names);
        }
      };

      await fetchMetricsData(workflowName, customerName, onMetricsUpdate);
    } catch (error) {
      console.error("Error fetching metrics data:", error);
    } finally {
      setIsLoading(false);
      setIsStreaming(false);
    }
  };

  useEffect(() => {
    const getCustomers = async () => {
      if (user && userIsQurrent) {
        try {
          const response = await fetchCustomers();
          setCustomers(response.data.customers);
        } catch (error) {
          console.error("Error fetching customers:", error);
        }
      }
    };
    getCustomers();
  }, []);

  useEffect(() => {
    console.log("Filter/Env change detected - CLEARING ALL DATA");

    // FIRST: Set filter changed to true to force chart cleanup
    setFilterChanged(true);

    // Clear workflow filter ONLY when header filters change
    setSelectedWorkflow("");

    // THEN: Clear everything else
    clearAllMetricsAndCharts();

    setIsLoading(true);
    setIsStreaming(true); // Set streaming true BEFORE clearing data

    const customerName = impersonatedCustomer?.name || filters?.customer;

    // Force a complete reset before fetching new data
    Promise.resolve().then(() => {
      setMetricsData([]); // Force clear again

      const onMetricsUpdate = ({ metrics_data, workflow_names }) => {
        setMetricsData(metrics_data);
        if (workflow_names.length > 0) {
          setWorkflowNames(workflow_names);
        }
      };

      fetchMetricsData(null, customerName, onMetricsUpdate)
        .catch((error) => {
          console.error("Error fetching metrics data:", error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsStreaming(false); // Move streaming false to finally block
        });
    });
  }, [env, filters, impersonatedCustomer]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Handle calendar click outside
      const calendarElement = document.querySelector(
        ".App-date-range-picker-metrics"
      );
      if (
        showCalendar &&
        calendarElement &&
        !calendarElement.contains(event.target)
      ) {
        setShowCalendar(false);
        setOpenDropdown(null);
      }

      // Handle dropdown click outside
      const dropdownElements = document.querySelectorAll(
        ".App-filter-dropdown"
      );
      let clickedInsideDropdown = false;

      dropdownElements.forEach((dropdown) => {
        if (dropdown.contains(event.target)) {
          clickedInsideDropdown = true;
        }
      });

      if (!clickedInsideDropdown) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  const renderChartOrTable = (metric, container) => {
    if (metric.metric_type === "stacked_bar_chart") {
      // Collect all metrics with the same workflow_name and metric_name
      const relatedMetrics = metricsData.filter(
        (m) =>
          m.metric_name === metric.metric_name &&
          m.workflow_name === metric.workflow_name
      );
      renderStackedBarChart(
        relatedMetrics,
        container,
        selectedDateRange,
        renderedCharts,
        downloadData
      );
    } else if (metric.metric_type === "table") {
      renderTable(
        metric,
        container,
        selectedDateRange,
        prepareDataForDownload,
        downloadData,
        null
      );
    } else {
      renderChart(
        metric,
        container,
        selectedDateRange,
        prepareDataForDownload,
        downloadData,
        null
      );
    }
  };

  useEffect(() => {
    const initializeCharts = () => {
      if (!chartRef.current) {
        console.error("Chart reference is not attached to a DOM element.");
        return;
      }

      // Initialize chart instances map if not exists
      if (!chartRef.current.chartInstances) {
        chartRef.current.chartInstances = new Map();
      }

      // Clear all existing charts and containers when filters change
      if (
        selectedMetricName ||
        (selectedDateRange[0] && selectedDateRange[1])
      ) {
        // Dispose all chart instances
        chartRef.current.chartInstances.forEach((instance) => {
          instance.dispose();
        });
        chartRef.current.chartInstances.clear();

        // Clear all workflow groups
        while (chartRef.current.firstChild) {
          chartRef.current.removeChild(chartRef.current.firstChild);
        }
      }

      // Only clear and re-render if dark mode actually changed
      if (prevDarkMode.current !== darkMode) {
        chartRef.current.chartInstances.forEach((instance) => {
          instance.dispose();
        });
        chartRef.current.chartInstances.clear();
        while (chartRef.current.firstChild) {
          chartRef.current.removeChild(chartRef.current.firstChild);
        }
        prevDarkMode.current = darkMode;
      }

      // Clear charts if filters changed
      if (filterChanged) {
        chartRef.current.chartInstances.forEach((instance) => {
          instance.dispose();
        });
        chartRef.current.chartInstances.clear();
        while (chartRef.current.firstChild) {
          chartRef.current.removeChild(chartRef.current.firstChild);
        }
        setFilterChanged(false); // Reset the flag
      }

      // Guard against null/undefined metricsData
      let filteredMetricsData = Array.isArray(metricsData)
        ? [...metricsData]
        : [];

      // Apply filters
      if (selectedMetricName) {
        filteredMetricsData = filteredMetricsData.filter(
          (metric) => metric.metric_name === selectedMetricName
        );
      }

      if (selectedDateRange[0] && selectedDateRange[1]) {
        const startDate = new Date(selectedDateRange[0]);
        const endDate = new Date(selectedDateRange[1]);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
      }

      // Get list of valid chart IDs from filtered data
      const validChartIds = new Set(
        filteredMetricsData.map((m) => `${m.workflow_name}-${m.metric_name}`)
      );

      // Remove charts that shouldn't exist anymore
      for (const [chartId, instance] of chartRef.current.chartInstances) {
        if (!validChartIds.has(chartId)) {
          instance.dispose();
          chartRef.current.chartInstances.delete(chartId);
        }
      }

      const metricsByWorkflow = filteredMetricsData.reduce((acc, metric) => {
        if (!acc[metric.workflow_name]) {
          acc[metric.workflow_name] = [];
        }
        acc[metric.workflow_name].push(metric);
        return acc;
      }, {});

      Object.entries(metricsByWorkflow)
        .sort(([a], [b]) => a.localeCompare(b))
        .forEach(([workflowName, metrics]) => {
          let workflowGroup = chartRef.current.querySelector(
            `[data-workflow="${workflowName}"]`
          );

          if (!workflowGroup) {
            // Add workflow name header with divider
            const workflowHeader = document.createElement("h2");
            workflowHeader.className = "workflow-header";
            workflowHeader.innerHTML = `${workflowName}<div class="workflow-divider"></div>`;
            chartRef.current.appendChild(workflowHeader);

            // Add workflow group
            workflowGroup = document.createElement("div");
            workflowGroup.className = "workflow-group";
            workflowGroup.setAttribute("data-workflow", workflowName);
            chartRef.current.appendChild(workflowGroup);
          }

          metrics.forEach((metric) => {
            const chartId = `${metric.workflow_name}-${metric.metric_name}`;
            let container = workflowGroup.querySelector(
              `[data-chart-id="${chartId}"]`
            );
            let chartInstance = chartRef.current.chartInstances.get(chartId);

            if (!container) {
              container = document.createElement("div");
              container.setAttribute("data-chart-id", chartId);
              workflowGroup.appendChild(container);
              renderChartOrTable(metric, container);
              if (container.chartInstance) {
                chartRef.current.chartInstances.set(
                  chartId,
                  container.chartInstance
                );
              }
            } else if (chartInstance) {
              // Update existing chart data
              if (metric.metric_type === "stacked_bar_chart") {
                const relatedMetrics = metricsData.filter(
                  (m) =>
                    m.metric_name === metric.metric_name &&
                    m.workflow_name === metric.workflow_name
                );
                chartInstance.setOption({
                  series: relatedMetrics.map((m) => ({
                    data: m.interval_data,
                  })),
                });
              } else {
                chartInstance.setOption({
                  series: [
                    {
                      data: metric.interval_data,
                    },
                  ],
                });
              }
            }
          });
        });
    };

    initializeCharts();
  }, [
    metricsData,
    selectedMetricName,
    selectedDateRange,
    darkMode,
    filters,
    filterChanged,
  ]);

  const toggleFilterOptions = (filterType) => {
    if (isStreaming) return;
    setOpenDropdown(openDropdown === filterType ? null : filterType);
  };

  function formatMetricValue(value, metricFormat) {
    const format = metricFormat || "0";
    const decimalLength = format.includes(".")
      ? format.split(".")[1].length
      : 0;
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimalLength,
      maximumFractionDigits: decimalLength,
    }).format(value);
  }

  const handleDateFilter = (option) => {
    let startDate, endDate;
    const now = new Date();

    if (option === "Last 7 Days") {
      startDate = new Date(now);
      startDate.setDate(now.getDate() - 7);
      endDate = now;
      setDateRangeLabel("Last 7 Days");
    } else if (option === "Last 30 Days") {
      startDate = new Date(now);
      startDate.setDate(now.getDate() - 30);
      endDate = now;
      setDateRangeLabel("Last 30 Days");
    } else {
      setShowCalendar(true);
      setOpenDropdown(null);
      return;
    }

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    setSelectedDateRange([startDate, endDate]);
    setShowCalendar(false);
    setOpenDropdown(null);
  };

  const handleCustomDateRange = (range) => {
    if (range && range.length === 2) {
      const [startDate, endDate] = range;

      // Set time in local timezone
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      setSelectedDateRange([startDate, endDate]);

      // Format dates using the user's locale
      const dateFormatter = new Intl.DateTimeFormat(navigator.language);
      const formattedStart = dateFormatter.format(startDate);
      const formattedEnd = dateFormatter.format(endDate);
      setDateRangeLabel(`${formattedStart} - ${formattedEnd}`);

      if (startDate && endDate) {
        setShowCalendar(false);
        setOpenDropdown(null);
      }
    }
  };

  const handleWorkflowSelect = async (workflowName) => {
    setSelectedWorkflow(workflowName);
    setSelectedMetricName("");
    setOpenDropdown(null);
    setIsLoading(true);
    setIsStreaming(true);
    try {
      const customerName = impersonatedCustomer?.name || filters?.customer;

      // Clear everything first
      clearAllMetricsAndCharts();

      const onMetricsUpdate = ({ metrics_data, workflow_names }) => {
        // Replace metrics, don't append
        setMetricsData(metrics_data);
        if (workflow_names.length > 0) {
          setWorkflowNames(workflow_names);
        }
      };

      await fetchMetricsData(workflowName, customerName, onMetricsUpdate);
    } catch (error) {
      console.error("Error in handleWorkflowSelect:", error);
    } finally {
      setIsLoading(false);
      setIsStreaming(false);
    }
  };

  const prepareDataForDownload = (data, metric) => {
    if (!data || !Array.isArray(data)) {
      console.error("Invalid input data:", data);
      return [];
    }

    // Direct return for hourly data
    if (metric.time_interval === "hour") {
      return data.map((item) => ({
        "Start Date": new Date(item.interval_start).toISOString(),
        "End Date": new Date(
          new Date(item.interval_start).getTime() + 60 * 60 * 1000
        ).toISOString(),
        Dimension: "N/A",
        Value: formatMetricValue(item.measure, metric.metric_format),
      }));
    }

    // Handle monthly data
    if (metric.time_interval === "month") {
      return data.map((item) => {
        const startDate = new Date(item.interval_start);
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0); // Last day of the month

        return {
          "Start Date": startDate.toISOString(),
          "End Date": endDate.toISOString(),
          Dimension: item.dimension || "N/A",
          Value: formatMetricValue(item.measure, metric.metric_format),
        };
      });
    }

    // Handle weekly data
    if (metric.time_interval === "week") {
      return data.map((item) => {
        const startDate = new Date(item.interval_start);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        return {
          "Start Date": startDate.toISOString(),
          "End Date": endDate.toISOString(),
          Dimension: item.dimension || "N/A",
          Value: formatMetricValue(item.measure, metric.metric_format),
        };
      });
    }

    // Handle yearly data
    if (metric.time_interval === "year") {
      return data.map((item) => {
        const startDate = new Date(item.interval_start);
        startDate.setMonth(0, 1); // January 1st
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(startDate);
        endDate.setFullYear(startDate.getFullYear() + 1);
        endDate.setDate(0); // December 31st
        endDate.setHours(23, 59, 59, 999);

        return {
          "Start Date": startDate.toISOString(),
          "End Date": endDate.toISOString(),
          Dimension: item.dimension || "N/A",
          Value: formatMetricValue(item.measure, metric.metric_format),
        };
      });
    }

    // Default (daily data)
    return data.map((item) => {
      const startDate = new Date(item.interval_start);
      const endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);

      return {
        "Start Date": startDate.toISOString(),
        "End Date": endDate.toISOString(),
        Dimension: item.dimension || "N/A",
        Value: formatMetricValue(item.measure, metric.metric_format),
      };
    });
  };

  const createPreviewModal = (data, filename, onDownload, metric) => {
    const modal = document.createElement("div");
    modal.className = "download-preview-modal";
    modal.style.cssText = `
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--background-color);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      z-index: 1000;
      max-width: 800px;
      width: 90%;
      max-height: 60vh;
      display: flex;
      flex-direction: column;
      font-family: Asap;
    `;

    const overlay = document.createElement("div");
    overlay.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 999;
    `;

    const header = document.createElement("div");
    header.style.cssText = `
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      color: var(--text-color);
      flex-shrink: 0;
    `;

    const title = document.createElement("h3");
    title.textContent = `Preview: ${filename}(per_${metric.time_interval})`;
    title.style.margin = "0";

    const description = document.createElement("div");
    description.style.cssText = `
      color: var(--text-color);
      margin-bottom: 15px;
    `;
    description.textContent =
      metric.metric_definition || "No description available";

    const closeButton = document.createElement("button");
    closeButton.innerHTML = "×";
    closeButton.style.cssText = `
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: var(--text-color);
    `;

    const content = document.createElement("div");
    content.style.cssText = `
      overflow: auto;
      margin-bottom: 15px;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      padding: 0;
      color: var(--text-color);
      flex: 1;
      min-height: 0;
    `;

    const table = document.createElement("table");
    table.style.cssText = `
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      margin: 0;
      padding: 0;
    `;

    const thead = document.createElement("thead");
    thead.style.cssText = `
      position: sticky;
      top: 0;
      background: var(--background-color);
      z-index: 1;
    `;

    const headerRow = document.createElement("tr");
    Object.keys(data[0]).forEach((key) => {
      const th = document.createElement("th");
      th.textContent = key;
      th.style.cssText = `
        padding: 8px;
        border-bottom: 2px solid var(--border-color);
        color: var(--text-color);
      `;
      headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);
    table.appendChild(thead);

    const tbody = document.createElement("tbody");
    data.forEach((row) => {
      const tr = document.createElement("tr");
      Object.values(row).forEach((value) => {
        const td = document.createElement("td");
        td.textContent = value;
        td.style.cssText = `
          padding: 8px;
          border-bottom: 1px solid var(--border-color);
        `;
        tr.appendChild(td);
      });
      tbody.appendChild(tr);
    });
    table.appendChild(tbody);

    const footer = document.createElement("div");
    footer.style.cssText = `
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      flex-shrink: 0;
    `;

    const rowCount = document.createElement("span");
    rowCount.textContent = `Total rows: ${data.length}`;
    rowCount.style.color = "var(--text-color)";

    const downloadButton = document.createElement("button");
    downloadButton.textContent = "Download CSV";
    downloadButton.style.cssText = `      background: var(--button-background);
      color: var(--text-color);
      border: none;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.2s;
      display: flex;
      align-items: center;
      gap: 8px;
    `;

    const downloadIcon = document.createElement("span");
    downloadIcon.innerHTML = `<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 16L7 11H17L12 16Z" fill="currentColor"/>
      <path d="M12 2V11M12 16L7 11H17L12 16ZM6 22H18" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </svg>`;
    downloadButton.prepend(downloadIcon);

    downloadButton.onmouseover = () => {
      downloadButton.style.background = "var(--button-hover-background)";
    };
    downloadButton.onmouseout = () => {
      downloadButton.style.background = "var(--button-background)";
    };

    header.appendChild(title);
    header.appendChild(closeButton);
    content.appendChild(table);
    footer.appendChild(rowCount);
    footer.appendChild(downloadButton);
    modal.appendChild(header);
    modal.appendChild(description);
    modal.appendChild(content);
    modal.appendChild(footer);

    const closeModal = () => {
      document.body.removeChild(modal);
      document.body.removeChild(overlay);
    };

    closeButton.onclick = closeModal;
    overlay.onclick = closeModal;
    downloadButton.onclick = () => {
      onDownload();
      closeModal();
    };

    document.body.appendChild(overlay);
    document.body.appendChild(modal);
  };

  const downloadData = (data, filename, metric) => {
    // Add this null check at the beginning of the function
    if (!metric || !metric.time_interval) {
      console.error("Invalid metric object or missing time_interval property");
      return;
    }

    // Create BOM for Excel to properly recognize UTF-8
    const BOM = "\uFEFF";

    // Ensure we have valid data with at least one row
    if (!data || !Array.isArray(data) || data.length === 0) {
      data = [
        {
          "Start Date": "",
          "End Date": "",
          Dimension: "",
          Value: "",
        },
      ];
    }

    const csvContent =
      BOM +
      Object.keys(data[0]).join(",") +
      "\n" +
      data
        .map((row) => {
          return [
            row["Start Date"],
            row["End Date"],
            // Quote the Dimension if it contains commas
            row["Dimension"].includes(",")
              ? `"${row["Dimension"]}"`
              : row["Dimension"],
            // Quote the Value if it contains commas
            row["Value"].includes(",") ? `"${row["Value"]}"` : row["Value"],
          ].join(",");
        })
        .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const actualDownload = () => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      const formattedFilename = `${filename}_${metric.time_interval}`.replace(
        / /g,
        "_"
      );
      link.setAttribute("download", `${formattedFilename}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    };

    createPreviewModal(data, filename, actualDownload, metric);
  };

  return (
    <div className="App-metrics">
      <Header isStreaming={isStreaming} />
      <div className="App-metrics-wrapper">
        <div className="App-metrics-row">
          <div className="App-metrics-filters-left">
            {showCalendar && (
              <div className="App-date-range-picker-metrics">
                <Calendar
                  onChange={handleCustomDateRange}
                  value={selectedDateRange}
                  selectRange={true}
                  className="custom-calendar"
                />
                <button
                  className="App-submit-date-range-metrics"
                  onClick={() => {
                    setShowCalendar(false);
                    setOpenDropdown(null);
                  }}
                >
                  Close
                </button>
              </div>
            )}
            <div
              className={`App-filter-dropdown ${
                selectedWorkflow ? "active" : ""
              }`}
              onClick={() => toggleFilterOptions("workflow")}
              disabled={isStreaming}
              style={{ opacity: isStreaming ? 0.5 : 1 }}
            >
              <button
                className={`App-filter-button ${
                  selectedWorkflow ? "active" : ""
                }`}
              >
                {selectedWorkflow || "Select a Workflow"}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="small-chevron"
                />
              </button>
              {selectedWorkflow && (
                <button
                  className="App-filter-clear"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setSelectedWorkflow("");
                    setOpenDropdown(null);
                    setIsLoading(true);
                    setIsStreaming(true);
                    try {
                      const customerName =
                        impersonatedCustomer?.name || filters?.customer;

                      // Clear existing metrics first
                      setMetricsData([]);
                      chartRef.current.innerHTML = "";

                      const onMetricsUpdate = ({
                        metrics_data,
                        workflow_names,
                      }) => {
                        setMetricsData((prevData) => {
                          const existingIds = new Set(
                            prevData.map(
                              (m) => `${m.workflow_name}-${m.metric_name}`
                            )
                          );
                          const newMetrics = metrics_data.filter(
                            (m) =>
                              !existingIds.has(
                                `${m.workflow_name}-${m.metric_name}`
                              )
                          );
                          return [...prevData, ...newMetrics];
                        });
                        if (workflow_names.length > 0) {
                          setWorkflowNames(workflow_names);
                        }
                      };

                      await fetchMetricsData(
                        null,
                        customerName,
                        onMetricsUpdate
                      );
                    } catch (error) {
                      console.error("Error clearing workflow filter:", error);
                    } finally {
                      setIsLoading(false);
                      setIsStreaming(false);
                    }
                  }}
                >
                  ×
                </button>
              )}
              {openDropdown === "workflow" && (
                <div
                  className="App-filter-options-metrics"
                  onClick={(e) => e.stopPropagation()}
                >
                  {workflowNames?.map((name) => (
                    <div
                      key={name}
                      onClick={() => handleWorkflowSelect(name)}
                      className="App-filter-option-metrics"
                    >
                      {name}
                    </div>
                  )) || <div className="App-filter-option-metrics"></div>}
                </div>
              )}
            </div>
            <div
              className={`App-filter-dropdown ${
                selectedMetricName ? "active" : ""
              }`}
              onClick={() => toggleFilterOptions("metric")}
              disabled={isStreaming}
              style={{ opacity: isStreaming ? 0.5 : 1 }}
            >
              <button
                className={`App-filter-button ${
                  selectedMetricName ? "active" : ""
                }`}
              >
                {selectedMetricName || "Select a Metric"}{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="small-chevron"
                />
              </button>
              {selectedMetricName && (
                <button
                  className="App-filter-clear"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedMetricName("");
                    setOpenDropdown(null);
                    setFilterChanged(true);
                  }}
                >
                  ×
                </button>
              )}
              {openDropdown === "metric" && (
                <div
                  className="App-filter-options-metrics"
                  onClick={(e) => e.stopPropagation()}
                >
                  {Array.from(
                    new Set(
                      metricsData?.map((metric) => metric.metric_name) || []
                    )
                  ).map((metricName) => (
                    <div
                      key={metricName}
                      className="App-filter-option-metrics"
                      onClick={() => {
                        setSelectedMetricName(metricName);
                        setOpenDropdown(null);
                      }}
                    >
                      {metricName}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className={`App-filter-dropdown ${
                selectedDateRange[0] && selectedDateRange[1] ? "active" : ""
              }`}
              onClick={() => toggleFilterOptions("dateRange")}
              disabled={isStreaming}
              style={{ opacity: isStreaming ? 0.5 : 1 }}
            >
              <button
                className={`App-filter-button ${
                  selectedDateRange[0] && selectedDateRange[1] ? "active" : ""
                }`}
              >
                {dateRangeLabel}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="small-chevron"
                />
              </button>
              {selectedDateRange[0] && selectedDateRange[1] && (
                <button
                  className="App-filter-clear"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedDateRange([null, null]);
                    setDateRangeLabel("Select Date Range");
                    setOpenDropdown(null);
                    setShowCalendar(false);
                    setFilterChanged(true); // Trigger re-render
                  }}
                >
                  ×
                </button>
              )}
              {openDropdown === "dateRange" && (
                <div
                  className="App-filter-options-metrics"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="App-filter-option-metrics"
                    onClick={() => handleDateFilter("Last 7 Days")}
                  >
                    Last 7 Days
                  </div>
                  <div
                    className="App-filter-option-metrics"
                    onClick={() => handleDateFilter("Last 30 Days")}
                  >
                    Last 30 Days
                  </div>
                  <div
                    className="App-filter-option-metrics"
                    onClick={() => handleDateFilter("Custom")}
                  >
                    Custom date range
                  </div>
                </div>
              )}
            </div>
            <div className="App-filter-dropdown">
              <button
                disabled={isStreaming}
                style={{ opacity: isStreaming ? 0.5 : 1 }}
                className="App-filter-button"
                onClick={fetchThisMetricsData}
                title="Refresh metrics"
              >
                <FontAwesomeIcon icon={faRotate} />
              </button>
            </div>
          </div>
          <div className="App-metrics-filters-right">
            {isLoading && <div className="loading-spinner-metrics"></div>}
          </div>
        </div>
        {!isStreaming && metricsData.length === 0 ? (
          <div className="no-metrics-message">
            No metrics defined, please contact Qurrent to add metrics to your
            workflows.
          </div>
        ) : (
          <div className="chart-container" ref={chartRef}></div>
        )}
      </div>
    </div>
  );
}

export default Metrics;
