import React, { useEffect, useRef, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faExclamationTriangle,
  faCircleCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import ErrorNotification from "./ErrorNotification.js";
import { useApiUtils } from "../utils/apiUtils.js";
import { useUser } from "../contexts/UserContext.js";
import { EnvironmentContext } from "../contexts/EnvironmentContext.js";
import { useTooltip } from "../contexts/TooltipContext.js";
import { formatDateTime } from "../utils/formatUtils.js";

function Cards({
  totalWorkflows,
  totalRuns,
  errorCount,
  warningCount,
  successCount,
  toggleStatusFilter,
  isExpanded,
  setIsExpanded,
  error,
  fetchWorkflowInstances,
}) {
  const [report, setReport] = useState("");
  const [lastRefreshed, setLastRefreshed] = useState("");
  const { fetchWorkflowsForLast7Days } = useApiUtils();
  const firstTwoCardsRef = useRef(null);
  const { impersonatedCustomer, setImpersonatedCustomer } = useUser();
  const { switchEnvironment } = useContext(EnvironmentContext);
  const { shouldShowTooltips, closeTooltipForever } = useTooltip();
  const pageName = "MainContent";
  const showTooltip = shouldShowTooltips(pageName);

  useEffect(() => {
    setImpersonatedCustomer(null);
  }, [switchEnvironment]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        setReport("");

        const handleChunk = (chunk) => {
          setReport((prevReport) => {
            const updatedReport = prevReport + chunk;
            return updatedReport;
          });
        };

        const currentTime = new Date();
        setLastRefreshed(formatDateTime(currentTime));

        await fetchWorkflowsForLast7Days(handleChunk, abortController);

        sessionStorage.setItem(
          "lastRefreshedTime",
          formatDateTime(currentTime)
        );
        sessionStorage.setItem("customerViewChanged", "false");
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };

    const cachedReport = sessionStorage.getItem("supervisorReport");
    const cachedTime = sessionStorage.getItem("lastRefreshedTime");
    const customerViewChanged =
      sessionStorage.getItem("customerViewChanged") === "true";

    if (customerViewChanged) {
      fetchData();
    } else if (cachedReport && cachedTime) {
      console.log("Using cached report");
      setReport(cachedReport);
      setLastRefreshed(cachedTime);
    } else {
      fetchData();
    }

    return () => {
      abortController.abort();
    };
  }, [impersonatedCustomer, switchEnvironment]);

  useEffect(() => {
    if (report) {
      sessionStorage.setItem("supervisorReport", report);
      sessionStorage.setItem("lastRefreshedTime", lastRefreshed);
    }
  }, [report, lastRefreshed]);

  useEffect(() => {
    if (firstTwoCardsRef.current) {
      const firstTwoCardsWidth = firstTwoCardsRef.current.offsetWidth;
      document.documentElement.style.setProperty(
        "--first-two-cards-width",
        `${firstTwoCardsWidth}px`
      );
    }
  }, [firstTwoCardsRef]);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="App-cards">
      <div ref={firstTwoCardsRef} className="First-two-cards">
        <div className="App-card App-card-first">
          <div className="row">
            <span className="number">{totalWorkflows}</span>
            <span className="text">
              {totalWorkflows === 1 ? "Active Workflow" : "Active Workflows"}
            </span>
          </div>
          <div className="separator"></div>
          <div className="row">
            <span className={totalRuns !== undefined ? "number" : "text"}>
              {totalRuns !== undefined ? totalRuns : "Calculating"}
            </span>
            <span className="text">
              Runs{totalRuns === undefined ? "..." : ""}
            </span>
          </div>
        </div>
        <div className="App-card App-card-second">
          {showTooltip && (
            <div
              className="tooltip-tutorial tooltip-0"
              style={{ whiteSpace: "pre-wrap" }}
            >
              Failed: The run did not complete
              <br />
              Warning: The run completed with errors
              <button
                className="close-tooltip-button-main"
                onClick={() => closeTooltipForever(pageName)}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          )}
          <div className="icon" onClick={() => toggleStatusFilter("failed")}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="status-icon-card-failed"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("failed")}>
            Failed
          </div>
          <div
            className="number failed"
            onClick={() => toggleStatusFilter("failed")}
          >
            {errorCount}
          </div>
          <div className="icon" onClick={() => toggleStatusFilter("warning")}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="status-icon-card-error"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("warning")}>
            Warning
          </div>
          <div className="number error">{warningCount}</div>
          <div className="icon" onClick={() => toggleStatusFilter("completed")}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="status-icon-card-success"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("completed")}>
            Success
          </div>
          <div
            className="number completed"
            onClick={() => toggleStatusFilter("completed")}
          >
            {successCount}
          </div>
        </div>
      </div>
      {showTooltip && (
        <div
          className="tooltip-tutorial tooltip-1"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            The Supervisor Report summarizes the activity of your AI Workforce
            over the last 7 days.
          </span>
          <button
            className="close-tooltip-button-main"
            onClick={() => closeTooltipForever(pageName)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
      <div
        className={`App-card App-card-third ${isExpanded ? "expanded" : ""}`}
        onClick={handleExpandClick}
      >
        <div className="supervisor-report-header">
          <h4>
            Supervisor Report <span className="report-date-separator">•</span>
            <span className="report-date">{lastRefreshed}</span>
          </h4>
        </div>
        <div className="report-content">
          <pre>{report}</pre>
        </div>
      </div>
      {error && (
        <ErrorNotification
          error={error}
          fetchWorkflowInstances={fetchWorkflowInstances}
        />
      )}
    </div>
  );
}

export default Cards;
