import React, { createContext, useContext, useState, useEffect } from "react";
import { debounce } from "lodash";

const TooltipContext = createContext();

export const TooltipProvider = ({ children }) => {
  const [tooltips, setTooltips] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const [temporarilyShowAll, setTemporarilyShowAll] = useState(false);

  useEffect(() => {
    const savedTooltips = localStorage.getItem("tooltipPreferences");
    if (savedTooltips) {
      setTooltips(JSON.parse(savedTooltips));
    }
  }, []);

  const saveToLocalStorage = debounce((tooltipData) => {
    localStorage.setItem("tooltipPreferences", JSON.stringify(tooltipData));
  }, 1000);

  const initializeTooltipsForNewUser = () => {
    setIsNewUser(true);
    setTooltips({});
    localStorage.setItem("tooltipPreferences", JSON.stringify({}));
  };

  const shouldShowTooltips = (pageName) => {
    if (temporarilyShowAll) {
      return true;
    }

    if (isNewUser && tooltips[pageName] === undefined) {
      return true;
    }
    return tooltips[pageName] !== false;
  };

  const closeTooltipForever = (pageName) => {
    setTemporarilyShowAll(false);
    const updatedTooltips = {
      ...tooltips,
      [pageName]: false,
    };
    setTooltips(updatedTooltips);
    saveToLocalStorage(updatedTooltips);
  };

  const resetTooltips = (pageName) => {
    if (!pageName.includes("/workflow/")) {
      setTemporarilyShowAll(true);
    }
  };

  return (
    <TooltipContext.Provider
      value={{
        tooltips,
        closeTooltipForever,
        shouldShowTooltips,
        initializeTooltipsForNewUser,
        resetTooltips,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
};

export const useTooltip = () => useContext(TooltipContext);
