import React, { useContext, useEffect } from "react";
import Calendar from "react-calendar";
import FilterDropdown from "./FilterDropdown.js";
import { formatDateRange } from "../utils/formatUtils.js";
import { EnvironmentContext } from "../contexts/EnvironmentContext.js";

function FilterRow({
  toggleFilterOptions,
  handleStatusFilter,
  setFilters,
  openDropdown,
  handleSearch,
  handleDateFilter,
  showCalendar,
  calendarRef,
  handleChange,
  selectedDateRange,
  submitButtonRef,
  filters,
  setShowCalendar,
}) {
  const { searchInputRef } = useContext(EnvironmentContext);

  // Set initial search value from filters
  useEffect(() => {
    if (searchInputRef.current && filters.name) {
      searchInputRef.current.value = filters.name;
    }
  }, [filters.name]);

  const statusOptions = [
    { value: "failed", label: "Failed" },
    { value: "warning", label: "Warning" },
    { value: "completed", label: "Success" },
    { value: "created", label: "No Status" },
    { value: "waiting", label: "Waiting" },
  ];

  const getStatusDisplayValue = (status) => {
    if (!status) return "Last Status";
    const option = statusOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const renderStatusDropdown = () => (
    <div>
      {statusOptions.map(({ value, label }) => (
        <div
          key={value}
          className="dropdown-option"
          onClick={() => handleStatusFilter(value)}
        >
          {label}
        </div>
      ))}
    </div>
  );

  const renderDateDropdown = () => (
    <div>
      {["Last 7 Days", "Last 30 Days", "Custom date range"].map((option) => (
        <div
          key={option}
          className="dropdown-option"
          onClick={() =>
            handleDateFilter(option === "Custom date range" ? "Custom" : option)
          }
        >
          {option}
        </div>
      ))}
    </div>
  );

  return (
    <div className="App-filter-row">
      <FilterDropdown
        type="status"
        displayValue={getStatusDisplayValue(filters.status)}
        isActive={filters.status}
        onClear={() => setFilters((prev) => ({ ...prev, status: null }))}
        toggleFilterOptions={toggleFilterOptions}
        openDropdown={openDropdown}
      >
        {renderStatusDropdown()}
      </FilterDropdown>
      <FilterDropdown
        type="lastRun"
        displayValue={
          !filters.lastRun
            ? "Last Run"
            : typeof filters.lastRun === "string"
            ? filters.lastRun
            : filters.lastRun?.display || formatDateRange(filters.lastRun)
        }
        isActive={filters.lastRun}
        onClear={() => setFilters((prev) => ({ ...prev, lastRun: null }))}
        toggleFilterOptions={toggleFilterOptions}
        openDropdown={openDropdown}
      >
        {renderDateDropdown()}
      </FilterDropdown>
      <div className="search-container">
        <input
          type="text"
          placeholder=" Search..."
          ref={searchInputRef}
          id="workflow-search"
          name="workflow-search"
          defaultValue={filters.name || ""}
          onChange={(e) => {
            const searchTerm = e.target.value.trim();
            handleSearch(searchTerm);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              const searchTerm = e.target.value.trim();
              handleSearch(searchTerm);
            }
          }}
          className="styled-search-input"
        />
        {searchInputRef.current?.value && (
          <button
            className="App-search-submit"
            onClick={() => {
              searchInputRef.current.value = "";
              handleSearch("");
            }}
            id="search-submit"
            name="search-submit"
            aria-label="Clear search"
          >
            ×
          </button>
        )}
      </div>
      {showCalendar && (
        <div ref={calendarRef} className="App-date-range-picker-main">
          <Calendar
            onChange={(value) => {
              if (Array.isArray(value) && value.length === 2) {
                const [start, end] = value;
                // Set consistent time parts for the dates
                const startDate = new Date(start);
                const endDate = new Date(end);
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 999);

                handleChange([startDate, endDate]);
                setFilters((prev) => ({
                  ...prev,
                  lastRun: {
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    display: formatDateRange(startDate, endDate),
                  },
                }));
                setShowCalendar(false);
              } else {
                // Add this else clause to handle clearing
                setFilters((prev) => ({ ...prev, lastRun: null }));
              }
            }}
            value={selectedDateRange}
            selectRange={true}
            className="custom-calendar-main custom-calendar"
          />
          <button
            ref={submitButtonRef}
            className="App-submit-date-range-main"
            onClick={() => setShowCalendar(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default FilterRow;
