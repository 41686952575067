/* eslint-disable */
import config from "../config.js";
import axios from "axios";

export const handleLogin = async ({
  user,
  config,
  navigate,
  getAccessTokenSilently,
  tooltipContext,
  workflowTooltipContext,
}) => {
  try {
    const token = await getAccessTokenSilently({
      audience: config.audience,
      prompt: "select_account login",
    });

    const domain = user.email.split("@")[1];

    try {
      const loginResponse = await axios.post(
        `${config.apiBaseUrl}/users/login`,
        {
          email: user.email,
          name: user.name || user.email.split("@")[0],
          ip_address: window.location.hostname,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-Domain": domain,
          },
        }
      );

      // Only initialize tooltips if this is actually a new user
      if (loginResponse.data.user_created) {
        tooltipContext.initializeTooltipsForNewUser();
        workflowTooltipContext.initializeTooltipsForNewUser();
      }

      return loginResponse.data;
    } catch (error) {
      console.error("Error during login or session logging:", error);

      // Handle CORS errors
      if (
        error.message.includes("Network Error") ||
        error.message.includes("CORS")
      ) {
        navigate("/error", {
          state: {
            title: "Connection Error",
            message:
              "Unable to connect to the server. This might be due to a CORS policy restriction or the server being unavailable.",
            contactInfo:
              "Please ensure you're connecting to the correct server. If the problem persists, contact team@qurrent.ai",
          },
        });
        throw error;
      }

      // Handle other API errors
      if (error.response) {
        if (error.response.status === 400) {
          navigate("/error", {
            state: {
              title: "Login Failed",
              message:
                "Bad request during login. This could be due to invalid credentials or missing information.",
              contactInfo: "If the problem persists, contact team@qurrent.ai",
            },
          });
        } else if (error.response.status === 403) {
          navigate("/error", {
            state: {
              title: "Unauthorized Email",
              message:
                "Your email was not authorized. Please use your work email.",
              contactInfo: "If the problem persists, contact team@qurrent.ai",
            },
          });
        } else if (error.response.status === 500) {
          navigate("/error", {
            state: {
              title: "Internal Server Error",
              message: "An internal server error occurred.",
              contactInfo: "If the problem persists, contact team@qurrent.ai",
            },
          });
        } else {
          navigate("/error", {
            state: {
              title: "Unknown Error",
              message: "An unknown error occurred.",
              contactInfo: "If the problem persists, contact team@qurrent.ai",
            },
          });
        }
      }
      throw error;
    }
  } catch (error) {
    console.error("Error during login process:", error);
    throw error;
  }
};

export const handleLogout = async ({ logout, apiUtils }) => {
  const sessionId = sessionStorage.getItem("session_id");
  const auth0Domain = config.domain;

  try {
    // Clear all application state first
    sessionStorage.clear();

    // Clear any application-specific cookies
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
    });

    // End backend session if exists
    if (sessionId) {
      try {
        await apiUtils.endSession(sessionId);
      } catch (error) {
        console.error("Error ending backend session:", error);
        // Continue with logout even if backend session end fails
      }
    }

    // Finally, logout from Auth0
    await logout({
      returnTo: window.location.origin,
      prompt: "select_account",
      federated: true,
      localOnly: false,
      end_session_endpoint: `https://${auth0Domain}/v2/logout?federated&returnTo=${encodeURIComponent(
        window.location.origin
      )}&client_id=${config.clientId}`,
    });
  } catch (error) {
    console.error("Error during logout:", error);
    window.location.href = window.location.origin;
  }
};
