import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { getStatusIcon } from "../utils/formatUtils.js";

const WorkflowRow = ({
  workflow,
  isLast,
  selectedRunId,
  childWorkflows,
  handleWorkflowClick,
  handleRunClick,
  toggleExpand,
  expandedRunIds,
  lastElementRef,
  handleSeeMoreClickError,
  handleWorkflowLinkClick,
  index,
  isFocused,
}) => {
  const shouldShowWarning =
    (workflow.status === "completed" ||
      workflow.status === "created" ||
      workflow.status === "waiting" ||
      workflow.status === "Success") &&
    workflow.errors &&
    Array.isArray(workflow.errors) &&
    workflow.errors.length > 0 &&
    workflow.errors[0] !== "N/A" &&
    workflow.errors[0] !== null;

  const isSelected = selectedRunId === workflow.id;

  const renderErrors = () => {
    if (workflow.status === "failed") {
      return <span className="light-opacity">N/A</span>;
    }

    if (!workflow.errors || !Array.isArray(workflow.errors)) {
      return <span className="light-opacity">N/A</span>;
    }

    if (
      workflow.errors.length === 0 ||
      workflow.errors[0] === "N/A" ||
      workflow.errors[0] === null
    ) {
      return <span className="light-opacity">N/A</span>;
    }

    return (
      <>
        {workflow.errors[0]
          ? workflow.errors[0].substring(0, 20) + "..."
          : "N/A"}
        <button
          onClick={() => handleSeeMoreClickError(workflow.errors)}
          className="see-more-button"
        >
          see more
        </button>
      </>
    );
  };

  return (
    <React.Fragment key={`${workflow.id}-${index}`}>
      <tr
        ref={isLast ? lastElementRef : null}
        onClick={(event) => {
          if (!event.defaultPrevented) {
            handleRunClick(event, workflow);
          }
        }}
        className={`${isSelected ? "selected-run" : ""} ${
          isFocused ? "focused-row" : ""
        }`}
        style={{ cursor: "pointer" }}
      >
        <td>
          {childWorkflows[workflow.id] && (
            <FontAwesomeIcon
              icon={
                expandedRunIds.has(workflow.id) ? faChevronDown : faChevronRight
              }
              className="dropdown-icon"
              onClick={(e) => {
                e.stopPropagation();
                toggleExpand(workflow.id);
              }}
            />
          )}
        </td>
        <td>
          {workflow.parent_workflow_instance_id && <span>┃</span>}
          {workflow.start_time}
        </td>
        <td>{workflow.end_time}</td>
        <td>
          {getStatusIcon(workflow.status, workflow.errors)}{" "}
          {shouldShowWarning
            ? "Warning"
            : workflow.status === "failed"
            ? "Failed"
            : workflow.status.charAt(0).toUpperCase() +
              workflow.status.slice(1)}
        </td>
        <td>{renderErrors()}</td>
        <td>{workflow.workflowVersion}</td>
        <td>
          {workflow.parent_workflow_instance_id &&
            childWorkflows[workflow.parent_workflow_instance_id]?.[0]?.name && (
              <span
                className="workflow-link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleWorkflowLinkClick(workflow);
                }}
              >
                {childWorkflows[workflow.parent_workflow_instance_id][0].name}
              </span>
            )}
        </td>
      </tr>
      {expandedRunIds.has(workflow.id) &&
        childWorkflows[workflow.id] &&
        childWorkflows[workflow.id].map((child, childIndex) => (
          <WorkflowRow
            key={`${child.id}-${childIndex}`}
            workflow={child}
            index={childIndex}
            isLast={false}
            selectedRunId={selectedRunId}
            childWorkflows={childWorkflows}
            handleWorkflowClick={handleWorkflowClick}
            handleRunClick={handleRunClick}
            toggleExpand={toggleExpand}
            expandedRunIds={expandedRunIds}
            lastElementRef={lastElementRef}
            handleSeeMoreClickError={handleSeeMoreClickError}
            handleWorkflowLinkClick={handleWorkflowLinkClick}
            isFocused={isFocused}
          />
        ))}
    </React.Fragment>
  );
};

export default WorkflowRow;
