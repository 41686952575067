import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import whiteLogo from "./assets/qurrent_logo_cropped.png";
import "./App.css";
import { useObservableView } from "./contexts/ObservableViewContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoon,
  faSignOutAlt,
  faChevronDown,
  faBug,
  faSun,
  faUser,
  faEnvelope,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useApiUtils } from "./utils/apiUtils.js";
import { handleLogout } from "./utils/authUtils.js";
import { Link, useLocation } from "react-router-dom";
import getConfig from "./config.js";
import { useUser } from "./contexts/UserContext.js";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";
import { useTooltip } from "./contexts/TooltipContext.js";
import { useWorkflowTooltip } from "./contexts/WorkflowTooltipContext.js";
import { ThemeContext } from "./contexts/ThemeContext.js";
import { ContactPopup } from "./ContactPopup.js";
import { trackOperation } from "./utils/memoryMonitor.js";
import { useFilter } from "./contexts/FilterContext.js";

const Header = React.memo(function Header({ isStreaming }) {
  const apiUtils = useApiUtils();

  const { logout, user } = useAuth0();
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  const { isObservableViewActive, toggleObservableView } = useObservableView();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const config = getConfig();

  const { workflowInstanceId } = useParams();
  useState(workflowInstanceId);
  const {
    setSelectedCustomerForImpersonation,
    selectedCustomerForImpersonation,
    isImpersonating,
    userIsQurrent,
    setImpersonatedCustomer,
    setIsImpersonating,
    customers,
  } = useUser();

  const { switchEnvironment, env } = useContext(EnvironmentContext);
  const location = useLocation();
  const navigate = useNavigate();

  const { resetTooltips: resetMainTooltips } = useTooltip();
  const { resetTooltips: resetWorkflowTooltips } = useWorkflowTooltip();

  const [isContactOpen, setIsContactOpen] = useState(false);

  const [dropdownStates, setDropdownStates] = useState({
    main: false,
    environment: false,
  });

  const [customerDropdownOpen, setCustomerDropdownOpen] = useState(false);
  const customerDropdownRef = useRef(null);
  const { setFilters } = useFilter();

  const handleClickOutside = useCallback((event) => {
    if (!event.target.closest(".App-dropdown")) {
      setDropdownStates({ main: false, environment: false });
    }
  }, []);

  useEffect(() => {
    if (dropdownStates.main || dropdownStates.environment) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [dropdownStates, handleClickOutside]);

  useEffect(() => {
    if (!userIsQurrent && isObservableViewActive) {
      setTimeout(() => {
        toggleObservableView();
      }, 0);
    }
  }, [userIsQurrent, isObservableViewActive, toggleObservableView]);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", darkMode);
    document.documentElement.classList.toggle("dark-mode", darkMode);
    document.body.classList.toggle("light-mode", !darkMode);
    document.documentElement.classList.toggle("light-mode", !darkMode);
  }, [darkMode]);

  useEffect(() => {
    setSelectedEnvironment(env);
  }, [env]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        customerDropdownRef.current &&
        !customerDropdownRef.current.contains(e.target)
      ) {
        setCustomerDropdownOpen(false);
      }
    };

    if (customerDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [customerDropdownOpen]);

  const handleLogoutClick = async () => {
    const memoryTracker = trackOperation("logout");
    try {
      await handleLogout({
        logout,
        apiUtils,
      });
    } finally {
      memoryTracker.end();
    }
  };

  const toggleDropdown = useCallback((e) => {
    e.stopPropagation();
    setDropdownOpen((prev) => !prev);
  }, []);

  const selectEnvironment = async (environment) => {
    let environmentIcon;
    let newEnv;
    if (environment === "Production") {
      environmentIcon = "P";
      newEnv = "prod";
    } else if (environment === "Staging") {
      environmentIcon = "S";
      newEnv = "staging";
    } else {
      environmentIcon = "D";
      newEnv = "dev";
    }

    try {
      // Ensure Qurrent user exists in the new environment before switching
      await apiUtils.ensureUserInEnvironment();

      setSelectedEnvironment(newEnv);
      switchEnvironment(newEnv);

      // Update the URL to include the environment prefix
      const currentPath = location.pathname.replace(/\/(dev|staging)/, "");
      const newPath =
        newEnv === "prod" ? currentPath : `/${newEnv}${currentPath}`;
      navigate(newPath, { replace: true });

      setSelectedCustomerForImpersonation(null);
      setImpersonatedCustomer(null);
      setIsImpersonating(false);
      setFilters((prev) => ({
        ...prev,
        customer: null,
        impersonatedCustomer: null,
      }));

      sessionStorage.setItem("environmentIcon", environmentIcon);
      sessionStorage.setItem("customerViewChanged", "true");
      setDropdownOpen(false);
    } catch (error) {
      console.error("Error switching environments:", error);
      // Handle error appropriately
    }
  };

  const handleShowTooltipsClick = () => {
    const currentPath = location.pathname;
    if (currentPath.includes("/workflow/")) {
      resetWorkflowTooltips(currentPath);
    } else {
      resetMainTooltips(currentPath);
    }
    setDropdownOpen(false);
  };

  const handleCustomerSelect = useCallback(
    (customer) => {
      setSelectedCustomerForImpersonation(customer);
      setFilters((prev) => ({
        ...prev,
        customer: customer.name,
      }));
      setCustomerDropdownOpen(false);
      setDropdownOpen(false);
      sessionStorage.setItem("customerViewChanged", "true");
    },
    [setSelectedCustomerForImpersonation, setFilters]
  );

  const handleViewAsCustomer = useCallback(() => {
    if (selectedCustomerForImpersonation) {
      setImpersonatedCustomer(selectedCustomerForImpersonation);
      setIsImpersonating(true);
      setFilters((prev) => ({
        ...prev,
        customer: selectedCustomerForImpersonation.name,
        impersonatedCustomer: selectedCustomerForImpersonation,
      }));
      sessionStorage.setItem("customerViewChanged", "true");
    }
    setDropdownOpen(false);
  }, [
    selectedCustomerForImpersonation,
    setImpersonatedCustomer,
    setIsImpersonating,
    setFilters,
  ]);

  const handleViewAsQurrent = useCallback(() => {
    setImpersonatedCustomer(null);
    setIsImpersonating(false);
    setSelectedCustomerForImpersonation(null);
    setFilters((prev) => ({
      ...prev,
      customer: null,
      impersonatedCustomer: null,
    }));
    sessionStorage.setItem("customerViewChanged", "true");
    setDropdownOpen(false);
  }, [
    setImpersonatedCustomer,
    setIsImpersonating,
    setSelectedCustomerForImpersonation,
    setFilters,
  ]);

  useEffect(() => {
    let mounted = true;

    const handleClickOutside = (event) => {
      if (mounted && !event.target.closest(".App-dropdown")) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      mounted = false;
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdownOpen]);

  const isWorkflowPage = location.pathname.includes("/workflow/");

  return (
    <header className="App-header">
      <div className="App-header-left">
        <Link
          to={`${
            env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""
          }/`}
        >
          <img
            src={whiteLogo}
            className="App-logo"
            alt="logo"
            width="150"
            height="38"
            loading="eager"
            decoding="async"
            fetchpriority="high"
          />
        </Link>
        <Link
          to={`${
            env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""
          }/`}
          className={`metrics-link ${
            location.pathname === "/" ||
            location.pathname === "/dev/" ||
            location.pathname === "/staging/"
              ? "active"
              : ""
          }`}
        >
          <div className="metrics-text" style={{ marginLeft: "5px" }}>
            Overview
          </div>
        </Link>
        <Link
          to={`${
            env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""
          }/metrics`}
          className={`metrics-link ${
            location.pathname.includes("/metrics") ||
            location.pathname.includes("/dev/metrics") ||
            location.pathname.includes("/staging/metrics")
              ? "active"
              : ""
          }`}
        >
          <div className="metrics-text">Metrics</div>
        </Link>
        <Link
          to={`${
            env === "dev" ? "/dev" : env === "staging" ? "/staging" : ""
          }/feedback`}
          className={`metrics-link ${
            location.pathname.includes("/feedback") ||
            location.pathname.includes("/dev/feedback") ||
            location.pathname.includes("/staging/feedback")
              ? "active"
              : ""
          }`}
        >
          <div className="metrics-text">Feedback</div>
        </Link>
      </div>
      {user && (
        <div className="App-header-right">
          <div className="App-account">
            <div className="App-dropdown">
              <div className="App-username-container" onClick={toggleDropdown}>
                <span className="App-username">
                  {userIsQurrent &&
                    isObservableViewActive &&
                    !isImpersonating && (
                      <FontAwesomeIcon
                        icon={faBug}
                        className="debug-icon-header"
                      />
                    )}
                  {userIsQurrent && selectedEnvironment && (
                    <div>
                      <span className="environment-icon-text">
                        {selectedEnvironment.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                  {userIsQurrent && isImpersonating && (
                    <FontAwesomeIcon
                      icon={faUser}
                      className="user-icon-header"
                    />
                  )}
                  {isImpersonating
                    ? selectedCustomerForImpersonation?.name
                    : user
                    ? user.given_name && user.family_name
                      ? `${user.given_name} ${user.family_name}`
                      : user.name || user.email
                    : "Username"}{" "}
                </span>
                <button className="App-dropdown-button">
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`small-chevron ${dropdownOpen ? "rotated" : ""}`}
                  />
                </button>
              </div>
              {dropdownOpen && (
                <div className="App-dropdown-content">
                  {!isWorkflowPage && (
                    <>
                      <div className="environment-options">
                        <button
                          onClick={() => selectEnvironment("Production")}
                          className={`environment-dropdown-button-option ${
                            selectedEnvironment === "prod"
                              ? "environment-highlight"
                              : ""
                          }`}
                          disabled={isStreaming}
                          style={{ opacity: isStreaming ? 0.5 : 1 }}
                        >
                          <div
                            className={`environment-icon-text-list ${
                              selectedEnvironment === "prod"
                                ? "environment-highlight"
                                : ""
                            }`}
                          >
                            <span>P</span>
                          </div>
                          <div className="environment-text-header-list">
                            Production
                          </div>
                        </button>
                        <button
                          onClick={() => selectEnvironment("Staging")}
                          className={`environment-dropdown-button-option ${
                            selectedEnvironment === "staging"
                              ? "environment-highlight"
                              : ""
                          }`}
                          disabled={isStreaming}
                          style={{ opacity: isStreaming ? 0.5 : 1 }}
                        >
                          <div
                            className={`environment-icon-text-list ${
                              selectedEnvironment === "staging"
                                ? "environment-highlight"
                                : ""
                            }`}
                          >
                            <span>S</span>
                          </div>
                          <div className="environment-text-header-list">
                            Staging
                          </div>
                        </button>
                        {userIsQurrent && !isImpersonating && (
                          <button
                            onClick={() => selectEnvironment("Development")}
                            className={`environment-dropdown-button-option ${
                              selectedEnvironment === "dev"
                                ? "environment-highlight"
                                : ""
                            }`}
                            disabled={isStreaming}
                            style={{ opacity: isStreaming ? 0.5 : 1 }}
                          >
                            <div
                              className={`environment-icon-text-list ${
                                selectedEnvironment === "dev"
                                  ? "environment-highlight"
                                  : ""
                              }`}
                            >
                              <span>D</span>
                            </div>
                            <div className="environment-text-header-list">
                              Development
                            </div>
                          </button>
                        )}
                      </div>
                      {userIsQurrent && (
                        <>
                          {isImpersonating ? (
                            <button
                              onClick={handleViewAsQurrent}
                              className="customer-view-button"
                            >
                              <FontAwesomeIcon
                                icon={faUser}
                                className="customer-view-icon"
                              />
                              View as Qurrent
                            </button>
                          ) : (
                            <div className="customer-selection-row ">
                              <div
                                className="customer-select-container"
                                ref={customerDropdownRef}
                              >
                                <button
                                  onClick={() =>
                                    setCustomerDropdownOpen(
                                      !customerDropdownOpen
                                    )
                                  }
                                  className="customer-view-button"
                                  disabled={isStreaming}
                                  style={{ opacity: isStreaming ? 0.5 : 1 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    className="customer-view-icon"
                                  />
                                  {selectedCustomerForImpersonation?.name ||
                                    "Select Customer"}
                                </button>
                                {customerDropdownOpen && (
                                  <div className="customer-dropdown-menu">
                                    <div
                                      className="customer-option dropdown-option"
                                      onClick={() => {
                                        setSelectedCustomerForImpersonation(
                                          null
                                        );
                                        setFilters((prev) => ({
                                          ...prev,
                                          customer: null,
                                        }));
                                        setCustomerDropdownOpen(false);
                                      }}
                                    >
                                      All Customers
                                    </div>
                                    {customers?.map((customer) => (
                                      <div
                                        key={customer.id}
                                        className="customer-option dropdown-option"
                                        onClick={() =>
                                          handleCustomerSelect(customer)
                                        }
                                      >
                                        {customer.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              {selectedCustomerForImpersonation && (
                                <button
                                  onClick={handleViewAsCustomer}
                                  className="customer-view-button"
                                >
                                  View as Customer
                                </button>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {userIsQurrent &&
                    !isImpersonating &&
                    (location.pathname.includes("/workflow/") ||
                      location.pathname.includes("/feedback")) && (
                      <button
                        onClick={() => {
                          toggleObservableView();
                          setDropdownOpen(false);
                        }}
                        className={`dark-mode-button ${
                          isObservableViewActive ? "active" : ""
                        }`}
                      >
                        <div className="dark-mode-icon">
                          <FontAwesomeIcon icon={faBug} />
                        </div>
                        {isObservableViewActive
                          ? "Disable Debug Mode"
                          : "Enable Debug Mode"}
                      </button>
                    )}
                  {!location.pathname.includes("/metrics") &&
                    !location.pathname.includes("/feedback") && (
                      <button
                        className="tooltip-tutorial-button"
                        onClick={handleShowTooltipsClick}
                      >
                        <div className="magnifying-glass-icon">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </div>
                        Show Tooltips
                      </button>
                    )}
                  <button
                    onClick={toggleDarkMode}
                    className={`dark-mode-button ${darkMode ? "active" : ""}`}
                  >
                    <div className="dark-mode-icon">
                      <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                    </div>
                    {darkMode ? "Toggle Light Mode" : "Toggle Dark Mode"}
                  </button>
                  <button
                    onClick={() => setIsContactOpen(true)}
                    className={`logout-button`}
                  >
                    <div className="logout-icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    Contact Qurrent
                  </button>
                  <button
                    onClick={handleLogoutClick}
                    className={`logout-button`}
                  >
                    <div className="logout-icon">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </div>
                    Logout
                  </button>
                  <div className="version-text">
                    {}v{config.version || "ersion not found"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ContactPopup
        isOpen={isContactOpen}
        onClose={() => setIsContactOpen(false)}
      />
    </header>
  );
});

export default Header;
