import React from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const TooltipWorkflows = ({ handleCloseTooltip }) => {
  return (
    <div className="tooltip-container">
      <div
        className="tooltip-tutorial tooltip-0-workflows"
        style={{ whiteSpace: "pre-wrap" }}
      >
        The 'stack' shows the list of tasks performed by agents
      </div>
      <div
        className="tooltip-tutorial tooltip-1-workflows"
        style={{ whiteSpace: "pre-wrap" }}
      >
        Diagram of the workflow run
      </div>
      <div
        className="tooltip-tutorial tooltip-2-workflows"
        style={{ whiteSpace: "pre-wrap" }}
      >
        List of 'runs' for the selected workflow
      </div>
      <div
        className="tooltip-tutorial tooltip-3-workflows"
        style={{ whiteSpace: "pre-wrap" }}
      >
        <span>
          This drawer shows the details of the selected run.
          <br />
          <br />
          Click on <strong>Needs Review</strong> to add this run to your
          feedback list.
          <br />
          <br />
          Select the <strong>Feedback</strong> tab to add feedback now.
        </span>
      </div>
      <button
        className="close-tooltip-button-workflows"
        onClick={handleCloseTooltip}
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
};

export default TooltipWorkflows;
