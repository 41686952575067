import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiUtils } from "../utils/apiUtils.js";
import { handleLogout } from "../utils/authUtils.js";

const AutoLogout = () => {
  const { logout, getAccessTokenSilently, isAuthenticated, error } = useAuth0();
  const apiUtils = useApiUtils();
  const INACTIVITY_LIMIT = 14400000; // 4 hours in milliseconds
  // const INACTIVITY_LIMIT = 600000; // 10 minutes in milliseconds
  const INACTIVITY_WARNING = 5000; // 5 seconds in milliseconds

  const [showWarning, setShowWarning] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(INACTIVITY_WARNING / 1000);

  useEffect(() => {
    if (error || !isAuthenticated) return;

    let timeoutId;
    let warningTimeoutId;
    let countdownIntervalId;
    let tabVisible = true;

    const resetTimeout = () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (warningTimeoutId) clearTimeout(warningTimeoutId);
      if (countdownIntervalId) clearInterval(countdownIntervalId);

      warningTimeoutId = setTimeout(() => {
        setShowWarning(true);
        setTimeRemaining(INACTIVITY_WARNING / 1000);

        countdownIntervalId = setInterval(() => {
          setTimeRemaining((prev) => {
            if (prev <= 1) {
              clearInterval(countdownIntervalId);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }, INACTIVITY_LIMIT - INACTIVITY_WARNING);

      timeoutId = setTimeout(() => {
        handleLogout({ logout, getAccessTokenSilently, apiUtils });
      }, INACTIVITY_LIMIT);
    };

    const handleActivity = () => {
      if (!document.hidden) {
        setShowWarning(false);
        resetTimeout();
      }
    };

    const handleVisibilityChange = () => {
      tabVisible = !document.hidden;
      if (tabVisible) {
        handleActivity();
      }
    };

    // Remove beforeunload event handler if it exists
    window.onbeforeunload = null;

    const activityEvents = [
      "mousemove",
      "mousedown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    activityEvents.forEach((event) =>
      window.addEventListener(event, handleActivity)
    );

    document.addEventListener("visibilitychange", handleVisibilityChange);
    resetTimeout();

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(warningTimeoutId);
      clearInterval(countdownIntervalId);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.onbeforeunload = null;
    };
  }, [logout]);

  if (error) return null;

  return (
    <>
      {showWarning && (
        <div className="inactivity-warning">Logging out due to inactivity.</div>
      )}
    </>
  );
};

export default AutoLogout;
