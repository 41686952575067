import React, { createContext, useState, useContext, useEffect } from "react";

const FilterContext = createContext();

export const useFilter = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => {
    const savedFilters = sessionStorage.getItem("qurrent-filters");
    return savedFilters ? JSON.parse(savedFilters) : {};
  });

  useEffect(() => {
    sessionStorage.setItem("qurrent-filters", JSON.stringify(filters));
  }, [filters]);

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
