// ObservableNodePopup.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faClone } from "@fortawesome/free-solid-svg-icons";
import { formatId, formatDateTime } from "../utils/formatUtils.js";
import { useObservableView } from "../contexts/ObservableViewContext.js";

const ObservableNodePopup = ({
  node,
  handleClosePopup,
  copied,
  handleIdClick,
  observableSummary,
  loadingSummary,
}) => {
  const { isObservableViewActive } = useObservableView();
  const nodeData = node.data?.item || node;
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header-section">
          <div className="main-row">
            <FontAwesomeIcon className="node-icon-popup" icon={faPenToSquare} />
            <div className="title-row">
              <div className="title-header">
                {nodeData.function_name || "Observable"}
              </div>
              <button
                className="close-button-right-drawer"
                onClick={handleClosePopup}
              >
                &times;
              </button>
            </div>
          </div>
          <div className="description-text-content">
            {nodeData.description || "No description available"}
          </div>
          <hr className="divider-popup" />
          {Object.entries(nodeData)
            .filter(([key]) =>
              isObservableViewActive
                ? ["id", "created_at"].includes(key)
                : ["created_at"].includes(key)
            )
            .map(([key, value]) => {
              const isIdField = key.toLowerCase() === "id";
              const displayValue =
                isIdField && value !== "None"
                  ? formatId(value)
                  : value === null || value === ""
                  ? "N/A"
                  : value;

              return (
                <div key={key}>
                  <p className="key-value-pair">
                    <strong>
                      {key === "created_at"
                        ? "START"
                        : key === "model_name"
                        ? "MODEL"
                        : key.toUpperCase()}
                    </strong>
                    <span
                      className={
                        isIdField && value !== "None" && displayValue !== "N/A"
                          ? "id-tooltip"
                          : ""
                      }
                      title={
                        isIdField && value !== "None" && displayValue !== "N/A"
                          ? value
                          : ""
                      }
                      onClick={() => {
                        if (
                          isIdField &&
                          value !== "None" &&
                          displayValue !== "N/A"
                        ) {
                          handleIdClick(value);
                        }
                      }}
                      style={{ cursor: isIdField ? "pointer" : "default" }}
                    >
                      {isIdField &&
                        value !== "None" &&
                        displayValue !== "N/A" && (
                          <span
                            onClick={() => handleIdClick(value)}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              position: "relative",
                            }}
                          >
                            {copied && (
                              <span className="copied-style">Copied!</span>
                            )}
                            <FontAwesomeIcon
                              className="copy-icon"
                              icon={faClone}
                            />
                          </span>
                        )}
                      {key === "created_at"
                        ? formatDateTime(displayValue)
                        : typeof displayValue === "object"
                        ? JSON.stringify(displayValue, null, 2)
                        : displayValue}
                    </span>
                  </p>
                </div>
              );
            })}
          <hr className="divider-popup" />
        </div>

        <div className="popup-scrollable-content">
          <strong className="description-content">TASK SUMMARY</strong>
          <div className="formatted-content">
            <p>
              {loadingSummary
                ? "Loading summary..."
                : observableSummary
                ? observableSummary
                : "Loading summary..."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObservableNodePopup;
