import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { getSortIcon, formatRelativeDateTime } from "../utils/formatUtils.js";
import { useTooltip } from "../contexts/TooltipContext.js";

const MainTable = ({
  workflows,
  handleSortClick,
  handleRowClick,
  showPreviousVersions,
  handleCheckboxChange,
  showTooltip,
}) => {
  const { closeTooltipForever } = useTooltip();
  const pageName = "MainContent";

  const handleCloseTooltip = () => {
    closeTooltipForever(pageName);
  };

  return (
    <div className="App-table">
      {showTooltip && (
        <div
          className="tooltip-tutorial tooltip-2"
          style={{ whiteSpace: "pre-wrap" }}
        >
          Your AI Workforce
          <br />
          Select a row to see details of the runs
          <button
            className="close-tooltip-button-main"
            onClick={handleCloseTooltip}
            id="close-tooltip-btn"
            name="close-tooltip"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
      <div className="App-table-header">
        <div className="App-table-row">
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("latest_status")}
          >
            Last Status {getSortIcon("latest_status")}
          </div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("runs")}
          >
            Runs {getSortIcon("runs")}
          </div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("failed")}
          >
            Failed {getSortIcon("failed")}
          </div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("workflow_name")}
          >
            Name {getSortIcon("workflow_name")}
          </div>
          <div className="App-table-cell">Version</div>
          <div className="App-table-cell">
            State{" "}
            <input
              type="checkbox"
              checked={!!showPreviousVersions}
              onChange={handleCheckboxChange}
              className="tiny-checkbox"
              id="show-archived-workflows"
              name="showPreviousVersions"
              aria-label="Show Archived Workflows"
            />
          </div>
          <div className="App-table-cell description-header">Description</div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("lastRun")}
          >
            Last Run {getSortIcon("lastRun")}
          </div>
        </div>
      </div>
      <div className="App-table-body scrollable">
        {workflows.map((item, index) => (
          <div
            className={`App-table-row grid-row ${
              item.is_previous_version ? "previous-version" : ""
            } ${item.status === "failed" ? "failed-row" : ""} ${
              item.warnings ? "warning-row" : ""
            }`}
            key={`${item.id}-${index}`}
            onClick={() => handleRowClick(item)}
            role="row"
            tabIndex="0"
          >
            <div className="App-table-cell">
              {item.status === "failed" ? (
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="status-icon-failed"
                  aria-label="Failed status"
                />
              ) : item.warnings ? (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="status-icon-warning"
                  aria-label="Warning status"
                />
              ) : item.status === "completed" ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="status-icon-success"
                  aria-label="Completed status"
                />
              ) : item.status === "created" ? (
                <span
                  className="status-icon created"
                  aria-label="Created status"
                >
                  —
                </span>
              ) : item.status === "waiting" ? (
                <FontAwesomeIcon
                  icon={faClock}
                  className="status-icon-waiting"
                  aria-label="Waiting status"
                />
              ) : null}
            </div>
            <div className="App-table-cell">{item.runs}</div>
            <div className="App-table-cell">{item.failed}</div>
            <div className="App-table-cell">
              <span className="tooltip-container-name">
                {item.name}
                <span className="tooltip-text-name">{item.name}</span>
              </span>
            </div>
            <div className="App-table-cell">{item.version}</div>
            <div className="App-table-cell">
              {item.state
                ? item.state.charAt(0).toUpperCase() + item.state.slice(1)
                : "N/A"}
            </div>
            <div className="App-table-cell">
              <div className="tooltip-container-description">
                {item.description || "No description available"}
                <span className="tooltip-text-description">
                  {item.description || "No description available"}
                </span>
              </div>
            </div>
            <div className="App-table-cell">
              <div className="tooltip-container-date">
                {formatRelativeDateTime(item.last_run).displayText}
                <span className="tooltip-text-date">
                  {formatRelativeDateTime(item.last_run).fullFormattedDate}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainTable;
