import React, { createContext, useContext, useState, useEffect } from "react";
import { useUser } from "./UserContext.js";

// Create a context for Observable View
const ObservableViewContext = createContext();

// Create a provider component
export const ObservableViewProvider = ({ children }) => {
  const [isObservableViewActive, setIsObservableViewActive] = useState(false);
  const { isImpersonating } = useUser();

  // Turn off debug mode when impersonating
  useEffect(() => {
    if (isImpersonating && isObservableViewActive) {
      setIsObservableViewActive(false);
      sessionStorage.setItem("observableViewMode", "false");
    }
  }, [isImpersonating, isObservableViewActive]);

  const toggleObservableView = () => {
    // Only allow toggle if not impersonating
    if (!isImpersonating) {
      setIsObservableViewActive((prev) => {
        const newState = !prev;
        sessionStorage.setItem("observableViewMode", JSON.stringify(newState));
        return newState;
      });
    }
  };

  return (
    <ObservableViewContext.Provider
      value={{ isObservableViewActive, toggleObservableView }}
    >
      {children}
    </ObservableViewContext.Provider>
  );
};

// Custom hook to use the Observable View context
export const useObservableView = () => {
  return useContext(ObservableViewContext);
};
