import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faCircleXmark,
  faExclamationTriangle,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import runningIcon from "../assets/spinner_icon.png";

export const formatDateTime = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString + "Z"); // Adding 'Z' to indicate UTC
  if (isNaN(date.getTime())) return "N/A";

  return date.toLocaleDateString(navigator.language || "en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
};

export const formatRelativeDateTime = (dateString) => {
  if (!dateString) return { displayText: "N/A", fullFormattedDate: "N/A" };

  const date = new Date(dateString);
  const now = new Date();
  const locale = navigator.language || "en-US";

  // Convert both dates to UTC to avoid timezone issues
  const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utcNow = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());

  // Calculate the difference in days
  const diffDays = Math.floor((utcNow - utcDate) / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.floor(diffDays / 7);

  let displayText;
  if (diffDays === 0) {
    displayText = `Today at ${date.toLocaleTimeString(locale, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })}`;
  } else if (diffDays < 7) {
    displayText = `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  } else if (diffWeeks < 4) {
    displayText = `${diffWeeks} week${diffWeeks > 1 ? "s" : ""} ago`;
  } else {
    displayText = date.toLocaleString(locale, {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  const fullFormattedDate = date.toLocaleString(locale, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return { displayText, fullFormattedDate };
};

export const formatDateRange = (startDate, endDate) => {
  const dateFormatter = new Intl.DateTimeFormat(navigator.language);
  const formattedStart = dateFormatter.format(startDate);
  const formattedEnd = dateFormatter.format(endDate);
  return `${formattedStart} - ${formattedEnd}`;
};

export const setDateTimes = (startDate, endDate) => {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  return [startDate, endDate];
};

export const formatIntervalDate = (dateString, interval) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  const locale = navigator.language;

  switch (interval) {
    case "hour":
      return `${date.getHours() % 12 || 12}:00 ${
        date.getHours() >= 12 ? "PM" : "AM"
      }`;
    case "week": {
      const endDate = new Date(date);
      endDate.setDate(date.getDate() + 6);
      return `${date.toLocaleDateString(locale)}`;
    }
    case "month":
      return date.toLocaleString(locale, { month: "short", year: "numeric" });
    case "year":
      return date.getFullYear().toString();
    default:
      return date.toLocaleDateString(locale);
  }
};

export const formatIntervalTooltip = (dateString, interval) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  const locale = navigator.language;

  switch (interval) {
    case "hour":
      return `${date.toLocaleDateString(locale)} ${
        date.getHours() % 12 || 12
      }:00 ${date.getHours() >= 12 ? "PM" : "AM"}`;
    case "week": {
      const endDate = new Date(date);
      endDate.setDate(date.getDate() + 6);
      return `${date.toLocaleDateString(locale)} - ${endDate.toLocaleDateString(
        locale
      )}`;
    }
    default:
      return formatIntervalDate(dateString, interval);
  }
};

export const mapStatus = (backendStatus) => {
  switch (backendStatus) {
    case "created":
      return "No Status";
    case "failed":
      return "Failed";
    case "completed":
      return "Success";
    case "waiting":
      return "Waiting for Input";
    default:
      return backendStatus;
  }
};

export const formatContent = (content) => {
  if (typeof content === "string") {
    try {
      const parsedContent = JSON.parse(content);
      return formatObject(parsedContent);
    } catch (e) {
      return content.replace(/\\n/g, "\n");
    }
  }

  if (Array.isArray(content)) {
    return content.map((item) => formatObject(item)).join("\n\n");
  }

  if (typeof content === "object" && content !== null) {
    return formatObject(content);
  }

  return content;
};

export const formatOutput = (text) => {
  const imageRegex = /__IMAGE_DATA_START__(.*?)__IMAGE_DATA_END__/s;
  const match = text.match(imageRegex);

  if (match) {
    const beforeImage = text.substring(0, match.index);
    const afterImage = text.substring(match.index + match[0].length);
    const imageData = match[1];

    return {
      beforeText: beforeImage,
      image: imageData,
      afterText: afterImage,
    };
  }

  return { beforeText: text };
};

export const getSortIcon = (column, spinningColumn) => {
  return (
    <FontAwesomeIcon
      icon={faSort}
      className={`sort-icon ${
        spinningColumn === column ? "spinning-sort" : ""
      }`}
    />
  );
};

export const formatObject = (obj, indent = 0) => {
  const indentation = " ".repeat(indent);
  return Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return `${indentation}${key}:\n${formatObject(value, indent + 2)}`;
      }
      return `${indentation}${key}: ${value}`;
    })
    .join("\n");
};

export const formatId = (id) => {
  if (!id || id === "None") return "N/A";
  const displayId = id.slice(-5);
  return `...${displayId}`;
};

export const formatName = (name) => {
  if (!name) return "";
  return name
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getStatusIcon = (status, errors) => {
  // Check if status is failed first
  if (status && status.toLowerCase() === "failed") {
    return (
      <FontAwesomeIcon icon={faCircleXmark} className="status-icon-failed" />
    );
  }

  // Check if error is a non-empty array with meaningful content
  const hasError =
    Array.isArray(errors) &&
    errors.length > 0 &&
    errors[0] !== "N/A" &&
    errors[0] !== null;

  if (hasError) {
    return (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className="status-icon-error"
      />
    );
  }

  if (!status) {
    return null; // Return null or a default icon if status is undefined
  }

  switch (status.toLowerCase()) {
    case "success":
      return (
        <FontAwesomeIcon icon={faCircleCheck} className="status-icon-success" />
      );
    case "running":
      return <img src={runningIcon} alt="Running" className="status-icon" />;
    default:
      return null;
  }
};

export const getDomainFromEmail = (email) => {
  return email.split("@")[1];
};

export function formatMetricValue(value, metricFormat) {
  if (!metricFormat) {
    return value.toString();
  }

  let decimalPlaces = 0;
  let useThousandsSeparator = false;

  // Parse the format string
  if (metricFormat.includes(".")) {
    // Count decimal places
    decimalPlaces = metricFormat.split(".")[1].length;
  }

  if (metricFormat.includes(",") || metricFormat.includes("#")) {
    useThousandsSeparator = true;
  }

  // Format the number
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    useGrouping: useThousandsSeparator,
  }).format(value);
}

export const capitalizeWords = (str) => {
  if (!str) return "";
  return str
    .replace(/_/g, " ") // Replace underscores with spaces
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const formatTableValue = (value, format) => {
  if (value === null || value === undefined) return "N/A";

  // Handle specific formats
  if (format === "0.0") {
    return Number(value).toFixed(1);
  } else if (format === "0.00") {
    return Number(value).toFixed(2);
  } else if (format === "0") {
    return Math.round(value).toString();
  } else if (format === "percentage") {
    return `${(value * 100).toFixed(1)}%`;
  } else if (format === "currency") {
    return `$${Number(value).toFixed(2)}`;
  }

  // Use the existing formatMetricValue as fallback
  return formatMetricValue(value, format);
};
