import React, { useState, useRef, useEffect, useContext } from "react";
import Header from "./Header.js";
import { getSortIcon } from "./utils/formatUtils.js";
import FeedbackFilterRow from "./feedback_components/FeedbackFilterRow.js";
import EmptyDrawerMessagePopup from "./workflow_components/EmptyDrawerMessagePopup.js";
import Pagination from "./main_components/Pagination.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useFeedback } from "./contexts/FeedbackContext.js";
import { formatDateTime } from "./utils/formatUtils.js";
import { useUser } from "./contexts/UserContext.js";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";
import { useFeedbackFilter } from "./contexts/FeedbackFilterContext.js";
import { useParams, useNavigate } from "react-router-dom";
const ITEMS_PER_PAGE = 25;

const Feedback = () => {
  const {
    workflowFeedback,
    setWorkflowFeedback,
    fetchAllFeedback,
    fetchFeedbackForWorkflow,
    clearFeedback,
  } = useFeedback();
  const { selectedCustomerForImpersonation, impersonatedCustomer } = useUser();
  const { env } = useContext(EnvironmentContext);
  const { filters, setFilters } = useFeedbackFilter();
  const [spinningColumn, setSpinningColumn] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const calendarRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInput, setPageInput] = useState("1");
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const drawerRef = useRef(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [copied, setCopied] = useState(false);
  const [showNoFeedbackMessage, setShowNoFeedbackMessage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { workflowInstanceId: urlWorkflowInstanceId } = useParams();
  const navigate = useNavigate();
  const isManualNavigation = useRef(false);

  // Modify the initial useEffect
  useEffect(() => {
    clearFeedback();
    setIsLoading(true);
    fetchAllFeedback(filters).finally(() => setIsLoading(false));
    return () => clearFeedback(); // Clear on unmount
  }, []);

  // Add this effect to detect manual URL navigation
  useEffect(() => {
    if (urlWorkflowInstanceId && !isManualNavigation.current) {
      isManualNavigation.current = true;
    }
  }, []);

  // Modify the env/customer/filter effect
  useEffect(() => {
    // Skip if this was a manual navigation
    if (isManualNavigation.current) {
      isManualNavigation.current = false;
      return;
    }

    // Only clear URL and selection on actual env/customer/filter changes
    if (selectedCustomerForImpersonation || impersonatedCustomer || env) {
      const basePath = env === "prod" ? "" : `/${env}`;
      navigate(`${basePath}/feedback`, { replace: true });
      setSelectedRowId(null); // Clear the selected row
      setSelectedWorkflow(null); // Clear the selected workflow
    }
  }, [selectedCustomerForImpersonation, impersonatedCustomer, env, filters]);

  // Modify the feedback fetching effect
  useEffect(() => {
    if (selectedCustomerForImpersonation || impersonatedCustomer || env) {
      setIsLoading(true);
      fetchAllFeedback(filters).finally(() => setIsLoading(false));
      setShowNoFeedbackMessage(true);
    }
  }, [selectedCustomerForImpersonation, impersonatedCustomer, env, filters]);

  // Group feedback by workflow ID
  const groupedFeedback = workflowFeedback.reduce((acc, item) => {
    const instanceId = item.workflow_instance_id;

    // Create or update instance entry
    if (!acc[instanceId]) {
      acc[instanceId] = {
        workflowName: item.workflow_name,
        workflowInstanceId: instanceId,
        workflowDescription: item.workflow_description,
        startTime: formatDateTime(item.start_time),
        endTime: formatDateTime(item.end_time),
        status: item.review_status,
        thumbsUpCount: item.thumbs_up_count || 0,
        thumbsDownCount: item.thumbs_down_count || 0,
        lastUpdated: formatDateTime(item.last_updated),
      };
    }

    return acc;
  }, {});

  // Calculate total pages based on grouped feedback length
  const totalPages = Math.ceil(
    Object.keys(groupedFeedback).length / ITEMS_PER_PAGE
  );

  // Get current page's feedback items
  const getCurrentPageFeedback = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return Object.values(groupedFeedback).slice(startIndex, endIndex);
  };

  const toggleFilterOptions = (type) => {
    setOpenDropdown(openDropdown === type ? null : type);
    if (type !== "lastRun") {
      setShowCalendar(false);
    }
  };

  const handleSearch = (searchTerm) => {
    setFilters((prev) => ({ ...prev, name: searchTerm || null }));
  };

  const handleDateFilter = (option) => {
    if (option === "Custom") {
      setSelectedDateRange(selectedDateRange);
      setShowCalendar(true);
      setOpenDropdown(null);
    } else {
      const now = new Date();
      let start, end;

      if (option === "Last 7 Days") {
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
      } else if (option === "Last 30 Days") {
        start = new Date(now.setDate(now.getDate() - 30));
        end = new Date();
      }

      setFilters((prev) => ({
        ...prev,
        lastRun: {
          display: option, // Keep the display text
          start: start,
          end: end,
        },
      }));
      setOpenDropdown(null);
    }
  };

  const handleChange = (value) => {
    setSelectedDateRange(value);
  };

  const submitDateRange = (value) => {
    if (value && value.length === 2) {
      let [start, end] = value;

      const dateRangeFilter = {
        start: start,
        end: end,
      };

      console.log("Date Range Filter:", dateRangeFilter);

      setFilters((prev) => ({ ...prev, lastRun: dateRangeFilter }));
      setShowCalendar(false);
      setOpenDropdown(null);
    }
  };

  const handlePageInputChange = (e) => {
    setPageInput(e.target.value);
  };

  const handlePageInputSubmit = () => {
    const newPage = parseInt(pageInput);
    if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    } else {
      setPageInput(currentPage.toString());
    }
  };

  const goToPage = (direction) => {
    switch (direction) {
      case "first":
        setCurrentPage(1);
        setPageInput("1");
        break;
      case "previous":
        setCurrentPage((prev) => prev - 1);
        setPageInput((currentPage - 1).toString());
        break;
      case "next":
        setCurrentPage((prev) => prev + 1);
        setPageInput((currentPage + 1).toString());
        break;
      case "last":
        setCurrentPage(totalPages);
        setPageInput(totalPages.toString());
        break;
      default:
        break;
    }
  };

  const handleRowClick = async (workflow) => {
    setSelectedRowId(workflow.workflowInstanceId);
    setIsDrawerOpen(true);
    setIsLoading(true);

    // Construct the correct path based on the environment
    const basePath = env === "prod" ? "" : `/${env}`;
    const navigatePath = `${basePath}/feedback/${workflow.workflowInstanceId}`;
    // Update URL without reloading
    navigate(navigatePath, { replace: true });

    try {
      // Fetch feedback and outputs for the selected workflow
      const { feedback, outputs } = await fetchFeedbackForWorkflow(
        workflow.workflowInstanceId
      );

      // If the feedback is archived, make sure we can see it
      if (
        feedback?.[0]?.review_status === "Archived" &&
        !filters.archivedIncluded
      ) {
        setFilters((prev) => ({ ...prev, archivedIncluded: true }));
      }

      // Update the workflow data
      setSelectedWorkflow({
        ...workflow,
        feedback,
        outputs,
      });

      setShowNoFeedbackMessage(
        !workflow.status ||
          (workflow.status === "Needs Review" &&
            (!feedback || feedback.length === 0))
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosePopup = () => {
    setIsDrawerOpen(false);
    setSelectedRowId(null);
    const basePath = env === "prod" ? "" : `/${env}`;
    navigate(`${basePath}/feedback`, { replace: true });
  };

  const handleSort = (column) => {
    setSpinningColumn(column);
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        sortColumn: column,
        sortDirection: prevFilters.sortDirection === "asc" ? "desc" : "asc",
      };
      console.log("Updated Filters with Sort:", newFilters);
      return newFilters;
    });

    // Stop spinning after a short delay
    setTimeout(() => {
      setSpinningColumn(null);
    }, 500);
  };

  const handleIdClick = async (id) => {
    try {
      await navigator.clipboard.writeText(id);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  // Add keyboard navigation handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!workflowFeedback.length) return;

      const currentIndex = selectedRowId
        ? Object.values(groupedFeedback).findIndex(
            (w) => w.workflowInstanceId === selectedRowId
          )
        : -1;

      let newIndex;
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          newIndex =
            currentIndex > 0
              ? currentIndex - 1
              : Object.values(groupedFeedback).length - 1;
          break;
        case "ArrowDown":
          e.preventDefault();
          newIndex =
            currentIndex < Object.values(groupedFeedback).length - 1
              ? currentIndex + 1
              : 0;
          break;
        default:
          return;
      }

      const newWorkflow = Object.values(groupedFeedback)[newIndex];
      if (newWorkflow) {
        handleRowClick(newWorkflow);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [workflowFeedback, selectedRowId, groupedFeedback]);

  // Update useEffect to handle URL workflow instance
  useEffect(() => {
    if (urlWorkflowInstanceId && workflowFeedback.length > 0) {
      const workflow = Object.values(groupedFeedback).find(
        (w) => w.workflowInstanceId === urlWorkflowInstanceId
      );
      if (workflow) {
        handleRowClick(workflow);
      }
    }
  }, [urlWorkflowInstanceId, workflowFeedback]);

  const handleFeedbackUpdate = ({
    workflowInstanceId,
    thumbsUpCount,
    thumbsDownCount,
    status,
    isOptimistic,
    revert,
  }) => {
    if (revert) {
      fetchAllFeedback(filters);
      return;
    }

    setWorkflowFeedback((prev) =>
      prev.map((item) => {
        if (item.workflow_instance_id === workflowInstanceId) {
          return {
            ...item,
            thumbs_up_count: (item.thumbs_up_count || 0) + (thumbsUpCount || 0),
            thumbs_down_count:
              (item.thumbs_down_count || 0) + (thumbsDownCount || 0),
            review_status: status || item.review_status,
          };
        }
        return item;
      })
    );

    if (!isOptimistic) {
      fetchAllFeedback(filters);
    }
  };

  return (
    <div className="App-feedback">
      <Header />
      <div className="Feedback-main">
        <div
          className={`Feedback-content ${isDrawerOpen ? "drawer-open" : ""}`}
        >
          <FeedbackFilterRow
            toggleFilterOptions={toggleFilterOptions}
            setFilters={setFilters}
            openDropdown={openDropdown}
            handleSearch={handleSearch}
            handleDateFilter={handleDateFilter}
            showCalendar={showCalendar}
            calendarRef={calendarRef}
            handleChange={handleChange}
            selectedDateRange={selectedDateRange}
            submitDateRange={submitDateRange}
            submitButtonRef={submitButtonRef}
            filters={filters}
            setShowCalendar={setShowCalendar}
            fetchAllFeedback={fetchAllFeedback}
            isLoading={isLoading}
          />
          <div className="Feedback-table-wrapper">
            <div className="Feedback-table-container">
              <div className="Feedback-table">
                <div className="Feedback-table-header">
                  <div className="Feedback-table-row">
                    <div
                      className="Feedback-table-cell"
                      onClick={() => handleSort("workflow_name")}
                    >
                      Workflow {getSortIcon("workflow_name", spinningColumn)}
                    </div>
                    <div
                      className="Feedback-table-cell"
                      onClick={() => handleSort("start_time")}
                    >
                      Start Time {getSortIcon("start_time", spinningColumn)}
                    </div>
                    <div
                      className="Feedback-table-cell"
                      onClick={() => handleSort("end_time")}
                    >
                      End Time {getSortIcon("end_time", spinningColumn)}
                    </div>
                    <div className="Feedback-table-cell">Feedback</div>
                    <div
                      className="Feedback-table-cell"
                      onClick={() => handleSort("last_updated")}
                    >
                      Last Modified{" "}
                      {getSortIcon("last_updated", spinningColumn)}
                    </div>
                    <div
                      className="Feedback-table-cell"
                      onClick={() =>
                        setFilters((prev) => ({
                          ...prev,
                          archivedIncluded: !filters.archivedIncluded,
                        }))
                      }
                    >
                      Status
                      <input
                        type="checkbox"
                        checked={filters.archivedIncluded}
                        onChange={(e) =>
                          setFilters((prev) => ({
                            ...prev,
                            archivedIncluded: e.target.checked,
                          }))
                        }
                        className="tiny-checkbox"
                        id="show-archived"
                        name="archivedIncluded"
                        aria-label="Include archived"
                      />
                    </div>
                    <div className="Feedback-table-cell"></div>
                  </div>
                </div>
                <div className="Feedback-table-body">
                  {!isLoading && Object.keys(groupedFeedback).length === 0 ? (
                    <div className="empty-feedback-message">
                      Your feedback list is empty. Runs that have{" "}
                      <strong>'Needs Review' </strong>
                      status or active feedback messages show here
                    </div>
                  ) : (
                    getCurrentPageFeedback().map((workflow) => (
                      <div
                        key={`${workflow.workflowInstanceId}-${workflow.id}`}
                        className={`Feedback-table-row ${
                          selectedRowId === workflow.workflowInstanceId
                            ? "selected-row"
                            : ""
                        }`}
                        role="row"
                        tabIndex="0"
                        onClick={() => handleRowClick(workflow)}
                      >
                        <div className="Feedback-table-cell">
                          <span className="tooltip-container-name">
                            {workflow.workflowName}
                            <span className="tooltip-text-name">
                              {workflow.workflowName}
                            </span>
                          </span>
                        </div>
                        <div className="Feedback-table-cell">
                          <span className="tooltip-container-date">
                            {workflow.startTime || "N/A"}
                            <span className="tooltip-text-date">
                              {workflow.startTime || "N/A"}
                            </span>
                          </span>
                        </div>
                        <div className="Feedback-table-cell">
                          <span className="tooltip-container-date">
                            {workflow.endTime || "N/A"}
                            <span className="tooltip-text-date">
                              {workflow.endTime || "N/A"}
                            </span>
                          </span>
                        </div>
                        <div className="Feedback-table-cell">
                          <div className="feedback-counts">
                            {workflow.thumbsUpCount > 0 && (
                              <span
                                className={`feedback-count ${
                                  workflow.thumbsUpCount > 9 ? "wide-count" : ""
                                }`}
                              >
                                <FontAwesomeIcon icon={faThumbsUp} />
                                <span className="count-badge">
                                  {workflow.thumbsUpCount}
                                </span>
                              </span>
                            )}
                            {workflow.thumbsDownCount > 0 && (
                              <span className="feedback-count">
                                <FontAwesomeIcon icon={faThumbsDown} />
                                <span className="count-badge">
                                  {workflow.thumbsDownCount}
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="Feedback-table-cell">
                          <span className="tooltip-container-date">
                            {workflow.lastUpdated || "N/A"}
                            <span className="tooltip-text-date">
                              {workflow.lastUpdated || "N/A"}
                            </span>
                          </span>
                        </div>
                        <div className="Feedback-table-cell">
                          <span
                            className="status-bubble"
                            data-status={workflow.status}
                          >
                            {workflow.status}
                          </span>
                        </div>
                        <div
                          className="Feedback-table-cell"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            const path = `${
                              env === "prod" ? "" : `/${env}`
                            }/workflow/${workflow.workflowInstanceId}`;
                            navigate(path);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUpRightFromSquare}
                            className="workflow-link-icon"
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`Feedback-drawer ${isDrawerOpen ? "open" : ""}`}
          ref={drawerRef}
        >
          <EmptyDrawerMessagePopup
            currentWorkflowName={selectedWorkflow?.workflowName}
            workflowDescription={selectedWorkflow?.workflowDescription}
            onClose={handleClosePopup}
            outputs={selectedWorkflow?.outputs}
            workflowInstanceId={selectedWorkflow?.workflowInstanceId}
            workflowInstanceCreatedAt={selectedWorkflow?.startTime}
            workflowInstanceEndedAt={selectedWorkflow?.endTime}
            copied={copied}
            handleIdClick={handleIdClick}
            isFromFeedbackPage={true}
            showNoFeedbackMessage={showNoFeedbackMessage}
            showArchived={filters.archivedIncluded}
            onFeedbackUpdate={handleFeedbackUpdate}
          />
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageInput={pageInput}
          handlePageInputChange={handlePageInputChange}
          handlePageInputSubmit={handlePageInputSubmit}
          goToPage={goToPage}
          disableNext={currentPage === totalPages}
          isDrawerOpen={isDrawerOpen}
        />
      </div>
    </div>
  );
};

export default Feedback;
