import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userIsQurrent, setUserIsQurrent] = useState(false);
  const [customers, setCustomers] = useState(() => {
    const saved = sessionStorage.getItem("qurrent-customers");
    return saved ? JSON.parse(saved) : [];
  });

  const [
    selectedCustomerForImpersonation,
    setSelectedCustomerForImpersonation,
  ] = useState(() => {
    const saved = localStorage.getItem("qurrent-selected-customer");
    return saved ? JSON.parse(saved) : null;
  });

  const [isImpersonating, setIsImpersonating] = useState(() => {
    const saved = localStorage.getItem("qurrent-is-impersonating");
    return saved ? JSON.parse(saved) : false;
  });

  const [impersonatedCustomer, setImpersonatedCustomer] = useState(() => {
    const saved = localStorage.getItem("qurrent-impersonated-customer");
    return saved ? JSON.parse(saved) : null;
  });

  useEffect(() => {
    if (
      isImpersonating &&
      selectedCustomerForImpersonation &&
      !impersonatedCustomer
    ) {
      setImpersonatedCustomer(selectedCustomerForImpersonation);
    }
  }, [isImpersonating, selectedCustomerForImpersonation, impersonatedCustomer]);

  useEffect(() => {
    localStorage.setItem(
      "qurrent-selected-customer",
      JSON.stringify(selectedCustomerForImpersonation)
    );
  }, [selectedCustomerForImpersonation]);

  useEffect(() => {
    localStorage.setItem(
      "qurrent-is-impersonating",
      JSON.stringify(isImpersonating)
    );
  }, [isImpersonating]);

  useEffect(() => {
    localStorage.setItem(
      "qurrent-impersonated-customer",
      JSON.stringify(impersonatedCustomer)
    );
  }, [impersonatedCustomer]);

  useEffect(() => {
    sessionStorage.setItem("qurrent-customers", JSON.stringify(customers));
  }, [customers]);

  const [customerViewActive, setCustomerViewActive] = useState(false);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user_is_qurrent") {
        const storedValue = sessionStorage.getItem("user_is_qurrent");
        setUserIsQurrent(storedValue === "true");
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return (
    <UserContext.Provider
      value={{
        userIsQurrent,
        setUserIsQurrent,
        impersonatedCustomer,
        setImpersonatedCustomer,
        customerViewActive,
        setCustomerViewActive,
        isImpersonating,
        setIsImpersonating,
        selectedCustomerForImpersonation,
        setSelectedCustomerForImpersonation,
        customers,
        setCustomers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
