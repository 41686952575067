import React, { createContext, useState, useCallback, useRef } from "react";
import getConfig from "../config.js";
import { useFilter } from "./FilterContext.js";
import { useUser } from "./UserContext.js";

export const EnvironmentContext = createContext();

export const EnvironmentProvider = ({ children }) => {
  const initialConfig = getConfig();
  const pathEnv = window.location.pathname.startsWith("/dev")
    ? "dev"
    : window.location.pathname.startsWith("/staging")
    ? "staging"
    : null;
  const savedEnv =
    pathEnv || sessionStorage.getItem("environment") || initialConfig.env;

  const [config, setConfig] = useState(initialConfig);
  const [env, setEnv] = useState(savedEnv);
  const [apiBaseUrl, setApiBaseUrl] = useState(
    savedEnv === "dev"
      ? `${initialConfig.apiBaseUrl}/dev`
      : savedEnv === "staging"
      ? `${initialConfig.apiBaseUrl}/staging`
      : initialConfig.apiBaseUrl
  );
  const { setFilters } = useFilter();
  const { setImpersonatedCustomer } = useUser();

  const searchInputRef = useRef(null);

  const switchEnvironment = useCallback(
    (newEnv) => {
      if (newEnv === undefined) {
        newEnv = env === "prod" ? "dev" : "prod";
      }

      let newApiBaseUrl = initialConfig.apiBaseUrl.replace(
        /\/(dev|staging)$/,
        ""
      );
      if (newEnv === "dev") {
        newApiBaseUrl += "/dev";
      } else if (newEnv === "staging") {
        newApiBaseUrl += "/staging";
      }

      setFilters([
        {
          status: null,
          name: null,
          lastRun: null,
          customer: null,
        },
      ]);

      setImpersonatedCustomer(null);

      setEnv(newEnv);
      setConfig({ ...initialConfig, apiBaseUrl: newApiBaseUrl });
      setApiBaseUrl(newApiBaseUrl);
      sessionStorage.setItem("environment", newEnv);

      if (searchInputRef.current) {
        searchInputRef.current.value = "";
      }
    },
    [env, initialConfig.apiBaseUrl]
  );

  return (
    <EnvironmentContext.Provider
      value={{ env, config, apiBaseUrl, switchEnvironment, searchInputRef }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};
