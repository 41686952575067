import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../App.css";
import qurrentLogoDark from "../assets/qurrent_icon.png";

const NotAuthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModePreference = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setIsDarkMode(darkModePreference);
  }, []);

  const handleBackToMain = () => {
    navigate("/");
  };

  const message =
    location.state?.message ||
    "You are not authorized to access this workflow.";

  return (
    <div className="not-authorized-container">
      <img
        src={qurrentLogoDark}
        alt="Qurrent Logo"
        className="authentication-logo"
      />
      <h1>Not Authorized</h1>
      <p>{message}</p>
      <button onClick={handleBackToMain} className="back-to-main-button">
        Back to Main Page
      </button>
    </div>
  );
};

export default NotAuthorized;
